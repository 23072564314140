import React from 'react';
import {Editor} from "@tinymce/tinymce-react";
import GlobalCustomFieldGrouped from '../../Common/GlobalCustomFieldGrouped/Index';
import Utils from '../../../helpers/Utils';


class EachTiming extends React.Component {
    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
        this.messageRef = React.createRef();
    }

    handleTitlePersonalizedChanged = (value) =>  {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        let start = this.titleRef.current.selectionStart;
        let end = this.titleRef.current.selectionEnd;
        let text = this.titleRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        // this.props.onTitleChange(newText)
        this.props.onTitleChange(
            (this.props.RowIndex-1), 
            newText, 
            this.props.reminderType, 
        )
        setTimeout(() => {
            this.titleRef.current.selectionStart = this.titleRef.current.selectionEnd = start + value.length;
            this.titleRef.current.focus();
        }, 0);
    }

    handleMessagePersonalizedChanged = (value) =>  {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        this.messageRef.current.insertContent(value);

        setTimeout(() => {
            this.messageRef.current.focus();
        }, 100);
    }

   emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    apiKeys = () => {
        return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
    }

    render() {
        return (
            <div className='input_group_calender_widget_row_single'>
                {this.props.RowIndex === 0 &&
                    this.props.firstStep
                }
                <div className="timing-controls d-flex mb-3">
                    <div className="timing-interval d-block">
                        <input className="timing-interval form-control" type="text" name={this.props.reminderType+"_interval_" + this.props.RowIndex}
                            onChange={(e) => this.props.onTimeChange(this.props.RowIndex, e.target.value, this.props.reminderType, this.props.timeSelect !== undefined ? true : false)}
                            value={(this.props.timeValue) ? this.props.timeValue : ''}
                            autoComplete="off" autoCorrect="off" maxLength="2"/>
                        <span className="m-form__help tsc-error-message">{this.props.validator}</span>
                    </div>
                    <div className="timing-unit d-block ml-2">
                        <div className="select">
                            <select className="timing-unit form-control" value={(this.props.unitValue) ? this.props.unitValue : ''}
                                    name="unit[]"
                                    onChange={(e) => this.props.onUnitChange(this.props.RowIndex, e.target.value, this.props.reminderType)}>
                                <option value="days">day(s) before</option>
                                <option value="hours">hour(s) before</option>
                                <option value="minutes">minute(s) before</option>
                            </select>
                        </div>
                    </div>
                    {this.props.RowIndex !== 0 &&
                    <div className="delete-text-reminder-button d-block ml-2 p-2 cursor-pointer"
                        onClick={() => this.props.remover(this.props.RowIndex, this.props.reminderType)} data-index={this.props.RowIndex}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                    </div>
                    }
                </div>
                {this.props.RowIndex !== 0 &&
                <div>
                    <div className="input_group">
                        <div className='input_group_calender_widget_wr'>
                            <label className='input_group_calender_widget_wr_title'>Title {this.props.reminderType === "text" && (<span className="m-form__help tsc-error-message" style={{ fontSize: "13px" }}>* Length cannot be greater than 255 character</span>)}</label>
                            <div className='input_group_calender_widget_container'>
                                <GlobalCustomFieldGrouped 
                                    className={"send_module__quick__reply__global_custom__field"} 
                                    labelText={"Personalized"} 
                                    handleSelect={this.handleTitlePersonalizedChanged}
                                    forCalendarWidget={true}
                                />
                            </div>
                        </div>
                        <input
                            ref={this.titleRef}
                            value={this.props.titleValue}
                            placeholder="Title"
                            type="text"
                            className="validate"
                            onChange={(event)=> {
                                let val = event.target.value;
                                if(this.props.reminderType === "text" && val !== null && val !== undefined && val.length > 255){
                                    val = val.slice(0,255);
                                }
                                this.props.onTitleChange(
                                    (this.props.RowIndex - 1),
                                    val,
                                    this.props.reminderType,
                                )
                            }}
                        />
                    </div>

                    {this.props.reminderType === "email" &&
                    <div className="input_group">
                        <div className='input_group_calender_widget_wr'>
                            <label className='input_group_calender_widget_wr_title'>Body</label>
                            <div className='input_group_calender_widget_container'>
                                <GlobalCustomFieldGrouped 
                                    className={"send_module__quick__reply__global_custom__field"} 
                                    labelText={"Personalized"} 
                                    handleSelect={this.handleMessagePersonalizedChanged}
                                    forCalendarWidget={true}
                                />
                            </div>
                        </div>

                        <Editor
                            apiKey={this.apiKeys()} //'bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                            onInit={(evt, editor) => this.messageRef.current = editor}
                            initialValue={this.props.messageValue}
                            cloudChannel='5-dev'
                            textareaName='message'
                            init={{
                                height: 500,
                                // menubar: false,
                                menu: {
                                    favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                                },
                                menubar: 'favs file edit view insert format tools table help',
                                plugins: [
                                    'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                    'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                    'insertdatetime media table contextmenu paste code help wordcount template'
                                ],
                                convert_urls: false,
                                toolbar:
                                    'insertfile undo redo | styleselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | link image | \
                                    bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                imagetools_proxy: this.emailUploadFileApi,
                                images_upload_url: this.emailUploadFileApi,
                                // images_upload_base_path: baseUrl,
                                file_picker_types: 'file image media',
                                placeholder: "Compose your mail message",
                                forced_root_block: false
                            }}
                            onEditorChange={(content)=> {
                                this.props.onMessageChange(
                                    (this.props.RowIndex-1), 
                                    content, 
                                    this.props.reminderType, 
                                )
                            }}
                        />
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}

export default EachTiming;