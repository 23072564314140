import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import './../assets/css/calendar-details.css';
import {} from "../../../api/calendarApi";
import TopPart from "./TopPart";
import BasicSection from "./BasicSection";
import CalendarSection from "./CalendarSection";
import ConfirmationSection from "./ConfirmationSection";
import PolicySection from "./PolicySection";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {fetchCalendarWidgetDetails} from "../../../actions/calendarWidgetAction";
import moment from "moment";
import Utils from "../../../helpers/Utils";
import If from "if-else-react";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));



const App = (props) => {
    const classes = useStyles();
    const calendarId = props.match.params.calendarWidgetId;
    const [calendarDetails, setCalendarDetails] = useState(props.calendarDetails);
    const [lastEditDate, setLastEditDate] = useState();
    const [title, setTitle] = useState('');
    const [openSection, setOpenSection] = useState(1);
    const [enableOtherSection, setEnableOtherSection] = useState(0);
    const [turnOnCalendar, setTurnOnCalendar] = useState(0);

    useEffect(()=>{
        document.title = `Calendar | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('calendar');

        props.fetchCalendarWidgetDetails({
            'calendar_id' : calendarId,
        });
    }, [])

    useEffect(()=>{
        setCalendarDetails(props.calendarDetails);
        if(props.calendarDetails.id != undefined){
            setLastEditDate(moment(props.calendarDetails.updated_at).format('D MMMM, YYYY'),);
            setTitle(props.calendarDetails.title);
            if(props.calendarDetails.permalink) {
                setEnableOtherSection(1);
            }else{
                setEnableOtherSection(0);
            }
        }

    }, [props.calendarDetails])

    const openNextSection = (currentSection, updated) => {
        setOpenSection(currentSection+1);

        if(updated)
            setEnableOtherSection(updated);
    }

    const handleSectionChange = (currentSection) => {
        if(openSection == currentSection) {
            setOpenSection(0)
        }else{
            setOpenSection(currentSection);
        }

    }

    const turnOnEvent = () => {
        setTurnOnCalendar(1);
    }


    return (
        <React.Fragment>


                <div className="calendar_section calender_section_update_v2_h">

                   <TopPart turnOnCalendar={turnOnCalendar}/>

                    <div className="page_content">
                        <div className="title_alart">
                            <h6>Complete the 4 sections below. Be sure to click save in each section before moving on to
                                the next.</h6>
                            <p>Last edited {lastEditDate}</p>
                        </div>
                        <div className="collapsible awesome__scroll_bar">

                            <div className={classes.root}>

                                <Accordion expanded={openSection === 1} onChange={()=>handleSectionChange(1)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={` accordion_header ${classes.heading}`}>
                                            <h6>What event is this?</h6>
                                            <span>{title}</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion__details">
                                        <Typography>
                                            <BasicSection openNextSection={(currentSection=1, updated=0)=>openNextSection(currentSection, updated)}/>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <If condition={enableOtherSection}>
                                    <Accordion disabled={enableOtherSection ? '' : 'disabled'} expanded={openSection === 2} onChange={()=>handleSectionChange(2)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={` accordion_header ${classes.heading}`}>
                                                <h6>When can people book this event ?</h6>
                                                <span>30 minutes</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion__details">
                                            <Typography>
                                                <CalendarSection openNextSection={()=>openNextSection(2)}/>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={enableOtherSection ? '' : 'disabled'} expanded={openSection === 3} onChange={()=>handleSectionChange(3)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={` accordion_header ${classes.heading}`}>
                                                <h6>Notifications and Cancellation Policy</h6>
                                                <span>Calendar Invitations, Text Reminders, Email Reminders, Email Follow-Up</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion__details">
                                            <Typography>
                                                <ConfirmationSection openNextSection={()=>openNextSection(3)} openSection={openSection} />
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={enableOtherSection ? '' : 'disabled'} expanded={openSection === 4} onChange={()=>handleSectionChange(4)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={` accordion_header ${classes.heading}`}>
                                                <h6>Confirmation Page</h6>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordion__details">
                                            <Typography>
                                                <PolicySection turnOnEvent={()=>turnOnEvent()}/>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </If>



                            </div>

                        </div>
                    </div>

                </div>


        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        calendarDetails : state.calendarWidgetReducer.calendarWidgetDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCalendarWidgetDetails : (params) => dispatch(fetchCalendarWidgetDetails(params)),
    };
}

const CalendarWidgetApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default CalendarWidgetApp;
