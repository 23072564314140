import React from 'react';
import moment from 'moment';
import axis from 'axis.js';
import EachSchedule from "./EachSchedule";
import "moment-timezone";

class TimeScheduleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelected : null
        }
    }

    handler = (date,time)=>{
        this.setState({
            isSelected : time
        });
    };

    handlerConfirm = (date,time)=>{
        if (this.props.timeZone != this.props.userTimezone) {
            date = this.convertTime(date.replace(' 00:00:00', ''), time, this.props.timeZone, this.props.userTimezone).format('YYYY-M-D') + ' 00:00:00';
            time = this.convertTime(date.replace(' 00:00:00', ''), time, this.props.timeZone, this.props.userTimezone).format('hh:mma');
        }
        this.props.submitConfirm(date,time);
    };

    convertTime = (date, time, from, to) => {
        let dateTime = moment(date+' '+time,['YYYY-M-D h:ma']);
        return dateTime.tz(from, true).tz(to);
    }


    render() {

        let selectedTimeZoneCurrentTime = moment.tz(this.props.timeZone).format('hh:mma');
        let selectedTimeZoneCurrentDate = moment.tz(this.props.timeZone).format('YYYY-M-D');

        let selectedTimeZoneCurrentTimeArr = selectedTimeZoneCurrentTime.split(':');

        let selectedTimeZoneCurrentTimeNumber = parseInt(selectedTimeZoneCurrentTimeArr[0]) * 60; //convert to minute

        if(selectedTimeZoneCurrentTimeArr[1].includes('am')){
            selectedTimeZoneCurrentTimeNumber = selectedTimeZoneCurrentTimeNumber + parseInt(selectedTimeZoneCurrentTimeArr[1].replace("am", ""));
        }else{
            selectedTimeZoneCurrentTimeNumber = selectedTimeZoneCurrentTimeNumber + parseInt(selectedTimeZoneCurrentTimeArr[1].replace("pm", "")) + (12*60);
        }

        let selectedConfirmDate = moment();
        let range = this.props.dateRange;
        let lists = [];
        for (let schedule in range){

            if(axis.isObject(range[schedule])){
                selectedConfirmDate = range[schedule].start;
                let date = moment(range[schedule].start,['YYYY-MM-DD']).format('YYYY-M-D');
                let start = range[schedule].title.split('-')[0];
                let end = range[schedule].title.split('-')[1];


                if (this.props.timeZone != this.props.userTimezone) {
                    date = this.convertTime(date, start, this.props.userTimezone, this.props.timeZone).format('YYYY-M-D');
                    start = this.convertTime(date, start, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                    end = this.convertTime(date, end, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                    let startTime = this.convertTime(date, range[schedule].title.split('-')[0], this.props.userTimezone, this.props.timeZone).format('YYYY-M-D');
                    let endTime = this.convertTime(date, range[schedule].title.split('-')[1], this.props.userTimezone, this.props.timeZone).format('YYYY-M-D');


                    if(endTime > startTime){
                        end = '11:59pm';
                    }
                }

                do {
                    let time = moment(start,['h:m a', 'H:m']).format('h:mma');
                    let slotStart = moment(start,['h:m a', 'H:m']);
                    let slotEnd = moment(start,['h:m a', 'H:m']).add(this.props.duration,'m');
                    if (date in  this.props.reserveSlots) {

                        let isExist = this.props.reserveSlots[date].filter((reserveTime)=>{
                            let reserveStartTime = '';
                            let reserveEndTime = '';
                            if (this.props.timeZone != this.props.userTimezone) {
                                reserveStartTime = this.convertTime(date, reserveTime.start, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                                reserveEndTime = this.convertTime(date, reserveTime.end, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                            } else {
                                reserveStartTime = reserveTime.start;
                                reserveEndTime = reserveTime.end;
                            }
                            let startDate = moment(reserveStartTime,['h:m a', 'H:m']);
                            let endDate = moment(reserveEndTime,['h:m a', 'H:m']);
                            return slotStart.isSame(startDate) || slotStart.isBetween(startDate, endDate) || slotEnd.isBetween(startDate, endDate);
                        });

                        if(axis.isArray(isExist) && isExist.length === 0){
                            lists.push({
                                time : time,
                                date :range[schedule].start
                            });
                        }

                    } else{
                        lists.push({
                            time : time,
                            date :range[schedule].start
                        });
                    }

                    start = moment(start,['h:m a', 'H:m']).add(this.props.increments,'m');
                    end = moment(end,['h:m a', 'H:m']);


                }
                while(start < end);
            }
        }

        let selectedDate = this.props.selectedDate;
        let previousRange = this.props.previousDateRange;
        let previousLists = [];


        if (this.props.timeZone != this.props.userTimezone) {
            for (let schedule in previousRange) {
                if (axis.isObject(previousRange[schedule])) {
                    //user time zone
                    let date = moment(previousRange[schedule].start, ['YYYY-MM-DD']).format('YYYY-M-D');
                    let scheduleStart = previousRange[schedule].title.split('-')[0];
                    let scheduleEnd = previousRange[schedule].title.split('-')[1];
                    //end user time zone

                    date = this.convertTime(date, scheduleStart, this.props.userTimezone, this.props.timeZone).format('YYYY-M-D');
                    let start = this.convertTime(date, scheduleStart, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                    let end = this.convertTime(date, scheduleEnd, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                    let startTime = this.convertTime(date, previousRange[schedule].title.split('-')[0], this.props.userTimezone, this.props.timeZone).format('YYYY-M-D hh:mma');
                    let endTime = this.convertTime(date, previousRange[schedule].title.split('-')[1], this.props.userTimezone, this.props.timeZone).format('YYYY-M-D hh:mma');



                    let startTime12HourFormat = moment(startTime, ['YYYY-M-D h:m a', 'H:m']).format('YYYY-M-D HH:mm');
                    let endTime12HourFormat = moment(endTime, ['YYYY-M-D h:m a', 'H:m']).format('YYYY-M-D HH:mm');


                    do {
                        let slotStart = moment(startTime,['h:m a', 'H:m']);
                        let time = moment(slotStart,['h:m a', 'H:m']).format('h:mma');
                        let slotEnd = moment(startTime,['h:m a', 'H:m']).add(this.props.duration,'m');

                        let timeFromDate = slotStart._i;
                        let timeFromDateArr = timeFromDate.split(' ');

                        let timeEndFromDate = slotEnd._i;
                        let timeEndFromDateArr = timeEndFromDate.split(' ');

                        startTime12HourFormat = moment(startTime, ['YYYY-M-D h:m a', 'H:m']).format('YYYY-M-D HH:mm');
                        endTime12HourFormat = moment(endTime, ['YYYY-M-D h:m a', 'H:m']).format('YYYY-M-D HH:mm');



                        if(startTime12HourFormat >= moment(selectedDate).format('YYYY-M-D HH:mm')){

                            if (date in this.props.reserveSlots) {
                                let isExist = this.props.reserveSlots[date].filter((reserveTime)=>{
                                    let reserveStartTime = '';
                                    let reserveEndTime = '';

                                    reserveStartTime = this.convertTime(date, reserveTime.start, this.props.userTimezone, this.props.timeZone).format('hh:mma');
                                    reserveEndTime = this.convertTime(date, reserveTime.end, this.props.userTimezone, this.props.timeZone).format('hh:mma');

                                    let startDate = moment(reserveStartTime,['h:m a', 'H:m']);
                                    let endDate = moment(reserveEndTime,['h:m a', 'H:m']);

                                    let startTimeArr = startDate._i.split(':');

                                    let startTimeNum = parseInt(startTimeArr[0]) * 60;

                                    if(startTimeArr[1].includes('am')){
                                        startTimeNum = startTimeNum + parseInt(startTimeArr[1].replace("am", ""));
                                    }else{
                                        startTimeArr[1].replace("pm", "");
                                        startTimeNum = startTimeNum + parseInt(startTimeArr[1].replace("pm", "")) + (12*60);
                                    }

                                    let endTimeArr = endDate._i.split(':');

                                    let endTimeNum = parseInt(endTimeArr[0]) * 60; //convert to minute

                                    if(endTimeArr[1].includes('am')){
                                        endTimeNum = endTimeNum + parseInt(endTimeArr[1].replace("am", ""));
                                    }else{
                                        endTimeNum = endTimeNum + parseInt(endTimeArr[1].replace("pm", "")) + (12*60);
                                    }

                                    let formattedSlotTimeArr = timeFromDateArr[1].split(':');

                                    let formattedSlotTimeNum = parseInt(formattedSlotTimeArr[0]) * 60;

                                    if(formattedSlotTimeArr[1].includes('am')){
                                        formattedSlotTimeNum = formattedSlotTimeNum + parseInt(formattedSlotTimeArr[1].replace("am", ""));
                                    }else{
                                        formattedSlotTimeNum = formattedSlotTimeNum + parseInt(formattedSlotTimeArr[1].replace("pm", "")) + (12*60);
                                    }

                                    let formattedSlotEndTimeArr = timeEndFromDateArr[1].split(':');

                                    let formattedSlotTimeEndNum = parseInt(formattedSlotEndTimeArr[0]) * 60;

                                    if(formattedSlotTimeArr[1].includes('am')){
                                        formattedSlotTimeEndNum = formattedSlotTimeEndNum + parseInt(formattedSlotEndTimeArr[1].replace("am", ""));
                                    }else{
                                        formattedSlotTimeEndNum = formattedSlotTimeEndNum + parseInt(formattedSlotEndTimeArr[1].replace("pm", "")) + (12*60);
                                    }

                                    return (startTimeNum == formattedSlotTimeNum) || (formattedSlotTimeNum > startTimeNum && formattedSlotTimeNum < endTimeNum) || (formattedSlotTimeEndNum > startTimeNum && formattedSlotTimeEndNum < endTimeNum);
                                });

                                let formattedSlotTimeArr = timeFromDateArr[1].split(':');

                                let formattedSlotTimeNum = parseInt(formattedSlotTimeArr[0]) * 60;

                                if(formattedSlotTimeArr[1].includes('am')){
                                    formattedSlotTimeNum = formattedSlotTimeNum + parseInt(formattedSlotTimeArr[1].replace("am", ""));
                                }else{
                                    formattedSlotTimeNum = formattedSlotTimeNum + parseInt(formattedSlotTimeArr[1].replace("pm", "")) + (12*60);
                                }

                                if(selectedDate){
                                    if(moment(selectedDate).format('YYYY-M-D') == selectedTimeZoneCurrentDate){
                                        if(selectedTimeZoneCurrentTimeNumber < formattedSlotTimeNum){
                                            if(axis.isArray(isExist) && isExist.length === 0){
                                                previousLists.push({
                                                    time : timeFromDateArr[1],
                                                    date :selectedConfirmDate
                                                });
                                            }
                                        }
                                    }else{
                                        if(axis.isArray(isExist) && isExist.length === 0){
                                            previousLists.push({
                                                time : timeFromDateArr[1],
                                                date :selectedConfirmDate
                                            });
                                        }
                                    }
                                }

                            }
                            else{
                                let formattedSlotTimeArr = timeFromDateArr[1].split(':');

                                let formattedSlotTimeNum = parseInt(formattedSlotTimeArr[0]) * 60;

                                if(formattedSlotTimeArr[1].includes('am')){
                                    formattedSlotTimeNum = formattedSlotTimeNum + parseInt(formattedSlotTimeArr[1].replace("am", ""));
                                }else{
                                    formattedSlotTimeNum = formattedSlotTimeNum + parseInt(formattedSlotTimeArr[1].replace("pm", "")) + (12*60);
                                }

                                if(selectedDate){
                                    if(moment(selectedDate).format('YYYY-M-D') == selectedTimeZoneCurrentDate){
                                        if(selectedTimeZoneCurrentTimeNumber < formattedSlotTimeNum){
                                            previousLists.push({
                                                time : timeFromDateArr[1],
                                                date :selectedConfirmDate
                                            });
                                        }
                                    }else{
                                        previousLists.push({
                                            time : timeFromDateArr[1],
                                            date :selectedConfirmDate
                                        });
                                    }
                                }

                            }

                        }

                        startTime = moment(startTime,['YYYY-M-D h:m a', 'H:m']).add(this.props.increments,'m').format('YYYY-M-D hh:mma');
                        endTime = moment(endTime,['YYYY-M-D h:m a', 'H:m']).format('YYYY-M-D hh:mma');

                    }while(startTime12HourFormat < endTime12HourFormat);


                }
            }
        }

        let newList = [...previousLists, ...lists];

        let today = moment().format('YYYY-MM-DD');

        const now = moment();

        const currentHours = now.hours();
        const currentMinutes = now.minutes();

        const currentTimeInMinutes = currentHours * 60 + currentMinutes;

        let content = newList.map((data,index)=>{

            if(moment(moment(data.date).format('YYYY-MM-DD')).isSame(today)){
                const time = data.time;
                const timeRegex = /^(\d{1,2}):(\d{2})(am|pm)$/i;
                const match = time.match(timeRegex);

                if (!match) {
                    console.error('Invalid time format');
                    return;
                }

                let [_, hours, minutes, period] = match; // Destructure the matched groups
                hours = parseInt(hours, 10);
                minutes = parseInt(minutes, 10);

                if (isNaN(hours) || isNaN(minutes)) {
                    console.error('Invalid time values');
                    return;
                }

                if (period.toLowerCase() === 'pm' && hours !== 12) {
                    hours += 12;
                } else if (period.toLowerCase() === 'am' && hours === 12) {
                    hours = 0;
                }

                const dataTimeInMinutes = hours * 60 + minutes;

                if (dataTimeInMinutes > currentTimeInMinutes) {
                    return  <EachSchedule isSelected={this.state.isSelected} handler={this.handler} handlerConfirm={this.handlerConfirm}  time={data} key={index}/>
                }

            }else {
                return  <EachSchedule isSelected={this.state.isSelected} handler={this.handler} handlerConfirm={this.handlerConfirm}  time={data} key={index}/>
            }
        });

        return (
            <React.Fragment>{content} </React.Fragment>
        );
    }
}
export default TimeScheduleList;
