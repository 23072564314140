import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { } from "../../../actions/calendarWidgetAction";
import { updateCalendarWidgetStatus } from "../../../api/calendarApi";
import Utils from "../../../helpers/Utils";
import CustomSwitch from "../../Common/Switch";
import CustomConfirmAlertMod from "../../Common/confirm-alert-modified/CustomConfirmAlertMod";

const App = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            marginBottom:"18px",
            marginRight:"20px",
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    }));

    const classes = useStyles();

    // let root_url = getAccountData('baseUrl');
    let root_url = 'https://'+window.location.hostname;
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const [calendarDetails, setCalendarDetails] = useState(props.calendarDetails);
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState(0);
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [eventLink, setEventLink] = useState('');

    const changeCalendarWidgetStatus = (value) => {
        setUpdatingStatus(true);
        updateCalendarWidgetStatus({
            calendarId : props.calendarDetails.id,
            status : value,

        }).then(res => {
            if(res.status == 200){
                setUpdatingStatus(false);
                if(value == true){
                    if(res.data.status == true){
                        setStatus(1);
                        if(res.data.data.permalink !== undefined)
                            setEventLink(res.data.data.permalink);
                    }else{
                        CustomConfirmAlertMod({
                            onSubmit : () => {
                                return;
                            },
                            title: 'Sorry!',
                            description: `Action can not be performed, Title is required`,
                            submitText: 'Ok, I understand'
                        });
                    }
                }else{
                    setStatus(0);
                }
            }
        });

    }


    useEffect(()=>{

        setCalendarDetails(props.calendarDetails);
        if(props.calendarDetails.id != undefined){
            setTitle(props.calendarDetails.title);
            setEventLink(props.calendarDetails.permalink);
            setStatus(props.calendarDetails.status);
        }
    }, [props.calendarDetails])

    useEffect(()=>{
        if(props.turnOnCalendar && props.calendarDetails.id != undefined && props.calendarDetails.status == 0 && !isTempUser){
            changeCalendarWidgetStatus(1);
        }
    }, [props.turnOnCalendar])

    let calendarUrl = '';

    if(props.calendarDetails.api_key != undefined){
        calendarUrl = root_url+'/calendar/'+props.calendarDetails.api_key+'/';
    }

    return (
        <>
            <div className="page_heading">
                <div className="title">
                    <Link to={`/calendar-widgets`} className="back">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.875 9C0.875 13.4871 4.51289 17.125 9 17.125C13.4871 17.125 17.125 13.4871 17.125 9C17.125 4.51289 13.4871 0.875 9 0.875C4.51289 0.875 0.875 4.51289 0.875 9ZM9.625 5.87148L7.14141 8.375H12.9844V9.625H7.14141L9.625 12.1285L8.73828 13.009L4.76016 9L8.73828 4.99102L9.625 5.87148Z"
                                fill="white"/>
                        </svg>
                        <span>Back</span>
                    </Link>
                    <h4>{title ? `Edit ${title}` : 'New Event'}</h4>
                </div>
                {
                    !isTempUser && (
                        <div className="action">
                            <p>Event status: {/* <span>{status ? 'ON':'OFF'}</span> */}</p>
                            {updatingStatus &&
                                <div className={classes.root}>
                                    <CircularProgress className={"custom-loader-size"}/>
                                </div>
                            }

                            {!updatingStatus &&
                                <CustomSwitch
                                    status={status == 1}
                                    brandColor={'#f94f72'}
                                    onChange={() => changeCalendarWidgetStatus(status == 1 ? 0 : 1)}
                                />
                            }

                            { status == 1 ?
                                <a href={calendarUrl + eventLink} target="_blank" className="view">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.1875 11.25H14.0625C13.9133 11.25 13.7702 11.3093 13.6648 11.4148C13.5593 11.5202 13.5 11.6633 13.5 11.8125V15.75H2.25V4.5H7.3125C7.46168 4.5 7.60476 4.44074 7.71025 4.33525C7.81574 4.22976 7.875 4.08668 7.875 3.9375V2.8125C7.875 2.66332 7.81574 2.52024 7.71025 2.41475C7.60476 2.30926 7.46168 2.25 7.3125 2.25H1.6875C1.23995 2.25 0.810725 2.42779 0.494257 2.74426C0.17779 3.06072 0 3.48995 0 3.9375L0 16.3125C0 16.7601 0.17779 17.1893 0.494257 17.5057C0.810725 17.8222 1.23995 18 1.6875 18H14.0625C14.5101 18 14.9393 17.8222 15.2557 17.5057C15.5722 17.1893 15.75 16.7601 15.75 16.3125V11.8125C15.75 11.6633 15.6907 11.5202 15.5852 11.4148C15.4798 11.3093 15.3367 11.25 15.1875 11.25ZM17.1562 0H12.6562C11.905 0 11.5295 0.910898 12.0586 1.44141L13.3147 2.69754L4.74609 11.263C4.66743 11.3414 4.60502 11.4345 4.56244 11.5371C4.51985 11.6397 4.49793 11.7496 4.49793 11.8607C4.49793 11.9717 4.51985 12.0817 4.56244 12.1842C4.60502 12.2868 4.66743 12.3799 4.74609 12.4583L5.54309 13.2539C5.62147 13.3326 5.71462 13.395 5.81718 13.4376C5.91974 13.4802 6.02969 13.5021 6.14074 13.5021C6.25179 13.5021 6.36175 13.4802 6.46431 13.4376C6.56687 13.395 6.66001 13.3326 6.7384 13.2539L15.3028 4.68703L16.5586 5.94141C17.0859 6.46875 18 6.09961 18 5.34375V0.84375C18 0.619974 17.9111 0.405362 17.7529 0.247129C17.5946 0.0888948 17.38 0 17.1562 0V0Z"
                                            fill="White"/>
                                    </svg>
                                    <span>View Live Page</span>
                                </a> : ''
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
}


const mapStateToProps = state => {
    return {
        calendarDetails : state.calendarWidgetReducer.calendarWidgetDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
}

const CalendarWidgetTopPart = connect(mapStateToProps, mapDispatchToProps)(App);

export default CalendarWidgetTopPart;
