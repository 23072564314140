import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Styles from './style.module.css'

const CustomConfirmAlert = (props) => {
  let default_params = {
    type: 'warning',
    title: '',
    description: '',
    highlightText: '',
    cancelText: '',
    submitText: 'Yes, Delete it',
    onCloseClick: () => {},
    onSubmit: () => {},
    closeOnClickOutside: true,
    width: '400px',
    height: ''
  }
  let data_params = {...default_params, ...props}
  let {
    title, description, closeOnClickOutside, highlightText, cancelText, submitText, onCloseClick, onSubmit, width, height
  } = data_params
  const handleSubmit = (onClose) => {
    onSubmit();
    onClose();
  }
  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={Styles.mainWrp} style={{ width: width, height: height }}>
            <div className={`${Styles.bodyWrp} cf_scroll_bar`}>
              <span className={Styles.bodyTitle}>{title}</span>
              <span className={Styles.descriptionText}>{description}</span>
            </div>
            <div className={Styles.footerWrp}>
                <span onClick={() => {handleSubmit(onClose)}} className={`${Styles.button} ${Styles.submitButtonWarning}`}>{submitText}</span>
                {
                    cancelText && (<span onClick={() => { onCloseClick(); onClose();}} className={`${Styles.button} ${Styles.cancelButton}`}>{cancelText}</span>)
                }
            </div>
          </div>
        );
      },
      closeOnClickOutside: closeOnClickOutside,
      overlayClassName: Styles.overlay
    })
  )
}
export default CustomConfirmAlert;