import React, {useEffect, useState} from 'react';
import './TaskAppointmentModal.css';
import TaskSingleItem from './TaskSingleItem';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

import {
    fetchActivityCalendar,
    fetchActivityListData,
    fetchTaskCount, updateActiveTaskId,
    updateActivityActiveTab
} from "../../../actions/calendarAction";
import {connect} from "react-redux";
import Moment from "moment";
import {TASK_ALL, TASK_MEETING} from "../../../constants/calendarActionTypes";
import { useIsMounted } from '../../../hooks/IsMounted';
import { Slide } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));




const App = (props) => {

    const classes = useStyles();
    const isMounted = useIsMounted();
    const [activityCalendar, setActivityCalendar] = useState(props.activityCalendar);
    const [activeTaskId, setActiveTaskId] = useState(props.activeTaskId);
    const [activeTab, setActiveTab] = useState(props.activeTabActivityCalendar);
    const [loading, setLoading] = useState(true);

    const updateActiveTaskTabId = (taskId) => {
        setActiveTaskId(taskId);
    }

    const userActivityRow = (type) => {

        let activityDate = '';
        let newDate = true;
        let mainDate = '';

        if(activityCalendar.length > 0){
            let activity = activityCalendar.map(function (activity,index){
                if(activity){
                    activityDate = activity && activity.taskDate !== undefined && activity['taskDate'] !=null ? Moment(activity['taskDate']).format('MMMM Do'):'';
                    if(mainDate != activityDate){
                        mainDate = activityDate;
                        newDate = true;
                    }else{
                        newDate = false;
                    }
    
                    return  <div className = "calendar__activity__modal__appointment" key={`${index}${activity['id']}`} >
    
                        {newDate &&
                        <p className="calendar__activity__modal__date text-center">{mainDate}</p>
                        }
                        <div className='calendar__activity_modal_tab_task'>

                         <TaskSingleItem showTaskAppointmentModal={ (item) => showTaskAppointmentModal(item)}
                                        fetchPageData={ () => fetchPageData()}
                                        item={activity} 
                                        setOpenTaskAppointmentListModal={props.setOpenTaskAppointmentListModal}                                    
                                        />

                            </div>
                    </div>
                }

            });

            return activity
        }else{
            return <p className="text-center">Not found</p>
        }

    }

    const showTaskAppointmentModal = (item) => {
        props.openTaskAppointmentModal(item);
    }

    useEffect(()=>{
       setActivityCalendar(props.activityCalendar)
    },[props.activityCalendar]);

    useEffect(()=>{
        if (isMounted) {
            setLoading(true);
            updateActiveTaskId(activeTaskId);
            fetchPageData();
        }
    }, [activeTaskId]);

    const fetchPageData = () => {
        props.fetchActivityCalendar({
            'task_id' : activeTaskId,
            'activeTab' : activeTab
        });

        props.fetchTaskCount({
            'task_id' : activeTaskId,
            'activeTab' : activeTab
        });
    }

    useEffect(()=>{
        setLoading(false);
    }, [activityCalendar])


    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.openTaskAppointmentListModal}
                onClose={props.setOpenTaskAppointmentListModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >


                <Slide direction={"left"} in={props.openTaskAppointmentListModal}>
                    <div id="task_appointment_modal" className={ "task__appointment__modal__extra modal mr-4 d-block awesome__scroll_bar " + (loading ? " data-loading" : "")}>
                        <div className="modal-content task__appointment__modal__extra_container p-0">
                            <span className='task__appointment__modal__extra_close'onClick={props.setOpenTaskAppointmentListModal}>
                                 <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m15.125 17.081 2.01-2.012L6.879 4.805l-2.01 2.012L15.124 17.08Z" fill="#fff"/><path d="M6.878 17.084 17.136 6.819l-2.01-2.012-10.26 10.265 2.011 2.012Z" fill="#fff"/></svg>
                            </span>
                            <div className="row">
                                <div className="col s12 p-0">
                                    <h2 className='task__appointment__modal__extra_title'>Task List</h2>
                                    
                                </div>
                                <div className="col s12 p-0">
                                    <ul className="tabs task__appointment__modal__extra_ul">
                                        <li onClick={()=>setActiveTaskId(TASK_ALL)} className="tab" >
                                            <a className={(activeTaskId == TASK_ALL ? 'active' : '') + ' p-0 d-flex justify-content-center align-items-center' } >
                                                <span className="mt-2 mr-2">
                                                    <svg width="16" height="16" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.0832 2.84267L11.4388 1.19823C11.2974 1.06346 11.1096 0.988281 10.9143 0.988281C10.719 0.988281 10.5312 1.06346 10.3899 1.19823L8.92767 2.6649H1.08322C0.847477 2.6649 0.621384 2.75855 0.454685 2.92524C0.287986 3.09194 0.194336 3.31804 0.194336 3.55378V13.3316C0.194336 13.5673 0.287986 13.7934 0.454685 13.9601C0.621384 14.1268 0.847477 14.2205 1.08322 14.2205H10.861C11.0968 14.2205 11.3228 14.1268 11.4895 13.9601C11.6562 13.7934 11.7499 13.5673 11.7499 13.3316V5.2249L13.0832 3.89156C13.2222 3.7524 13.3002 3.56378 13.3002 3.36712C13.3002 3.17046 13.2222 2.98184 13.0832 2.84267ZM6.78545 8.9449L4.92322 9.35823L5.36767 7.51378L9.61211 3.26045L11.0477 4.69601L6.78545 8.9449ZM11.5277 4.18934L10.0921 2.75378L10.9143 1.93156L12.3499 3.36712L11.5277 4.18934Z" />
                                                    </svg>
                                                </span>
                                                <span>Tasks</span>
                                            </a>
                                        </li>
                                        <li onClick={()=>setActiveTaskId(TASK_MEETING)} className="tab" >
                                            <a className={(activeTaskId == TASK_MEETING ? 'active' : '') + ' p-0 d-flex justify-content-center align-items-center' } >
                                            <span className="mt-2 mr-2">
                                                <svg width="16" height="16" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.25033 13.6654H11.5837C12.319 13.6654 12.917 13.0674 12.917 12.332V2.9987C12.917 2.26336 12.319 1.66536 11.5837 1.66536H10.2503V0.332031H8.91699V1.66536H4.91699V0.332031H3.58366V1.66536H2.25033C1.51499 1.66536 0.916992 2.26336 0.916992 2.9987V12.332C0.916992 13.0674 1.51499 13.6654 2.25033 13.6654ZM2.25033 3.66536H11.5837V4.9987H2.25033V3.66536Z" />
                                                </svg>
                                            </span>
                                                <span>Appointment</span>
                                            </a>
                                        </li>
                                        {/* <li className="tab col s2 calendar-task-appointment-close" onClick={props.setOpenTaskAppointmentListModal}>
                                            <CloseIcon font/>
                                            Close
                                        </li> */}
                                    </ul>
                                </div>
                                <div id="task_appointment_task_tab" className={"col s12 p-0 white " + (loading ? " data-loader-circle" : "")}>
                                    { loading ? <div className={classes.root}>
                                        <CircularProgress />
                                    </div> : userActivityRow()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Modal>
       </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        activeTabActivityCalendar : state.calendarReducer.activeTabActivityCalendar,
        activityCalendar : state.calendarReducer.activityCalendar,
        activeTaskId : state.calendarReducer.activeTaskId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchActivityCalendar : (params) => dispatch(fetchActivityCalendar(params)),
        fetchTaskCount : (params) => dispatch(fetchTaskCount(params)),
        updateActivityActiveTab: (params) => dispatch(updateActivityActiveTab(params)),
        updateActiveTaskId: (params) => dispatch(updateActiveTaskId(params)),
    };
}

const TaskAppointmentModal = connect(mapStateToProps, mapDispatchToProps)(App);

export default TaskAppointmentModal;