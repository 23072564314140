import * as ACTION from "../constants/calendarActionTypes";
import React from "react";
import {updateConfirmationSection} from "../api/calendarApi";

const initialState = {
    calendarWidgetList : [],
    calendarWidgetDetails : [],
    calendarWidgetScheduleDetails : [],
    calendarWidgetEventsSlot : [],
    calendarWidgetSchedulesArr : [],
    calendarWidgetSchedulesStartDate : null,
    calendarWidgetSchedulesEndDate : null
};

const calendarWidgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.CALENDAR_WIDGET_LIST:
            return {...state, calendarWidgetList: action.payload}

        case ACTION.CALENDAR_WIDGET_DETAILS:
            return {...state, calendarWidgetDetails: action.payload}

        case ACTION.CALENDAR_WIDGET_SCHEDULE_DETAILS:
            return {...state, calendarWidgetScheduleDetails: action.payload}

        case ACTION.CALENDAR_WIDGET_SCHEDULES_ARR:
            return {...state, calendarWidgetSchedulesArr: action.payload}

        case ACTION.CALENDAR_WIDGET_SCHEDULES_START_DATE:
            return {...state, calendarWidgetSchedulesStartDate: action.payload}

        case ACTION.CALENDAR_WIDGET_SCHEDULES_END_DATE:
            return {...state, calendarWidgetSchedulesEndDate: action.payload}

        case ACTION.UPDATE_CALENDAR_EVENTS_SLOT:
            return {...state, calendarWidgetEventsSlot: action.payload}

        case ACTION.REGENERATE_CALENDAR_EVENTS:
            return {...state, calendarWidgetScheduleDetails: action.payload}


        default:
            return state;
    }
}

export default calendarWidgetReducer;
