import React, {useState, useEffect, useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3), 
    },
}));



const App = (props) => {
    const classes = useStyles();
    const [hostName, setHostName] = useState('https://'+window.location.hostname);
    const [copyBtnTitle, setCopyBtnTitle] = useState('Copy Snippet');
    const activityClass ="waves-effect py-2 px-3 radius-5 d-flex justify-content-center align-items-center mr-2";

    const changeText=() => {
        setCopyBtnTitle('Copied');
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.openEmbedCodeModal}
                onClose={props.closeEmbedCodeModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openEmbedCodeModal}>
                    <div id="create_calendar" className="create_calendar_widget modal d-block">
                        <div className="modal-content">
                            <div className="header">
                                <h5>Calender Widget Embed Code</h5>
                            </div>
                            <div className="modal_article">
                                <div className="row">
                                    <div className="col s12">
                                        <label>Embed Code</label>
                                        <br/>
                                        <small>Place this code in your page's HTML where you want your calendar page to appear.</small>
                                    </div>
                                    <div className="col s12">
                                        <div className="form-group">
                                            <textarea className="form-control code-snippet" rows="5"  readOnly="readonly"
                                                      defaultValue={'<!-- Calendar widget begin -->\n<iframe src='+hostName+'/calendar/'+props.api_key+'/'+props.calendarTitle+' height="100%" width="100%"></iframe>\n<!-- Calendar widget end -->'}>
                                             </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal_footer">
                                <button onClick={()=>props.closeEmbedCodeModal()}  type="button" className="modal_btn closs_btn modal-close">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.1246 2.91771C13.2247 -0.972568 6.82484 -0.972568 2.92493 2.91771C-0.974976 6.80798 -0.974976 13.192 2.92493 17.0823C6.82484 20.9726 13.1247 20.9726 17.0246 17.0823C20.9245 13.192 21.0245 6.80798 17.1246 2.91771ZM12.8247 14.1895L10.0248 11.3965L7.22483 14.1895L5.82486 12.793L8.62479 10L5.82486 7.20698L7.22483 5.81047L10.0248 8.60349L12.8247 5.81047L14.2247 7.20698L11.4247 10L14.2247 12.793L12.8247 14.1895Z"
                                            fill="white"/>
                                    </svg>
                                    Close
                                </button>
                                <CopyToClipboard text={'<!-- Calendar widget begin -->\n<iframe src='+hostName+'/calendar/'+props.api_key+'/'+props.calendarTitle+' height="100%" width="100%"></iframe>\n<!-- Calendar widget end -->'}
                                                 onCopy={changeText}>
                                    <button type="button" className="modal_btn create_btn">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10" cy="10" r="10" fill="white"/>
                                            <path
                                                d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
                                                fill="#3C7EF3"/>
                                        </svg>
                                        {copyBtnTitle}
                                    </button>
                                </CopyToClipboard>

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}


export default App;
