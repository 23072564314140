import React, { Component } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import Calendar from "../components/Calendar";
import CalendarWidget from "../components/CalendarWidget";
import CalendarWidgetApp from "../components/CalendarWidget/Details";
import PreviewScheduleCalendar from "../components/CalendarWidget/Preview";
import HealthCheck from '../components/HealthCheck';

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>

                    <Route path="/activity-calendar" component={Calendar}/>

                    <Route path="/calendar-widgets" component={CalendarWidget}/>

                    <Route path="/calendar-widget/:calendarWidgetId" component={CalendarWidgetApp}/>

                    <Route path="/calendar/:uniqueId/:permalink" component={PreviewScheduleCalendar}/>

                    <Route exact path="/calendar/health-check" component={HealthCheck}/>

                </Switch>
            </Router>
        );
    }
}

export default Routes;