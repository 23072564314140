import React, {createContext} from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import {createGenerateClassName, StylesProvider} from "@material-ui/core";
import Utils from "./helpers/Utils";

const defaultHistory = createBrowserHistory();

const generateClassName = createGenerateClassName({
    productionPrefix: 'clndr_' + Utils.generateRandomString(5),
});
export const HistoryProvider = createContext({});

function App({ history = defaultHistory }) {
    return (
        <HistoryProvider.Provider value={history}>
            <Router history={history}>
                <StylesProvider generateClassName={generateClassName}>
                    <Routes />
                </StylesProvider>
            </Router>
        </HistoryProvider.Provider>
    );
}

export default App;
