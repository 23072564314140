import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {updateCalendarSection} from "../../../api/calendarApi";
import {confirmAlert} from "react-confirm-alert";
import ScheduleCalendar from './ScheduleCalendar';
import DateRangeModal from "./DateRangeModal";
import {regenerateCalendarEvents} from "../../../actions/calendarWidgetAction";
import moment from 'moment';

const App = (props) => {

    const [loading, setLoading] = useState(true);
    const [calendarDetails, setCalendarDetails] = useState(props.calendarDetails);
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState(0);
    const [calendarStartDate, setCalendarStartDate] = useState(null);
    const [calendarEndDate, setCalendarEndDate] = useState(null);
    const [eventDuration, setEventDuration] = useState('15');
    const [availabilityIncrements, setAvailabilityIncrements] = useState(30);
    const [customDuration, setCustomDuration] = useState(false);
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [btnTitle, setBtnTitle] = useState('Save & Go To Next Step');
    const [rollingDays, setRollingDays] = useState(props.calendarDetails.rolling_days);

    const handleSubmit = () => {

        setBtnTitle('Processing . . .');
        updateCalendarSection({
            calendarId : props.calendarDetails.id,
            duration : eventDuration,
            start_date : calendarStartDate,
            end_date : calendarEndDate,
            date_range_type : props.calendarDetails.date_range_type,
            rolling_days : rollingDays,
            availabilityIncrements : availabilityIncrements,
            schedules : props.calendarWidgetEventsSlot,

        }).then(res => {
            if(res.status == 200){
                setBtnTitle('Saved successfully');
                props.openNextSection();
            }else{
                setBtnTitle('Save Booking Time & Go for Confirmation Setting');
            }
        });
    }

    const closeDateRangeModal = () => {
        setShowDateRangeModal(false);
    }
    const generateEventRange = (startDate, endDate, oldEndDate, rollingDaysOverPeriod) => {
        setCalendarStartDate(startDate);
        setCalendarEndDate(endDate);
        setRollingDays(rollingDaysOverPeriod);

        props.regenerateCalendarEvents({
            'calendar_id' : props.calendarDetails.id,
            'startDate' : startDate,
            'endDate' : endDate,
            'oldEndDate' :  oldEndDate,
            'events' : [],
            'rolling_days' : rollingDaysOverPeriod,
            // 'events' : props.calendarWidgetEventsSlocalendarWidgetEventsSlott,
        });

        closeDateRangeModal();
    }

    useEffect(()=>{
        setEventDuration(customDuration);
    }, [customDuration])


    useEffect(()=>{
        setCalendarDetails(props.calendarDetails);
        if(props.calendarDetails.id != undefined){
            setTitle(props.calendarDetails.title);
            setStatus(props.calendarDetails.status);
            setEventDuration(props.calendarDetails.duration);
            setCalendarStartDate(props.calendarDetails.start_date);
            setCalendarEndDate(props.calendarDetails.end_date);
            setAvailabilityIncrements(props.calendarDetails.availability_increments);
            setCustomDuration(props.calendarDetails.duration === 60 || props.calendarDetails.duration === 45 || props.calendarDetails.duration === 30 || props.calendarDetails.duration === 15 ? false : props.calendarDetails.duration,);
        }
    }, [props.calendarDetails])

    return (
        <>
            <div className="row accordion__event_wrapper">
                <div className="col m4 s12">
                    <div className="input_group">
                        <label className="m--font-bolder event_sub__title">Event Duration</label><br/>
                        <div className="event-duration-wrapper">
                            <ul className="event-duration-items">
                                <li onClick={()=>setEventDuration(15)} className={eventDuration == 15 ? 'event-duration-list active custom-pointer-list' : ' custom-pointer-list'} >
                                    <h5 className="event-duration-title">15</h5>
                                    <div className="event-duration-desc">
                                        min
                                    </div>
                                </li>
                                <li onClick={()=>setEventDuration(30)} className={eventDuration == 30 ? 'event-duration-list active custom-pointer-list' : ' custom-pointer-list'} >
                                    <h5 className="event-duration-title">30</h5>
                                    <div className="event-duration-desc">
                                        min
                                    </div>
                                </li>
                                <li onClick={()=>setEventDuration(45)} className={eventDuration == 45 ? 'event-duration-list active custom-pointer-list' : 'custom-pointer-list'} >
                                    <h5 className="event-duration-title">45</h5>
                                    <div className="event-duration-desc">
                                        min
                                    </div>
                                </li>
                                <li onClick={()=>setEventDuration(60)} className={eventDuration == 60 ? 'event-duration-list active custom-pointer-list' : 'custom-pointer-list'} >
                                    <h5 className="event-duration-title">60</h5>
                                    <div className="event-duration-desc">
                                        min
                                    </div>
                                </li>
                                <li className={"event-duration-list event-custom-duration "+(customDuration ? 'active' : '') }>
                                    <input type="number" min={0} name="custom_duration" placeholder="-" value={customDuration} onChange={(event)=>setCustomDuration(event.target.value)} />
                                    <div className="event-duration-desc">
                                        custom min
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col m4 s12">
                <div className="form-group">
                    <label className="m--font-bolder event_sub__title">Date Range</label>
                    <p className="d-flex mt-0">
                        <span className="body-small-text">Set calendar schedule period. </span>
                        <span onClick={()=>setShowDateRangeModal(true)} className={"date-range-edit d-flex align-items-center ml-2"} > 
                        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" fill="#3C7EF3" fill-opacity=".1" r="8"/><g clip-path="url(#a)" fill="#3C7EF3"><path d="M5.317 9.16a.22.22 0 0 0-.06.11l-.307 1.542a.219.219 0 0 0 .256.257l1.541-.309a.217.217 0 0 0 .112-.06l3.449-3.448L8.767 5.71 5.317 9.16zM11.37 4.65a1.09 1.09 0 0 0-1.54 0l-.603.604 1.54 1.541.604-.603c.205-.206.319-.48.319-.77 0-.292-.114-.566-.32-.771z"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(1.23 4.309)" d="M0 0h10.461v10.461H0z"/></clipPath></defs></svg>
                         <span className="ml-2 custom-cursor-pointer">edit </span>
                        </span>
                    </p>
                </div>
                    <DateRangeModal
                        isOpen={showDateRangeModal}
                        hideModal={() => closeDateRangeModal(false)}
                        generateEventRange = {(startDate, endDate, oldEndDate, rollingDaysOverPeriod)=>generateEventRange(startDate, endDate, oldEndDate, rollingDaysOverPeriod)}
                    />
                </div>

                <div className="col m4 s12">

                <div className="form-group event-time-boxes">
                    <label className="m--font-bolder event_sub__title">Availability Increments</label>
                    <p className="mt-0"><span className="body-small-text ">Set the frequency of available time slots for your invitees.</span></p>
                    <select value={availabilityIncrements} onChange={(event)=>setAvailabilityIncrements(event.target.value)} className="form-control mb-2 p-5-10" name="availability_increments" >
                        <option value={15}> 15 min</option>
                        <option value={30}> 30 min</option>
                        <option value={45}> 45 min</option>
                        <option value={60}> 60 min</option>
                        <option value={75}> 75 min</option>
                        <option value={90}> 90 min</option>
                        <option value={105}> 105 min</option>
                        <option value={120}> 120 min</option>
                    </select>
                </div>
                </div>

               

            </div>
            <div className="row">
                <div className="col s12 event-calendar-toolbar">
                    <ScheduleCalendar/>
                </div>
            </div>
            <div className="row">
            <div className="col s12">
                <div className="collapsible_footer">
                    <button type="button" className="btn btn_danger d-inline-flex align-items-center btnSetHeight">
                        <span className="mr-2">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                        </span>
                        Cancel
                    </button>
                    <button onClick={()=>handleSubmit()} type="button" className="btn btn_info d-inline-flex align-items-center btnSetHeight accent--bg--color ">
                        <span className="mr-2"><svg className="MuiSvgIcon-root" focusable="false"
                                                    viewBox="0 0 24 24" aria-hidden="true"><path
                            d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg></span>
                        {btnTitle}</button>
                </div>
                </div>
            </div>

                
        </>
    );
}


const mapStateToProps = state => {
    return {
        calendarDetails : state.calendarWidgetReducer.calendarWidgetDetails,
        calendarWidgetEventsSlot : state.calendarWidgetReducer.calendarWidgetEventsSlot,
        calendarWidgetScheduleDetails : state.calendarWidgetReducer.calendarWidgetScheduleDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        regenerateCalendarEvents : (params) => dispatch(regenerateCalendarEvents(params)),
    };
}

const CalendarWidgetCalendarSection = connect(mapStateToProps, mapDispatchToProps)(App);

export default CalendarWidgetCalendarSection;
