import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import {fetchCampaignList, updateConfirmationSection} from "../../../api/calendarApi";
import {} from "../../../actions/calendarWidgetAction";
import {Editor} from "@tinymce/tinymce-react";
import SimpleReactValidator from "simple-react-validator";
import EachTiming from "./EachTiming";
import GlobalCustomFieldGrouped from "../../Common/GlobalCustomFieldGrouped/Index";
import Utils from '../../../helpers/Utils';

const templateData = {
    confirmation_title: 'Confirmed: {{event_name}} with {{my_name}} on {{event_date}}',
    confirmation_body: '<p>Hello {{invitee_full_name}},</p><p>Your {{event_name}} with {{my_name}} at {{event_time}} on {{event_date}} is scheduled.</p>' +
        '<p>{{event_description}}</p> <p>{{location}}</p>',
    cancellation_title: 'Canceled: {{event_name}} with {{my_name}} on {{event_date}}',
    cancellation_body: '<p>Hello {{invitee_full_name}},</p><p>Your {{event_name}} with {{my_name}} at {{event_time}} on {{event_date}} has been canceled.</p>',
    text_reminder_text: 'Reminder: {{event_name}} with {{my_name}} at {{event_time}} on {{event_date}}',
    email_reminder_subject: 'Reminder: {{event_name}} with {{my_name}} at {{event_time}} on {{event_date}}',
    email_reminder_body: '<p>Hello {{invitee_full_name}},</p><p>This is a friendly reminder that your {{event_name}} with {{my_name}} is at {{event_time}} on {{event_date}}.</p><p>{{location}}</p><p>{{event_description}}</p>',
}

const App = (props) => {
    /* TODO: input field ref */
    const confirmationTitleRef = useRef(null)
    const confirmationBodyRef = useRef(null)
    const cancelationTitleRef = useRef(null)
    const cancelationBodyRef = useRef(null)
    const textReminderTextRef = useRef(null)
    const emailReminderTextRef = useRef(null)
    const emailReminderBodyRef = useRef(null)

    const [loading, setLoading] = useState(true);
    const [calendarDetails, setCalendarDetails] = useState(props.calendarDetails);

    const [showPersonalizeInvitation, setShowPersonalizeInvitation] = useState(0);
    const [confirmation_title, setConfirmation_title] = useState(templateData.confirmation_title);
    const [confirmation_body, setConfirmation_body] = useState(templateData.confirmation_body);

    const [showPersonalizeCancellation, setShowPersonalizeCancellation] = useState(0);
    const [cancellation_title, setCancellation_title] = useState(templateData.cancellation_title);
    const [cancellation_body, setCancellation_body] = useState(templateData.cancellation_body);

    const [showPersonalizeTextReminder, setShowPersonalizeTextReminder] = useState(0);
    const [textReminderChecked, setTextReminderChecked] = useState(0);
    const [text_reminder_text, setText_reminder_text] = useState(templateData.text_reminder_text);


    const [showPersonalizeEmailReminder, setShowPersonalizeEmailReminder] = useState(0);
    const [emailReminderChecked, setEmailReminderChecked] = useState(0);
    const [email_reminder_subject, setEmail_reminder_subject] = useState(templateData['email_reminder_subject']);
    const [email_reminder_body, setEmail_reminder_body] = useState(templateData['email_reminder_body']);

    const [newTextReminderRow, setNewTextReminderRow]= useState([]);
    const [newEmailReminderRow, setNewEmailReminderRow]= useState([]);
    const [text_reminder_timings, setText_reminder_timings]= useState(['24']);
    const [text_reminder_units, setText_reminder_units]= useState(['hours']);
    const [email_reminder_timings, setEmail_reminder_timings]= useState(['24']);
    const [email_reminder_units, setEmail_reminder_units]= useState(['hours']);
    const [text_reminder_timing_generator, setText_reminder_timing_generator]= useState(true);
    const [email_reminder_timing_generator, setEmail_reminder_timing_generator]= useState(true);
    const [campaign_id, setCampaign_id]= useState(null);
    const [confirmation_link, setConfirmation_link]= useState('');
    const [button_text, setButton_text]= useState('');
    const [show_button_after_confirm, setShow_button_after_confirm]= useState(0);

   const timeSMSValidator = new SimpleReactValidator();
   const timeEmailValidator = new SimpleReactValidator();
   const [campaignList, setCampaignList] = useState([]);
   const [btnTitle, setBtnTitle] = useState('Save Notifications and Cancellation Policy');

   /* for reminder title and message */
   const [textReminderTexts, setTextReminderTexts]= useState([]);
   const [emailReminderData, setEmailReminderData]= useState([]);

    const apiKeys = () => {
        return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
    }

   const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    const handleSubmit = () => {
        setBtnTitle('Processing . . .');

        updateConfirmationSection({
            calendarId : props.calendarDetails.id,
            campaign_id : campaign_id,
            show_button_after_confirm : show_button_after_confirm,
            email_confirmation_title : confirmation_title,
            email_confirmation_body :confirmation_body,
            email_cancellation_title : cancellation_title,
            email_cancellation_body : cancellation_body,
            text_reminder_message : text_reminder_text,
            email_reminder_title : email_reminder_subject,
            email_reminder_body : email_reminder_body,
            text_reminder_timings : text_reminder_timings,
            email_reminder_timings : email_reminder_timings,
            emailReminderChecked : emailReminderChecked,
            textReminderChecked : textReminderChecked,
            text_reminder_units : text_reminder_units,
            email_reminder_units : email_reminder_units,
            text_reminder_texts: textReminderTexts,
            email_reminder_data: emailReminderData
        }).then(res => {
            if(res.status == 200){
                setBtnTitle('Saved successfully');
                props.openNextSection();
            }else{
                setBtnTitle('Save Notifications and Cancellation Policy');
            }
        });
    }

    useEffect(()=>{
        setCalendarDetails(props.calendarDetails);

        setCampaign_id(props.calendarDetails.campaign_id);

        if(props.calendarDetails.email_confirmation_title != null)
            setConfirmation_title(props.calendarDetails.email_confirmation_title);

        if(props.calendarDetails.email_confirmation_body != null)
            setConfirmation_body(props.calendarDetails.email_confirmation_body);

        if(props.calendarDetails.email_cancellation_body != null)
            setCancellation_body(props.calendarDetails.email_cancellation_body);

        if(props.calendarDetails.email_cancellation_title != null)
            setCancellation_title(props.calendarDetails.email_cancellation_title);

        if(props.calendarDetails.email_reminder_body != null)
            setEmail_reminder_body(props.calendarDetails.email_reminder_body);

        setEmailReminderChecked(props.calendarDetails.email_reminder_check);
        setTextReminderChecked(props.calendarDetails.text_reminder_check);

        if(props.calendarDetails.email_reminder_title != null)
            setEmail_reminder_subject(props.calendarDetails.email_reminder_title);

        if(props.calendarDetails.text_reminder_message != null)
            setText_reminder_text(props.calendarDetails.text_reminder_message);

        setConfirmation_link(props.calendarDetails.confirmation_link);
        setButton_text(props.calendarDetails.button_text);
        setShow_button_after_confirm(props.calendarDetails.show_button_after_confirm);
        setText_reminder_timings(props.calendarDetails.text_reminder_timings);
        setText_reminder_units(props.calendarDetails.text_reminder_units);
        setEmail_reminder_timings(props.calendarDetails.email_reminder_timings);
        setEmail_reminder_units(props.calendarDetails.email_reminder_units);

        let newTextReminderRowArr = [];

        if(props.calendarDetails.text_reminder_timings != null && props.calendarDetails.text_reminder_timings != [] && typeof props.calendarDetails.text_reminder_timings != 'number'){
            props.calendarDetails.text_reminder_timings.map((row, index) => {
                newTextReminderRowArr.push(<EachTiming key={0} RowIndex={0}/>);
            });

            setNewTextReminderRow(newTextReminderRowArr);
        }

        let newEmailReminderRowArr = [];

        if(props.calendarDetails.email_reminder_timings != null && props.calendarDetails.email_reminder_timings != [] && typeof props.calendarDetails.email_reminder_timings != 'number'){
            props.calendarDetails.email_reminder_timings.map((row, index) => {
                newEmailReminderRowArr.push(<EachTiming key={0} RowIndex={0}/>);
            });

            setNewEmailReminderRow(newEmailReminderRowArr);
        }

        setTimeout(() => {
            try{
                let reminder_timing = props.calendarDetails.reminder_timing;
                let parse_data = JSON.parse(reminder_timing);
                if(parse_data.text_reminder_texts !== undefined){
                    setTextReminderTexts(parse_data.text_reminder_texts)
                    setText_reminder_timing_generator((parse_data.text_reminder_texts.length === 2 ? false: true))
                }
                if(parse_data.email_reminder_data !== undefined){
                    setEmailReminderData(parse_data.email_reminder_data[0] ?? [])
                    setEmail_reminder_timing_generator((parse_data.email_reminder_data[0].length === 2 ? false : true))
                }
                
            }catch(error){
    
            }
        }, 1000);
    }, [props.calendarDetails]);

    useEffect(()=>{
        fetchCampaignList({}).then(res => {
            if(res.status == 200){
                setCampaignList(res.data.data[0]);
            }
        });

    }, [])

    useEffect(()=>{
        if(!emailReminderChecked)
            setShowPersonalizeEmailReminder(0);
    },[emailReminderChecked])

    useEffect(()=>{
        if(!textReminderChecked)
            setShowPersonalizeTextReminder(0);
    },[textReminderChecked])

    const onTimeChange = (index, value, reminderType, checkTime=false) => {
        if (reminderType == 'text') {
            let times = [...text_reminder_timings];
            if(checkTime){
                let regExp = /^[0-9]*$/g;
                if(regExp.test(value)){
                    times[index] = value;
                }
            }
            else{
                times[index] = value ;
            }
            setText_reminder_timings(times);
        } else if(reminderType == 'email') {
            let times = [...email_reminder_timings];
            if(checkTime){
                let regExp = /^[0-9]*$/g;
                if(regExp.test(value)){
                    times[index] = value;
                }
            }
            else{
                times[index] = value ;
            }
            // times[index] = value;
            setEmail_reminder_timings(times);
        }
    }

    const onUnitChange = (index, value, reminderType) => {
        if (reminderType == 'text') {
            if(typeof text_reminder_units !== 'object'){
                let data = []
                data[index] = value;
                setText_reminder_units(data);
            }else{
                let units = [...text_reminder_units];
                units[index] = value;
                setText_reminder_units(units);
            }
            
        } else if (reminderType == 'email') {
            if(typeof email_reminder_units !== 'object'){
                let data = []
                data[index] = value;
                setEmail_reminder_units(data);
            }else{
                let units = [...email_reminder_units];
                units[index] = value;
                setEmail_reminder_units(units);
            }
            // let units = [...email_reminder_units];
            // units[index] = value;
            // setEmail_reminder_units(units);
        }
    }

    const removeTiming = (index, reminderType) => {
        if (reminderType == 'text') {
            var newArr = [...newTextReminderRow];
            let times = [...text_reminder_timings];
            let units = [...text_reminder_units];

            if (newArr.length == 1) {
                return false;
            }
            if (index !== -1) {
                newArr.splice(index, 1);
                times.splice(index, 1);
                units.splice(index, 1);
                setNewTextReminderRow(newArr);
                setText_reminder_timings(times);
                setText_reminder_units(units);
                if(newArr.length <= 2) {
                    setText_reminder_timing_generator(true);
                }
            }
        } else if (reminderType == 'email') {
            var newArr = [...newEmailReminderRow];
            let times = [...email_reminder_timings];
            let units = [...email_reminder_units];

            if (newArr.length == 1) {
                return false;
            }
            if (index !== -1) {
                newArr.splice(index, 1);
                times.splice(index, 1);
                units.splice(index, 1);

                setNewEmailReminderRow(newArr);
                setEmail_reminder_timings(times);
                setEmail_reminder_units(units);
                if(newArr.length <= 2) {
                    setEmail_reminder_timing_generator(true);
                }
            }
        }

    }

    const addNewTiming = (e, block) => {
        e.preventDefault();
        if (block == 'text_reminder') {
            let currentextReminderRow = newTextReminderRow;
            let rowIndex = currentextReminderRow.length;

            //Set default 24 hours when new timing created

            let new_text_reminder_timings = [];
            let length = 0;
            let new_text_reminder_units = [];
            let text_reminders_text = [...textReminderTexts];

            // if(email_reminder_timings != undefined && email_reminder_timings != 0){
            //     new_text_reminder_timings = text_reminder_timings;
            //     length = new_text_reminder_timings.length;
            //     new_text_reminder_units = email_reminder_units;
            // }
            if(text_reminder_timings != undefined && text_reminder_timings != 0){
                new_text_reminder_timings = text_reminder_timings;
                length = text_reminder_timings.length;
                new_text_reminder_units = text_reminder_units;
            }
            new_text_reminder_timings[length] = '24';
            new_text_reminder_units[length] = 'hours';
            if(length > 0){
                text_reminders_text[length-1] = templateData.text_reminder_text;
                setTextReminderTexts(text_reminders_text);
            }
            setNewTextReminderRow(currentextReminderRow.concat(<EachTiming remover={removeTiming} key={rowIndex} RowIndex={rowIndex}/>));
            setText_reminder_timings(new_text_reminder_timings);
            setText_reminder_units(new_text_reminder_units);


            if (rowIndex >= 2) {
                setText_reminder_timing_generator(false);
            }
        } else if (block == 'email_reminder') {
            let currentEmailReminderRow = newEmailReminderRow;
            let rowIndex = currentEmailReminderRow.length;

            //Set default 24 hours when new timing created
            let current_email_reminder_timings = [];
            let length = 0;
            let current_email_reminder_units = [];
            let email_reminders_data = [...emailReminderData];

            if(email_reminder_timings != undefined && email_reminder_timings != 0){
                current_email_reminder_timings = email_reminder_timings;
                length = current_email_reminder_timings.length;
                current_email_reminder_units = email_reminder_units;
            }

            current_email_reminder_timings[length] = '24';
            current_email_reminder_units[length] = 'hours';

            if(length > 0){
                email_reminders_data[length-1] = {
                    subject: templateData.email_reminder_subject,
                    message: templateData.email_reminder_body
                };
                setEmailReminderData(email_reminders_data ?? []);
            }

            setNewEmailReminderRow(currentEmailReminderRow.concat(<EachTiming remover={removeTiming} key={rowIndex} RowIndex={rowIndex}/>));
            setEmail_reminder_timings(current_email_reminder_timings);
            setEmail_reminder_units(current_email_reminder_units);

            if (rowIndex >= 2) {
                setEmail_reminder_timing_generator(false);
                setEmail_reminder_timing_generator(false);
            }

        }
    }

    /* TODO: for personalized */
    const handleChangePersonalizedForConfirmationTitle = (value, group_value = '') => {
        // if(group_value === 'pype_calendar_widget'){
        //     value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        // }
        let start = confirmationTitleRef.current.selectionStart;
        let end = confirmationTitleRef.current.selectionEnd;
        let text = confirmationTitleRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        setConfirmation_title(newText)
        setTimeout(() => {
            confirmationTitleRef.current.selectionStart = confirmationTitleRef.current.selectionEnd = start + value.length;
            confirmationTitleRef.current.focus();
        }, 0);
    }
    const handleChangePersonalizedForConfirmationBody = (value) => {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        confirmationBodyRef.current.insertContent(value);
        // dispatch(updateMessage({
        //     message: (states.message + value),
        //     validMessage: false,
        // }))
        setTimeout(() => {
            confirmationBodyRef.current.focus();
        }, 100);
    }
    const handleChangePersonalizedForCancelationTitle = (value) => {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        let start = cancelationTitleRef.current.selectionStart;
        let end = cancelationTitleRef.current.selectionEnd;
        let text = cancelationTitleRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        setCancellation_title(newText)
        setTimeout(() => {
            cancelationTitleRef.current.selectionStart = cancelationTitleRef.current.selectionEnd = start + value.length;
            cancelationTitleRef.current.focus();
        }, 0);
    }
    const handleChangePersonalizedForCancelationBody = (value) => {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        cancelationBodyRef.current.insertContent(value);
        // dispatch(updateMessage({
        //     message: (states.message + value),
        //     validMessage: false,
        // }))
        setTimeout(() => {
            cancelationBodyRef.current.focus();
        }, 100);
    }
    const handleChangePersonalizedForTextReminderTitle = (value) => {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        let start = textReminderTextRef.current.selectionStart;
        let end = textReminderTextRef.current.selectionEnd;
        let text = textReminderTextRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        setText_reminder_text(newText)
        setTimeout(() => {
            textReminderTextRef.current.selectionStart = textReminderTextRef.current.selectionEnd = start + value.length;
            textReminderTextRef.current.focus();
        }, 0);
    }
    const handleChangePersonalizedForEmailReminderTitle = (value) => {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        let start = emailReminderTextRef.current.selectionStart;
        let end = emailReminderTextRef.current.selectionEnd;
        let text = emailReminderTextRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        setEmail_reminder_subject(newText)
        setTimeout(() => {
            emailReminderTextRef.current.selectionStart = emailReminderTextRef.current.selectionEnd = start + value.length;
            emailReminderTextRef.current.focus();
        }, 0);
    }
    const handleChangePersonalizedForEmailReminderBody = (value) => {
        // value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        emailReminderBodyRef.current.insertContent(value);
        // dispatch(updateMessage({
        //     message: (states.message + value),
        //     validMessage: false,
        // }))
        setTimeout(() => {
            emailReminderBodyRef.current.focus();
        }, 100);
    }
    const hanldeTitleChangeForReminder = (index, value, reminderType) => {
        if (reminderType === 'text') {
            let data = [...textReminderTexts];
            data[index] = value ;
            setTextReminderTexts(data);
        }
        else if(reminderType === 'email'){
            let data = [...emailReminderData];
            data[index].subject = value ;
            setEmailReminderData(data ?? []);
        }
    }
    const hanldeMessageChangeForReminder = (index, value, reminderType) => {
        let data = [...emailReminderData];
        data[index].message = value ;
        setEmailReminderData(data ?? []);
    }

    /* render first step */
    const renderTextReminderFirstStep = () => {
        return (
            <div className="input_group">
                <div className="input_group_calender_widget_wr">
                    <label className="input_group_calender_widget_wr_title">Title <span className="m-form__help tsc-error-message" style={{ fontSize: "13px" }}>* Length cannot be greater than 255 character</span></label>
                    <div className="input_group_calender_widget_container">
                        <span onClick={()=>setText_reminder_text(templateData.text_reminder_text)} className="input_group_calender_widget_btn custom-cursor-pointer" >Restore Default</span>
                        {(props.openSection === 3 && showPersonalizeTextReminder) &&
                        <GlobalCustomFieldGrouped 
                            className={"send_module__quick__reply__global_custom__field"} 
                            labelText={"Personalized"} 
                            handleSelect={handleChangePersonalizedForTextReminderTitle}
                            forCalendarWidget={true}
                        />
                        }
                    </div>
                </div>
                <input
                    ref={textReminderTextRef}
                    value={text_reminder_text}
                    placeholder="Title"
                    type="text"
                    className="validate"
                    onChange={(event)=>{
                        let val = event.target.value;
                        if(val.length > 255){
                            val = val.slice(0,255);
                        }
                        setText_reminder_text(val);
                    }}
                />
            </div>
        );
    }


    const renderEmailReminderFirstStep = () => {
        return (
            <div className={"personalized-confirmation "} style={{marginBottom: '20px'}}>
                <div className="input_group">
                    <div className="input_group_calender_widget_wr">
                        <label className="input_group_calender_widget_wr_title">Title</label>
                        <div className="input_group_calender_widget_container">
                            <span onClick={()=>setEmail_reminder_subject(templateData.email_reminder_subject)} className="input_group_calender_widget_btn custom-cursor-pointer " >Restore Default</span>
                            {(props.openSection === 3 && showPersonalizeEmailReminder) &&
                            <GlobalCustomFieldGrouped 
                                className={"send_module__quick__reply__global_custom__field"} 
                                labelText={"Personalized"} 
                                handleSelect={handleChangePersonalizedForEmailReminderTitle}
                                forCalendarWidget={true}
                            />
                            }
                        </div>
                        
                    </div>
                    <input
                        ref={emailReminderTextRef}
                        value={email_reminder_subject}
                        placeholder="Title"
                        type="text"
                        className="validate"
                        onChange={(event)=>setEmail_reminder_subject(event.target.value)}
                    />
                </div>
                <div className="input_group_calender_widget_wr">
                    <label className="input_group_calender_widget_wr_title">Body</label>
                    <div className="input_group_calender_widget_container">
                        {(props.openSection === 3 && showPersonalizeEmailReminder) &&
                        <GlobalCustomFieldGrouped 
                            className={"send_module__quick__reply__global_custom__field"} 
                            labelText={"Personalized"} 
                            handleSelect={handleChangePersonalizedForEmailReminderBody}
                            forCalendarWidget={true}
                        />
                        }
                    </div>
                </div>

                <Editor
                    apiKey={apiKeys()}
                    // apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                    onInit={(evt, editor) => emailReminderBodyRef.current = editor}
                    initialValue={email_reminder_body}
                    cloudChannel='5-dev'
                    textareaName='message'
                    init={{
                        height: 500,
                        // menubar: false,
                        menu: {
                            favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                        },
                        menubar: 'favs file edit view insert format tools table help',
                        plugins: [
                            'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                            'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                            'insertdatetime media table contextmenu paste code help wordcount template'
                        ],
                        convert_urls: false,
                        toolbar:
                            'insertfile undo redo | styleselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | link image | \
                            bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                        imagetools_proxy: emailUploadFileApi,
                        images_upload_url: emailUploadFileApi,
                        // images_upload_base_path: baseUrl,
                        file_picker_types: 'file image media',
                        placeholder: "Compose your mail message",
                        forced_root_block: false
                    }}
                    onEditorChange={(content)=>setEmail_reminder_body(content)}
                />
            </div>
        );
    }

    let newTextTimingRowData = [];

    if(newTextReminderRow != []){
        newTextReminderRow.map((row, index) => {
            newTextTimingRowData.push(
                <EachTiming
                    timeValue={text_reminder_timings[index]}
                    unitValue={text_reminder_units[index]}
                    onTimeChange={onTimeChange}
                    onUnitChange={onUnitChange}
                    reminderChecked={textReminderChecked}
                    reminder_timings={text_reminder_timings[index]}
                    remover={removeTiming}
                    reminderType={'text'}
                    validator={timeSMSValidator.message("text_interval_"+index, text_reminder_timings[index], 'required|min:1,num')}
                    key={index}
                    RowIndex={index}
                    timeSelect={true}
                    onTitleChange={hanldeTitleChangeForReminder}
                    defaultTitle={templateData.text_reminder_text}
                    titleValue={textReminderTexts[(index-1)] !== undefined ? textReminderTexts[(index-1)] : ''}
                    firstStep={index === 0 ? (renderTextReminderFirstStep()) : (null)}
                />
            );
        });
    }

    let newEmailTimingRowData = [];
    if(newEmailReminderRow != []) {
        newEmailReminderRow.map((row, index) => {
            newEmailTimingRowData.push(
                <EachTiming 
                    timeValue={email_reminder_timings[index]}
                    unitValue={email_reminder_units[index]}
                    onTimeChange={onTimeChange}
                    onUnitChange={onUnitChange}
                    reminderChecked={emailReminderChecked}
                    reminder_timings={email_reminder_timings[index]}
                    remover={removeTiming}
                    reminderType={'email'}
                    validator={timeEmailValidator.message("email_interval_" + index, email_reminder_timings[index], 'required|min:1,num')}
                    key={index}
                    RowIndex={index}
                    timeSelect={true}
                    onTitleChange={hanldeTitleChangeForReminder}
                    defaultTitle={templateData.email_reminder_subject}
                    titleValue={emailReminderData && emailReminderData[(index-1)] ? emailReminderData[(index-1)].subject : ''}
                    messageValue={emailReminderData && emailReminderData[(index-1)] ? emailReminderData[(index-1)].message : ''}
                    defaultMessage={templateData.email_reminder_body}
                    onMessageChange={hanldeMessageChangeForReminder}
                    firstStep={index === 0 ? (renderEmailReminderFirstStep()) : (null)}
                />
            );
        });

    }

    let campaignIssues = [];

    campaignIssues = campaignList.map(function(data,index){
        return(
            <option key={index} value={data.id}>{data.title}</option>
        )
    });

    return (
        <>
            <div className="row  new_calendar_policy_list_update_v2">
                <div className="col s12 new_calendar_policy_list_update_v2_p0">

                    <div className="policy_list">
                        <div className="article">
                            <h6>Email Confirmations</h6>
                            <p>Your invitee will receive an email confirmation with links to create their own calendar
                                event.</p>
                        </div>
                        <div className="action">
                            <button onClick={(event)=>setShowPersonalizeInvitation(!showPersonalizeInvitation)} className="custom_btn custom-cursor-pointer">{showPersonalizeInvitation ? 'Close' : 'Personalize'}</button>
                        </div>
                    </div>

                    <div className={"policy_list " + (showPersonalizeInvitation ? "d-block" : "d-none")}>
                            <div className={"personalized-confirmation "}>

                                <div className="input_group">
                                    <div className="input_group_calender_widget_wr">
                                        <label className="input_group_calender_widget_wr_title">Title</label>
                                        <div className="input_group_calender_widget_container">

                                        <span onClick={()=>setConfirmation_title(templateData.confirmation_title)} className="input_group_calender_widget_btn custom-cursor-pointer" >Restore Default</span>
                                        {(props.openSection === 3 && showPersonalizeInvitation) &&
                                        <GlobalCustomFieldGrouped 
                                            className={"send_module__quick__reply__global_custom__field"} 
                                            labelText={"Personalized"} 
                                            handleSelect={handleChangePersonalizedForConfirmationTitle}
                                            forCalendarWidget={true}
                                        />
                                        }

                                        </div>
                                        
                                    </div>
                                    <input
                                        ref={confirmationTitleRef}
                                        value={confirmation_title}
                                        placeholder="Title"
                                        type="text"
                                        className="validate"
                                        onChange={(event)=>setConfirmation_title(event.target.value)}
                                    />
                                </div>
                                <div className="input_group_calender_widget_wr">
                                    <label className="input_group_calender_widget_wr_title">Body</label>
                                    <div className="input_group_calender_widget_container">
                                        {(props.openSection === 3 && showPersonalizeInvitation) &&
                                            <GlobalCustomFieldGrouped 
                                                className={"send_module__quick__reply__global_custom__field"} 
                                                labelText={"Personalized"} 
                                                handleSelect={handleChangePersonalizedForConfirmationBody}
                                                forCalendarWidget={true}
                                            />
                                        }
                                    </div>
                                </div>
                                
                                
                                

                                <Editor
                                    apiKey={apiKeys()}
                                    onInit={(evt, editor) => confirmationBodyRef.current = editor}
                                    initialValue={confirmation_body}
                                    cloudChannel='5-dev'
                                    textareaName='message'
                                    init={{
                                        height: 500,
                                        // menubar: false,
                                        menu: {
                                            favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                                        },
                                        menubar: 'favs file edit view insert format tools table help',
                                        plugins: [
                                            'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                            'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                            'insertdatetime media table contextmenu paste code help wordcount template'
                                        ],
                                        convert_urls: false,
                                        toolbar:
                                            'insertfile undo redo | styleselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | link image | \
                                            bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                        imagetools_proxy: emailUploadFileApi,
                                        images_upload_url: emailUploadFileApi,
                                        // images_upload_base_path: baseUrl,
                                        file_picker_types: 'file image media',
                                        placeholder: "Compose your mail message",
                                        forced_root_block: false
                                    }}
                                    onEditorChange={(content)=>setConfirmation_body(content)}
                                />
                            </div>
                    </div>

                    <div className="policy_list">
                        <div className="article">
                            <h6>Email Cancellations</h6>
                            <p>Email notifications will be sent to your invitee if you cancel the event.</p>
                        </div>
                        <div className="action">
                            <button onClick={(event)=>setShowPersonalizeCancellation(!showPersonalizeCancellation)} className="custom_btn custom-cursor-pointer">{showPersonalizeCancellation ? 'Close' : 'Personalize'}</button>
                        </div>
                    </div>

                    <div className={"policy_list " + (showPersonalizeCancellation ? "d-block" : "d-none")}>
                        <div className={"personalized-confirmation "}>

                            <div className="input_group">
                                <div className="input_group_calender_widget_wr">
                                <label className="input_group_calender_widget_wr_title">Title</label>
                                    <div className="input_group_calender_widget_container">
                                        
                                        <span onClick={()=>setCancellation_title(templateData.cancellation_title)} className="input_group_calender_widget_btn custom-cursor-pointer" >Restore Default</span>
                                        {(props.openSection === 3 && showPersonalizeCancellation) &&
                                        <GlobalCustomFieldGrouped 
                                            className={"send_module__quick__reply__global_custom__field"} 
                                            labelText={"Personalized"} 
                                            handleSelect={handleChangePersonalizedForCancelationTitle}
                                            forCalendarWidget={true}
                                        />
                                        }
                                    </div>
                                 </div>
                                <input
                                    ref={cancelationTitleRef}
                                    value={cancellation_title}
                                    placeholder="Title"
                                    type="text"
                                    className="validate"
                                    onChange={(event)=>setCancellation_title(event.target.value)}
                                />
                               
                            </div>
                            <div className="input_group_calender_widget_wr">
                                <label className="input_group_calender_widget_wr_title">Body</label>
                                <div className="input_group_calender_widget_container">
                                    {(props.openSection === 3 && showPersonalizeCancellation) &&
                                    <GlobalCustomFieldGrouped 
                                        className={"send_module__quick__reply__global_custom__field"} 
                                        labelText={"Personalized"} 
                                        handleSelect={handleChangePersonalizedForCancelationBody}
                                        forCalendarWidget={true}
                                    />
                                    }
                                </div>
                            </div>
                            <Editor
                                apiKey={apiKeys()}
                                // apiKey=//'bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                                onInit={(evt, editor) => cancelationBodyRef.current = editor}
                                initialValue={cancellation_body}
                                cloudChannel='5-dev'
                                textareaName='message'
                                init={{
                                    height: 500,
                                    // menubar: false,
                                    menu: {
                                        favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                                    },
                                    menubar: 'favs file edit view insert format tools table help',
                                    plugins: [
                                        'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                        'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                        'insertdatetime media table contextmenu paste code help wordcount template'
                                    ],
                                    convert_urls: false,
                                    toolbar:
                                        'insertfile undo redo | styleselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | link image | \
                                        bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                    imagetools_proxy: emailUploadFileApi,
                                    images_upload_url: emailUploadFileApi,
                                    // images_upload_base_path: baseUrl,
                                    file_picker_types: 'file image media',
                                    placeholder: "Compose your mail message",
                                    forced_root_block: false
                                }}
                                onEditorChange={(content)=>setCancellation_body(content)}
                            />
                        </div>
                    </div>

                    <div className="policy_list">
                        <div className="article">
                            <h6>Text Reminders</h6>
                            <p>Your invitees will have the option of receiving text reminders before a scheduled event
                                at specified times.</p>
                        </div>
                        <div className="action">
                            <label className="switch">
                                <small className="on">
                                    <svg width="60" height="30" viewBox="0 0 52 27" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="51" height="26" rx="13" fill="white"/>
                                        <path
                                            d="M38 3.50004C43.5228 3.50004 48 7.9772 48 13.5C48 19.0228 43.5228 23.5 38 23.5C32.4772 23.5 28 19.0228 28 13.5C27.9843 7.99286 32.436 3.51576 37.9431 3.50004C37.9621 3.49999 37.981 3.49999 38 3.50004Z"
                                            fill="#3C7EF3"/>
                                        <path
                                            d="M43.5405 10.7432L36.4382 17.8455L32.4609 13.8967L34.0803 12.3058L36.4382 14.6353L41.9212 9.15234L43.5405 10.7432Z"
                                            fill="white"/>
                                        <rect x="0.5" y="0.5" width="51" height="26" rx="13" stroke="#F2F2F2"/>
                                    </svg>
                                </small>
                                <input checked={textReminderChecked ? 'checked':''} type="checkbox" value={1} onClick={()=>setTextReminderChecked(!textReminderChecked)} />
                                    <span className="slider round"></span>
                                    <small className="off">
                                        <svg width="60" height="30" viewBox="0 0 62 32" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="61" height="31" rx="15.5" fill="white"/>
                                            <path
                                                d="M16 4.00005C22.6274 4.00005 28 9.37264 28 16C28 22.6274 22.6274 28 16 28C9.37264 28 4.00005 22.6274 4.00005 16C3.98119 9.39143 9.32318 4.01891 15.9317 4.00005C15.9545 3.99998 15.9773 3.99998 16 4.00005Z"
                                                fill="#FF264A"/>
                                            <path
                                                d="M19.6058 19.6066C19.28 19.9325 18.9883 20.1676 18.6624 19.8417L16.0703 17.2496L13.4782 19.8417C13.1529 20.167 12.8606 19.9325 12.5348 19.6066C12.2089 19.2807 11.9744 18.9885 12.2997 18.6632L14.8918 16.0711L12.2997 13.4789C11.9744 13.1537 12.2089 12.8614 12.5348 12.5355C12.8606 12.2097 13.1529 11.9752 13.4782 12.3004L16.0703 14.8926L18.6624 12.3004C18.9883 11.9746 19.28 12.2097 19.6058 12.5355C19.9317 12.8614 20.1668 13.1531 19.841 13.4789L17.2488 16.0711L19.841 18.6632C20.1668 18.9891 19.9317 19.2807 19.6058 19.6066Z"
                                                fill="white"/>
                                            <rect x="0.5" y="0.5" width="61" height="31" rx="15.5" stroke="#E0E0E0"/>
                                        </svg>
                                    </small>
                            </label>
                            <button onClick={(event)=>setShowPersonalizeTextReminder(!showPersonalizeTextReminder)} className={"custom_btn custom-cursor-pointer " + (textReminderChecked ? "d-block":"d-none")}>{showPersonalizeTextReminder ? 'Close' : 'Personalize'}</button>

                        </div>
                    </div>

                    <div className={"policy_list " + (showPersonalizeTextReminder ? "d-block" : "d-none")}>
                        <div className={"personalized-confirmation "}>

                            {/* old code here */}

                            <div className="input_group">
                                {/* <label>Timing</label><br/> */}
                                <div className="timing mbs timing_wedgets_wr_v2" id="text-reminder-timing">
                                    {newTextTimingRowData}
                                    <a href="" className={(text_reminder_timing_generator == true ? "timing_wed_add_another_reminder_btn d-inline-block" : "d-none")} onClick={(e) => addNewTiming(e, 'text_reminder')}>+ Add Another Reminder</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="policy_list">
                        <div className="article">
                            <h6>Email Reminders</h6>
                            <p>An invitee will receive a reminder email before a scheduled event at specified times</p>
                        </div>
                        <div className="action">
                            <label className="switch">
                                <small className="on">
                                    <svg width="60" height="30" viewBox="0 0 52 27" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="51" height="26" rx="13" fill="white"/>
                                        <path
                                            d="M38 3.50004C43.5228 3.50004 48 7.9772 48 13.5C48 19.0228 43.5228 23.5 38 23.5C32.4772 23.5 28 19.0228 28 13.5C27.9843 7.99286 32.436 3.51576 37.9431 3.50004C37.9621 3.49999 37.981 3.49999 38 3.50004Z"
                                            fill="#3C7EF3"/>
                                        <path
                                            d="M43.5405 10.7432L36.4382 17.8455L32.4609 13.8967L34.0803 12.3058L36.4382 14.6353L41.9212 9.15234L43.5405 10.7432Z"
                                            fill="white"/>
                                        <rect x="0.5" y="0.5" width="51" height="26" rx="13" stroke="#F2F2F2"/>
                                    </svg>
                                </small>
                                <input checked={emailReminderChecked ? 'checked':''} type="checkbox" value={1} onClick={()=>setEmailReminderChecked(!emailReminderChecked)} />
                                    <span className="slider round"></span>
                                    <small className="off">
                                        <svg width="60" height="30" viewBox="0 0 62 32" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="61" height="31" rx="15.5" fill="white"/>
                                            <path
                                                d="M16 4.00005C22.6274 4.00005 28 9.37264 28 16C28 22.6274 22.6274 28 16 28C9.37264 28 4.00005 22.6274 4.00005 16C3.98119 9.39143 9.32318 4.01891 15.9317 4.00005C15.9545 3.99998 15.9773 3.99998 16 4.00005Z"
                                                fill="#FF264A"/>
                                            <path
                                                d="M19.6058 19.6066C19.28 19.9325 18.9883 20.1676 18.6624 19.8417L16.0703 17.2496L13.4782 19.8417C13.1529 20.167 12.8606 19.9325 12.5348 19.6066C12.2089 19.2807 11.9744 18.9885 12.2997 18.6632L14.8918 16.0711L12.2997 13.4789C11.9744 13.1537 12.2089 12.8614 12.5348 12.5355C12.8606 12.2097 13.1529 11.9752 13.4782 12.3004L16.0703 14.8926L18.6624 12.3004C18.9883 11.9746 19.28 12.2097 19.6058 12.5355C19.9317 12.8614 20.1668 13.1531 19.841 13.4789L17.2488 16.0711L19.841 18.6632C20.1668 18.9891 19.9317 19.2807 19.6058 19.6066Z"
                                                fill="white"/>
                                            <rect x="0.5" y="0.5" width="61" height="31" rx="15.5" stroke="#E0E0E0"/>
                                        </svg>
                                    </small>
                            </label>
                            <button onClick={(event)=>setShowPersonalizeEmailReminder(!showPersonalizeEmailReminder)} className={"custom_btn custom-cursor-pointer " + (emailReminderChecked ? "d-block":"d-none")}>{showPersonalizeEmailReminder ? 'Close' : 'Personalize'}</button>
                        </div>
                    </div>

                    <div className={"policy_list " + (showPersonalizeEmailReminder ? "d-block" : "d-none")}>
                        
                        {/* old code here */}

                        <div className="input_group">
                            {/* <label>Timing</label><br/> */}
                            <div className="timing_wedgets_wr_v2 timing mbs" id="text-reminder-timing">
                                {newEmailTimingRowData}
                                <a href="" className={(email_reminder_timing_generator == true ? "d-inline-block timing_wed_add_another_reminder_btn" : "d-none")} onClick={(e) => addNewTiming(e, 'email_reminder')}>+ Add Another Reminder</a>
                            </div>
                        </div>

                    </div>

                    <div className="policy_list policy_list_for_extra_v2">
                        <div className="article">
                            <h6>Follow-Up Campaign</h6>
                            <p>Follow up your leads with a campaign.</p>
                            {/*<p>Request a review or prompt next steps with an automated email sent after the event is*/}
                            {/*    over.</p>*/}
                        </div>
                        <div className="action">
                            <div className="input_group">
                                <div className="option_select">
                                    <select value={campaign_id} onChange={(event => setCampaign_id(event.target.value))} className="input-field">
                                        <option value={null} >Select a Campaign</option>
                                        {campaignIssues}
                                    </select>
                                    <svg width="15" height="9" viewBox="0 0 15 9" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.206524 1.9185C0.206048 1.67511 0.290813 1.43924 0.446107 1.25183C0.533553 1.14636 0.640951 1.05917 0.762146 0.995258C0.883341 0.93135 1.01595 0.891978 1.15239 0.879399C1.28882 0.86682 1.4264 0.881281 1.55723 0.921952C1.68807 0.962623 1.8096 1.02871 1.91486 1.11642L7.49819 5.78308L13.0919 1.28308C13.1985 1.19656 13.3211 1.13194 13.4527 1.09295C13.5843 1.05396 13.7223 1.04136 13.8588 1.05588C13.9953 1.0704 14.1276 1.11175 14.248 1.17756C14.3685 1.24336 14.4747 1.33233 14.5607 1.43933C14.6555 1.54711 14.727 1.67332 14.7708 1.81006C14.8145 1.94679 14.8295 2.09108 14.8149 2.23389C14.8002 2.3767 14.7562 2.51493 14.6855 2.63991C14.6149 2.76489 14.5192 2.87392 14.4044 2.96017L8.15444 7.99142C7.96805 8.14463 7.73426 8.22838 7.49298 8.22838C7.25171 8.22838 7.01791 8.14463 6.83152 7.99142L0.581524 2.78308C0.455464 2.67859 0.355815 2.54584 0.29066 2.39562C0.225505 2.24541 0.196679 2.08194 0.206524 1.9185Z"
                                            fill="#133159" fill-opacity="0.65"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="collapsible_footer collapsible_footer_for_btn_group">
                    <button type="button" className="btn collapsible_footer_for_btn_group1 btn_danger d-inline-flex align-items-center btnSetHeight">
                            <span className="mr-2">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                            </span>
                        Cancel
                    </button>
                    <button onClick={()=>handleSubmit()} type="button" className="collapsible_footer_for_btn2_group btn btn_info d-inline-flex align-items-center btnSetHeight accent--bg--color ">
                         <span className="mr-2"><svg className="MuiSvgIcon-root" focusable="false"
                                                     viewBox="0 0 24 24" aria-hidden="true"><path
                             d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg></span>{btnTitle}</button>
                </div>
            </div>
        </>
    );
}


const mapStateToProps = state => {
    return {
        calendarDetails : state.calendarWidgetReducer.calendarWidgetDetails
    };
};

const CalendarWidgetConfirmationSection = connect(mapStateToProps, null)(App);

export default CalendarWidgetConfirmationSection;
