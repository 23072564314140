import React from 'react';
import TimePicker from 'react-times';
import If from "if-else-react";
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
/* for new time picker */
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import Utils from "../../../helpers/Utils";

let userTimeZone = Utils.getAccountData('userTimezoneRaw');

export default class PreviousInterval extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            arrNewIntervalSlotTimeHour1 : props.hour1 != undefined ? props.hour1 : 0,
            arrNewIntervalSlotTimeMin1 : props.min1 != undefined ? props.min1 : 0,
            arrNewIntervalSlotTimeHour2 : props.hour2 != undefined ? props.hour2 : 0,
            arrNewIntervalSlotTimeMin2 : props.min2 != undefined ? props.min2 : 0,
            newRowId : props.rowId != undefined ? props.rowId : 0,
            renderMyComponent : true,
            timePicker1: moment(new Date()),
            timePicker2: moment(new Date()),
        };

    }

    componentDidMount(){
        if(this.props.hour1 && this.props.min1){
            let date = this.props?.newRow?.start;

            if(date){
                date = moment(new Date());
            }
            let date_parse = date.tz(userTimeZone).format('YYYY-MM-DD');
            let time = this.props.hour1+":"+this.props.min1+":00";
            this.setState({timePicker1: new Date(date_parse+' '+time)})


        }
        if(this.props.hour2 && this.props.min2){
            let date = this.props?.newRow?.end;
            if(date){
                date = moment(new Date())
            }
            let date_parse = date.tz(userTimeZone).format('YYYY-MM-DD');
            let time = this.props.hour2+":"+this.props.min2+":00";
            this.setState({timePicker2: new Date(date_parse+' '+time)})
        }
    }

    onNewIntervalTimeChange1(options){
        this.setState({
            arrNewIntervalSlotTimeHour1 : (options.meridiem == 'AM') ? options.hour : parseInt(options.hour) + 12,
            arrNewIntervalSlotTimeMin1 : options.minute
        }, () => this.addPreviousIntervalStartTime())
    }

    onNewIntervalTimeChange1New(options){
        let hour = new Date(options).getHours();
        let minute = new Date(options).getMinutes();

        this.setState({
            arrNewIntervalSlotTimeHour1 : hour,
            arrNewIntervalSlotTimeMin1 : minute,
            timePicker1 : options,
        }, () => this.addPreviousIntervalStartTime())
    }

    addPreviousIntervalStartTime = () =>{
        this.props.addPreviousIntervalStartTime(
            this.state.arrNewIntervalSlotTimeHour1,
            this.state.arrNewIntervalSlotTimeMin1,
            this.state.arrNewIntervalSlotTimeHour2,
            this.state.arrNewIntervalSlotTimeMin2
        );
    }

    onNewIntervalTimeChange2(options){
        this.setState({
            arrNewIntervalSlotTimeHour2 : (options.meridiem == 'AM') ? options.hour : parseInt(options.hour) + 12,
            arrNewIntervalSlotTimeMin2 : options.minute
        }, () => this.addPreviousIntervalStartTime())
    }

    onNewIntervalTimeChange2New(options){
        let hour = new Date(options).getHours();
        let minute = new Date(options).getMinutes();

        this.setState({
            arrNewIntervalSlotTimeHour2 : hour,
            arrNewIntervalSlotTimeMin2 : minute,
            timePicker2 : options,
        }, () => this.addPreviousIntervalStartTime())
    }

    removeElement = (rowId) => {
        this.props.removePreInterval(rowId);
        this.setState({
            renderMyComponent : false
        })
    }

    render() {

        return (

            <React.Fragment>
                <If condition={this.state.renderMyComponent}>
                    <div className="row text-center calendar__widget__new__design_edit" >
                        <div className="col s4">
                            <div className="form-group m-form__group">
                                <div className="timepicker-wrapper">
                                    {/* <TimePicker
                                        onTimeChange={this.onNewIntervalTimeChange1.bind(this)}
                                        time={this.state.arrNewIntervalSlotTimeHour1+':'+this.state.arrNewIntervalSlotTimeMin1}
                                        timeMode="12"
                                    /> */}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardTimePicker
                                                format="hh:mm a"
                                                value={this.state.timePicker1}
                                                onChange={(date) => this.onNewIntervalTimeChange1New(date)}
                                                KeyboardButtonProps={{ 'aria-label': 'change time' }}
                                                className="modal_bordered_text_field radius-5 white"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col s1">
                            <div className="form-group m-form__group">
                                <label >
                                    -
                                </label>
                            </div>
                        </div>
                        <div className="col s4">
                    <div className="form-group m-form__group">
                        <div className="timepicker-wrapper">
                            {/* <TimePicker
                                onTimeChange={this.onNewIntervalTimeChange2.bind(this)}
                                time={this.state.arrNewIntervalSlotTimeHour2+':'+this.state.arrNewIntervalSlotTimeMin2}
                                timeMode="12"
                            /> */}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justifyContent="space-around">
                                    <KeyboardTimePicker
                                        format="hh:mm a"
                                        value={this.state.timePicker2}
                                        onChange={(date) => this.onNewIntervalTimeChange2New(date)}
                                        KeyboardButtonProps={{ 'aria-label': 'change time' }}
                                        className="modal_bordered_text_field radius-5 white"
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    </div>
                        <div className="col s3">
                            <div className="form-group m-form__group delete-picker" onClick={ () => this.removeElement(this.state.newRowId)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                            </div>
                        </div>
                    </div>
                </If>
            </React.Fragment>

        );
    }


}