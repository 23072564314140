import React, {useEffect, useRef, useState} from "react";
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import  './scheduler.css';
import DatePicker from "react-datepicker";
import TimeScheduleList from "./TimeScheduleList";
import SimpleReactValidator from "simple-react-validator";
import axis from 'axis.js';
import './intlTelInput.css';
import "moment-timezone";
import 'react-dropdown/style.css';
import If from "if-else-react";
import {connect} from "react-redux";
import Api from "../../../constants/Api";
import Utils from "./../../../helpers/Utils";
import ListSkeleton from "../../Common/Skeletons/ListSkeleton";
import {Helmet} from "react-helmet";
import Select from 'react-select';
import {TimeZoneOptions, timezoneListObj, getUserTimezone} from './TimeZoneList';
import AccessTimeIcon from '@material-ui/icons/AccessTime';


moment.tz.setDefault('UTC');

const ConfirmationPage = (props) =>{
    return (
        <div className="confirm-wrapper">
            <div className="header">
                <h2>Confirmed</h2>
                <span>You are scheduled with {props.calendarData.user.full_name}.</span>
            </div>
            <div className="confirm-body">
                <ul className="details">
                    <li> <span>👥</span><h2>{props.calendarData.title}</h2></li>
                    {props.userTimezone === props.timezone &&
                    <li> <span>&#128338;</span>
                        {moment(props.slot_start_time,['YYYY-MM-DD h:m']).format('h:mma') + " - "}

                        {moment(props.slot_end_time,['YYYY-MM-DD h:m']).format('h:mma') + " "}
                        {moment(props.dateRange[0].start,['YYYY-MM-DD h:m']).format('dddd, DD MMMM  YYYY')}
                    </li>
                    }
                    {props.userTimezone !== props.timezone &&
                    <li><span>&#128338;</span>
                        {convertTime(props.slot_start_time, props.userTimezone, props.timezone).format('h:mma') + " - "}

                        {convertTime(props.slot_end_time, props.userTimezone, props.timezone).format('h:mma') + " "}
                        {moment(props.dateRange[0].start,['YYYY-MM-DD h:m']).format('dddd, DD MMMM  YYYY')}
                    </li>
                    }
                    {props.calendarData.location !== '' &&
                        <li> <span>&#127986;</span>{props.calendarData.location}</li>
                    }

                </ul>
                <div className="details-content">
                    <strong>A calendar invitation has been sent to your email
                        address.
                    </strong>
                </div>
            </div>
            <If condition={props.calendarData.show_button_after_confirm}>
                <div className="confirm-footer">
                    <a onClick={props.reloadData} href="javascript:void(0)" className="custom-link">
                        {(props.calendarData.button_text) ? props.calendarData.button_text : " Schedule another event"}
                    </a>
                </div>
            </If>
        </div>
    );
}

var timezones = {
    EST : 'Eastern Standard Time',
    EDT : 'Eastern Daylight Time',
    CST : 'Central Standard Time',
    CDT : 'Central Daylight Time',
    MST : 'Mountain Standard Time',
    MDT : 'Mountain Daylight Time',
    PST : 'Pacific Standard Time',
    PDT : 'Pacific Daylight Time',
    AKDT : 'Alaska Daylight Time',
    AKST : 'Alaska Standard Time',
    HST : 'Hawaii Standard Time',
    'America/Chicago' : 'Central Standard Time',
    'America/Denver' : 'Mountain Standard Time',
    'America/Los_Angeles' : 'Pacific Daylight Time',
    'America/Anchorage' : 'Alaska Daylight Time',
    'America/Honolulu' : 'Hawaii Standard Time',
    'America/Phoenix' : 'Arizona Standard Time',
    'Asia/Dhaka' : 'Asia/Dhaka Time',
};



var visitorTimezones = {
    'EST' : 'Eastern Standard Time',
    'America/Chicago' : 'Central Standard Time',
    'America/Denver' : 'Mountain Standard Time',
    'America/Los_Angeles' : 'Pacific Daylight Time',
    'America/Anchorage' : 'Alaska Daylight Time',
    'America/Honolulu' : 'Hawaii Standard Time',
    'America/Phoenix' : 'Arizona Standard Time',
};

function convertTime(date, from, to) {
    let dateTime = moment(date,['YYYY-MM-DD h:m']);
    return dateTime.tz(from, true).tz(to);
}

const App = (props) => {
    const [, forceUpdate] = useState()
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));

    const getVisitorTimezone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const [state, setState] = useState({
        loading: true,
        isSelectedDate: false,
        selectedDate: null,
        showTimeSchedule: "timeslot-wrapper ",
        isError: false,
        calendarData: [],
        selectedMonth: [],
        showConfirmation: false,
        slot_start_time : '',
        slot_end_time : '',
        dateRange : [],
        previousDateRange : [],
        timeZone : getVisitorTimezone(),
        userTimezone : '',
        showForm : false,
        intlTelInputProps : {
            placeholder: 'Number',
            name: 'number',
            className: 'tsc-form-control m-input'
        },
        intlTelOpts : {
            preferredCountries: [],
            onlyCountries: ['us'],
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js",
            formatOnDisplay: false,
        },
        intlTelValue : { iso2: 'us', dialCode: '+1', phone: '' },
        isUsNumber : true,
        emailInvalidError : false,
        firstNameInvalidError : false,
        lastNameInvalidError : false,
        isProcessing : false,
        reserveSlots : [],
        increments : [],
        number : '',
        email : '',
        first_name : '',
        last_name : '',
        defaultOption : {},
    });
    const [newDefaultOption, setNewDefaultOption] = useState({})

    const [pageInfo, setPageInfo] = useState({
        logo: '',
        favIcon: '',
        agencyName: '',
    });
    const clockIcon=<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 0.875C4.51289 0.875 0.875 4.51289 0.875 9C0.875 13.4801 4.52031 17.125 9 17.125C13.4867 17.125 17.125 13.4867 17.125 9C17.125 4.51992 13.4801 0.875 9 0.875ZM13.2188 10.25H8.53125C8.48981 10.25 8.45007 10.2335 8.42076 10.2042C8.39146 10.1749 8.375 10.1352 8.375 10.0938V3.53125C8.375 3.48981 8.39146 3.45007 8.42076 3.42076C8.45007 3.39146 8.48981 3.375 8.53125 3.375H9.46875C9.51019 3.375 9.54993 3.39146 9.57924 3.42076C9.60854 3.45007 9.625 3.48981 9.625 3.53125V9H13.2188C13.2602 9 13.2999 9.01646 13.3292 9.04576C13.3585 9.07507 13.375 9.11481 13.375 9.15625V10.0938C13.375 10.1352 13.3585 10.1749 13.3292 10.2042C13.2999 10.2335 13.2602 10.25 13.2188 10.25Z"></path></svg>

    useEffect(() => {
        fetchCalendarData();
        var d = document.getElementById("CalendarModule-container");
        d.className += " background-calendar-preview";
    }, []);

    const fetchCalendarData = () => {
        let data = [];
        let formData = new FormData();
        formData.append('apiKey', props.match.params.uniqueId);

        fetch(Api.fetchCalendarDetailsForPublicPage,{
            method: 'POST',
            body: formData
        }).then(response => {
            response.json().then(result => {
                if (result.status == true) {
                    let selectedMonth = {};
                    data = result.data;
                    data.schedules.map((each) => {
                        if(each != null){
                            if(each.start != null){
                                let month = parseInt(moment(each.start,['YYYY-MM-DD h:m']).format('M'));
                                let  day = parseInt(moment(each.start,['YYYY-MM-DD h:m']).format('D'));

                                if(month in selectedMonth){
                                    if(axis.isArray(selectedMonth[month].days)
                                        && selectedMonth[month].days.indexOf(day) === -1){
                                        selectedMonth[month].days.push(day);
                                    } else {
                                        selectedMonth[month].days.push(day);
                                    }
                                } else{
                                    selectedMonth[month] = {
                                        days : [day]
                                    }
                                }
                            }

                        }
                    });

                    setState({
                        ...state,
                        isError: false,
                        timeZone: getVisitorTimezone(),
                        userTimezone: data.user.timezone,
                        calendarData: data,
                        selectedMonth : selectedMonth,
                        increments : data.availability_increments,
                        reserveSlots : processReserveSlot(data.reserved_slots),
                        loading: false,
                    }, processDefaultOption());

                    setPageInfo({...pageInfo, logo: result.pageInfo.logo, favIcon: result.pageInfo.favIcon, agencyName: result.pageInfo.agencyName});

                } else {
                    setState({...state, isError: true, loading: false});
                }
            });
        }).catch(err => {
            setState({...state, isError: true});
        });
    }

    const processReserveSlot = (reserved_slots) => {
        let reserveSlots = {};

        reserved_slots.map((each) => {
            if (moment(each.slot_start_time,['YYYY-MM-DD h:m']).format('YYYY-M-D') in reserveSlots){
                reserveSlots[moment(each.slot_start_time,['YYYY-MM-DD h:m']).format('YYYY-M-D')].push({start: moment(each.slot_start_time, ['YYYY-MM-DD h:m']).format('h:mma'), end: moment(each.slot_end_time, ['YYYY-MM-DD h:m']).format('h:mma')})
            } else {
                reserveSlots[moment(each.slot_start_time,['YYYY-MM-DD h:m']).format('YYYY-M-D')] = [];
                reserveSlots[moment(each.slot_start_time,['YYYY-MM-DD h:m']).format('YYYY-M-D')].push({start: moment(each.slot_start_time, ['YYYY-MM-DD h:m']).format('h:mma'), end: moment(each.slot_end_time, ['YYYY-MM-DD h:m']).format('h:mma')})
            }
        });

        return reserveSlots;

    }


    const reloadData = () => {

    }

    const onBackFire = () => {
        setState({
            ...state,
            showForm : false,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setState({
            ...state,
            emailInvalidError: false,
            firstNameInvalidError: false,
            lastNameInvalidError: false,
        });

        var hasError = false;

        document.getElementsByClassName('common-error')[0].innerHTML = '';

        if( validator.current.allValid() && !state.isProcessing)
        {

            if (state.email != '' && !(state.email.substr(0, state.email.indexOf('@')).match(/(.*[a-z]){1}/))) {
                hasError = true;
                setState({
                    ...state,
                    emailInvalidError: true
                });
            }

            if (state.first_name != '' && (!(state.first_name.match(/^(?!\.)[A-Za-z. ]+$/))) ) {
                hasError = true;
                setState({
                    ...state,
                    firstNameInvalidError: true
                });
            }

            if (state.last_name != '' && (!(state.last_name.match(/^(?!\.)[A-Za-z. ]+$/))) ) {
                hasError = true;
                setState({
                    ...state,
                    lastNameInvalidError: true
                });
            }

            if (hasError || !state.isUsNumber) {
                return false;
            }

            setState({
                ...state,
                loading : true,
                isProcessing : true
            });

            let formData = new FormData();
            formData.append('apiKey', props.match.params.uniqueId);
            formData.append('number', state.number);
            formData.append('email', state.email);
            formData.append('first_name', state.first_name);
            formData.append('last_name', state.last_name);
            formData.append('slot_start_time', state.slot_start_time);
            formData.append('slot_end_time', state.slot_end_time);
            formData.append('is_new', 1); //1= new contact
            formData.append('visitorTimezone', state.timeZone);

            fetch(Api.scheduleBooking,{
                method: 'POST',
                body: formData,
            }).then(response => {
                response.json().then(result => {
                    let data = result.data;
                    if( data.status === 'validation-error' ){
                        if(typeof data.html === 'string') {
                            document.getElementsByClassName('number-error')[0].innerHTML = data.html;
                        } else{
                            document.getElementsByClassName('number-error')[0].innerHTML ='';
                        }
                        if(data.html.number === undefined){
                            document.getElementsByClassName('number-error')[0].innerHTML = '';
                        }else{
                            document.getElementsByClassName('number-error')[0].innerHTML = data.html.number[0];
                        }
                        if(data.html.email=== undefined){
                            document.getElementsByClassName('email-error')[0].innerHTML = '';
                        }else{
                            document.getElementsByClassName('email-error')[0].innerHTML = data.html.email[0];
                        }
                        setState({
                            ...state,
                            isProcessing : false,
                        });
                    }else if(data.status === 'error'){
                        document.getElementsByClassName('common-error')[0].innerHTML = data.html;
                        setState({
                            ...state,
                            isProcessing : false,
                        });
                    }
                    else if (data.status === "success") {
                        validator.current.hideMessages();

                        if(!axis.isUndefined(state.calendarData.confirmation_link) && state.calendarData.confirmation_link){
                            window.location = state.calendarData.confirmation_link;
                        }

                        setState({
                            ...state,
                            isProcessing : false,
                            showConfirmation : true
                        });

                    }
                });
            });

        } else {
            validator.current.showMessages();
        }

    }

    const intlTelOnChange = (value) => {
        setState({
            ...state,
            intlTelValue: value,
            number: value.phone
        });
    }

    useEffect(()=>{
        if (state.intlTelValue.phone != '') {
            var regex = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
            var result = regex.test(state.intlTelValue.phone);
            if (result) {
                setState({
                    ...state,
                    isUsNumber: true
                });
            } else {
                setState({
                    ...state,
                    isUsNumber: false
                });
            }
        } else {
            setState({
                ...state,
                isUsNumber: true
            });
        }

    }, [state.intlTelValue]);

    const intlTelOnReady = () => {

    }

    const onChangeHandle = (e) => {
        const { name, value } = e.target;
        if (([name] == 'email')) {
            setState({
                ...state,
                [name] : value.trim()
            });
        } else {
            setState({
                ...state,
                [name] : value
            });
        }
    }

    const filterDate = date => {
        let day = moment(date,['YYYY-MM-DD h:m']).format('D');
        let month = moment(date,['YYYY-MM-DD h:m']).format("M");
        let today = moment().format('YYYY-MM-DD');
        if(month in state.selectedMonth) {
            if(state.selectedMonth[month].days.indexOf(parseInt(day)) !== -1){
                if(moment(moment(date).format('YYYY-MM-DD')).isSameOrAfter(today)) {
                    return true;
                }
            }
        }
    }

    const onDatePicked = (res) =>{
        const D = moment(res,['YYYY-MM-DD h:m']).format("D");
        const M = moment(res,['YYYY-MM-DD h:m']).format("M");
        const Y = moment(res,['YYYY-MM-DD h:m']).format("Y");

        const previousDate = moment(res).subtract(1, 'days');
        const preD = moment(previousDate,['YYYY-MM-DD h:m']).format("D");
        const preM = moment(previousDate,['YYYY-MM-DD h:m']).format("M");
        const preY = moment(previousDate,['YYYY-MM-DD h:m']).format("Y");

        let Selected = state.calendarData.schedules.filter((each)=>{
            if(each != null) {
                if(each.start != null) {
                    if (moment(each.start, ['YYYY-MM-DD h:m']).format('D') === D && moment(each.start, ['YYYY-MM-DD h:m']).format('M') === M && moment(each.start, ['YYYY-MM-DD h:m']).format('Y') === Y) {
                        return each;
                    }
                }
            }
        });

        let preSelected = state.calendarData.schedules.filter((each)=>{
            if(moment(each.start,['YYYY-MM-DD h:m']).format('D') === preD && moment(each.start,['YYYY-MM-DD h:m']).format('M') === preM && moment(each.start,['YYYY-MM-DD h:m']).format('Y') === preY){
                return each;
            }
        });


        setState(
            {
                ...state,
                isSelectedDate:true,
                selectedDate : res,
                dateRange : Selected,
                previousDateRange : preSelected,
                showTimeSchedule : "timeslot-wrapper has-selected-day"
            })
    };

    const changeTimezone = (timezone) => {
        setState({...state, timeZone: timezone.value});
    }

    useEffect(()=>{
        setNewDefaultOption({ value: state.timeZone, label: getUserTimezone(state.timeZone) +' Time - '+moment.tz(state.timeZone).format('hh:mm A')})
    }, [state.timeZone])

    useEffect(()=>{
        if(getUserTimezone(state.timeZone) == undefined){
            setState({...state, timeZone: state.userTimezone});
        }
    }, [state.userTimezone])

    const getTimezone = (abbr) => {
        return timezones[abbr];
    }

    const onConfirm = (date,time) => {
        let slot_start_time = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD')+' '+moment(time,['hh:m a', 'H:m']).format('HH:mm')
        let slot_end_time = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD')+' '+moment(time,['hh:m a', 'H:m']).add(state.calendarData.duration,'m').format('HH:mm')
        setState({
            ...state,
            showForm: true,
            slot_start_time: slot_start_time,
            slot_end_time: slot_end_time,
        })
    };

    const renderTimezoneList = () => {
        return [
            { value: 'Pacific/Honolulu', label: getTimezone(moment.tz('Pacific/Honolulu').format('z')) +' - '+moment.tz('Pacific/Honolulu').format('hh:mm A')},
            { value: 'America/Anchorage', label: getTimezone(moment.tz('America/Anchorage').format('z')) +' - '+moment.tz('America/Anchorage').format('hh:mm A')},
            { value: 'America/Los_Angeles', label: getTimezone(moment.tz('America/Los_Angeles').format('z')) +' - '+moment.tz('America/Los_Angeles').format('hh:mm A')},
            { value: 'America/Denver', label: getTimezone(moment.tz('America/Denver').format('z')) +' - '+moment.tz('America/Denver').format('hh:mm A')},
            { value: 'America/Chicago', label: getTimezone(moment.tz('America/Chicago').format('z')) +' - '+moment.tz('America/Chicago').format('hh:mm A')},
            { value: 'America/New_York', label: getTimezone(moment.tz('America/New_York').format('z')) +' - '+moment.tz('America/New_York').format('hh:mm A')},
            { value: 'America/Phoenix', label: getTimezone(moment.tz('America/Phoenix').format('z')) +' - '+moment.tz('America/Phoenix').format('hh:mm A')},
            { value: 'Asia/Dhaka', label: getUserTimezone('Asia/Dhaka') +' - '+moment.tz('Asia/Dhaka').format('hh:mm A')},
        ];
    }

    const processDefaultOption = () => {
        setState({
            ...state,
            defaultOption: { value: state.timeZone, label: getUserTimezone(state.timeZone) +' Time - '+moment.tz(state.timeZone).format('hh:mm A')}
        })

        setNewDefaultOption({ value: state.timeZone, label: getUserTimezone(state.timeZone) +' Time - '+moment.tz(state.timeZone).format('hh:mm A')})
    }



    let divStyle = {
        color: "#ffffff",
        backgroundColor: "#20a8d8"
    };


    return (
      <React.Fragment>
        {!state.loading && (
          <Helmet>
            <link rel="icon" href={pageInfo.favIcon} />
            <title>{`${state.calendarData.title} | ${pageInfo.agencyName}`}</title>
            <meta
              name="description"
              content="All-In-One Sales & Marketing Platform"
            />
            <meta
              name="keywords"
              content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`}
            />
            <meta name="author" content={pageInfo.agencyName} />
          </Helmet>
        )}
        <div className="timeslot-calender-main timeslot_calender_main_wrapper_v2">
          <div className={`${state.showTimeSchedule} timeslot_leftside_calendar`}>
            <If condition={state.isError}>
              <div className="invalid-calender">
                <h1>Oops! Calendar url is not valid </h1>
              </div>
            </If>

            <If condition={!state.isError}>
              <If condition={!state.calendarData}>
                <div className="text-center w-100">{"Processing..."}</div>
              </If>
              <If condition={state.calendarData}>
                <If condition={state.showConfirmation}>
                  <ConfirmationPage
                    slot_start_time={state.slot_start_time}
                    slot_end_time={state.slot_end_time}
                    dateRange={state.dateRange}
                    reloadData={() => reloadData()}
                    calendarData={state.calendarData}
                    timezone={state.timeZone}
                    userTimezone={state.userTimezone}
                  />
                </If>
                <If condition={!state.showConfirmation}>
                  <div className="timeslot-inner ">
                    <div
                      className={`timeslot-row timeslot__wr__v2 ${
                        state.isSelectedDate
                          ? "select_date_state__row3"
                          : "select_date_state__row2"
                      } `}
                    >
                      <div className="timeslot-col-4 timeslot__wr__v2__left">
                        {state.loading ? (
                          <ListSkeleton number={4} height={50} width={200} />
                        ) : (
                          <div className="timeslot-summary timeslot-border outer-border-slot">
                            <div className="mx-auto">
                              <div className="row calender-leftside">
                                <div className="col-md-12 text-center my-2">
                                  <a
                                    href="#"
                                    className="d-flex justify-content-center"
                                  >
                                    {state.loading ? (
                                      <ListSkeleton
                                        number={1}
                                        width={220}
                                        height={120}
                                      />
                                    ) : (
                                      <img
                                        className="m-2"
                                        src={pageInfo.logo}
                                        style={{ maxWidth: "220px" }}
                                        alt="Logo"
                                      />
                                    )}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="timeslot-profile-info">
                              <p className="calander-title-leftside" >{state.calendarData.title}</p>
                            </div>
                            <div className="timeslot-details has-shrinkable-items is-mobile-hidden">
                                
                              <div className="timeslot-details-item ">
                                <span><AccessTimeIcon/></span> {state.calendarData.duration}{" "}
                                min
                              </div>
                            </div>
                            <div   className="mbm  calendar-widget-description">
                                <p style={{maxWidth:'400px'}}>{state.calendarData.description}</p>
                            </div>
                          </div>
                        )}
                      </div>

                      <If condition={state.showForm}>
                        <div className="timeslot-col-8 timeslot__wr__v2__right">
                          <form
                            onSubmit={(event) => handleSubmit(event)}
                            className="timeslot-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed"
                          >
                            <div className="timeslot-body">
                              <div className="tsc-form-group1  tsc-row">
                                <div className="tsc-col">
                                  <label>Number: </label>
                                  <div className="input-group m-input-group m-input-group--square">
                                    <input
                                      onChange={(event) =>
                                        onChangeHandle(event)
                                      }
                                      type="text"
                                      className="confirm_form_templete_input tsc-form-control m-input"
                                      placeholder="US Number"
                                      name="number"
                                    />
                                  </div>
                                  <span className="m-form__help tsc-error-message number-error">
                                    {validator.current.message(
                                      "number",
                                      state.number,
                                      state.email ? "" : "required|" + "number"
                                    )}
                                  </span>
                                  <If condition={!state.isUsNumber}>
                                    <span className="m-form__help text-danger error-message">
                                      The number must be a valid us number.
                                    </span>
                                  </If>
                                </div>
                              </div>
                              <div className="tsc-form-group1  tsc-row">
                                <div className="tsc-col">
                                  <label>Email:</label>
                                  <div className="input-group m-input-group m-input-group--square">
                                    <input
                                      onChange={(event) =>
                                        onChangeHandle(event)
                                      }
                                      type="text"
                                      className="confirm_form_templete_input tsc-form-control m-input"
                                      placeholder="Email"
                                      name="email"
                                    />
                                  </div>
                                  <span className="m-form__help tsc-error-message email-error">
                                    {validator.current.message(
                                      "email",
                                      state.email,
                                      state.number ? "" : "required|" + "email"
                                    )}
                                  </span>
                                  <If condition={state.emailInvalidError}>
                                    <span className="m-form__help text-danger error-message">
                                      The email must be a valid email address.
                                    </span>
                                  </If>
                                </div>
                              </div>

                              <div className="  tsc-row pb-30">
                                <div className="tsc-form-group1 tsc-col">
                                  <label>First name:</label>
                                  <div className="input-group m-input-group m-input-group--square">
                                    <input
                                      onChange={(event) =>
                                        onChangeHandle(event)
                                      }
                                      type="text"
                                      className="confirm_form_templete_input tsc-form-control m-input"
                                      placeholder="Enter First Name"
                                      name="first_name"
                                    />
                                  </div>
                                  <span className="m-form__help tsc-error-message error-message">
                                    {validator.current.message(
                                      "first_name",
                                      state.first_name,
                                      "max:255"
                                    )}
                                  </span>
                                  <If condition={state.firstNameInvalidError}>
                                    <span className="m-form__help tsc-error-message">
                                      Invalid Name
                                    </span>
                                  </If>
                                </div>

                                <div className="tsc-form-group1 tsc-col mb-sm-30">
                                  <label>Last Name:</label>
                                  <div className="input-group ">
                                    <input
                                      onChange={(event) =>
                                        onChangeHandle(event)
                                      }
                                      type="text"
                                      className="tsc-form-control m-input confirm_form_templete_input"
                                      placeholder="Enter Last Name"
                                      name="last_name"
                                    />
                                  </div>
                                  <span className="m-form__help tsc-error-message error-message">
                                    {validator.current.message(
                                      "last_name",
                                      state.last_name,
                                      "max:255"
                                    )}
                                  </span>
                                  <If condition={state.lastNameInvalidError}>
                                    <span className="m-form__help tsc-error-message">
                                      Invalid Name
                                    </span>
                                  </If>
                                </div>
                              </div>
                              <div
                                className="tsc-footer"
                                style={{ height: "0px" }}
                              >
                                <div
                                  className="tsc-footer-btn"
                                  style={{ height: "0px" }}
                                >
                                  <div
                                    className="tsc-row"
                                    style={{ height: "0px" }}
                                  >
                                    <span className="m-form__help tsc-error-message common-error"></span>
                                    <div
                                      className="tsc-col-6 "
                                      style={{ height: "0px" }}
                                    >
                                      <button
                                        type="submit"
                                        style={divStyle}
                                        className="btn btn-primary"
                                      >
                                        {!state.isProcessing
                                          ? " Reserve a slot"
                                          : " Processing.."}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </If>

                      <If condition={!state.showForm}>
                        <div className="timeslot-col-5 timeslot__wr__v2__right">
                          {state.loading ? (
                            <div>
                              <ListSkeleton
                                number={8}
                                height={50}
                                width={500}
                              />
                            </div>
                          ) : (
                            <DatePicker
                              inline
                              filterDate={filterDate}
                              selected={state.selectedDate}
                              onChange={(res) => onDatePicked(res)}
                              dateFormat={"YYYY-MM-DD h:m"}
                            />
                          )}
                          <div
                            className="form-group m-form__group"
                            style={{ width: "100%", marginTop: "30px" }}
                          >
                            {state.loading ? (
                              <ListSkeleton
                                number={1}
                                height={50}
                                width={500}
                              />
                            ) : (
                              <>
                                <Select
                                  options={TimeZoneOptions}
                                  // value={state.defaultOption}
                                  value={newDefaultOption}
                                  onChange={(timezone) =>
                                    changeTimezone(timezone)
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          id={"timeslot-show"}
                          className={
                            state.isSelectedDate
                              ? "timeslot-col-3 show "
                              : "timeslot-col-3 hide"
                          }
                        >
                          <div className="timeslot-box time_solt_title_v2_title_wr">
                            <div className="timeslot-title">
                              <h4 className={"mt-0"}>
                                {moment(state.selectedDate).format(
                                  "MMMM DD, YYYY"
                                )}
                              </h4>
                            </div>
                            <div
                              id={"schedule-temp-height"}
                              className="timeslot-items timeslot_items_v2_v4"
                            >
                              <PerfectScrollbar>
                                <TimeScheduleList
                                  reserveSlots={state.reserveSlots}
                                  submitConfirm={(date, time) =>
                                    onConfirm(date, time)
                                  }
                                  duration={
                                    state.calendarData.availability_increments
                                  }
                                  increments={state.increments}
                                  dateRange={state.dateRange}
                                  previousDateRange={state.previousDateRange}
                                  timeZone={state.timeZone}
                                  userTimezone={state.userTimezone}
                                  selectedDate={state.selectedDate}
                                />
                              </PerfectScrollbar>
                            </div>
                          </div>
                        </div>
                      </If>
                    </div>
                  </div>
                </If>
              </If>
            </If>
          </div>
        </div>
      </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
}


const PreviewScheduleCalendar = connect(mapStateToProps, mapDispatchToProps)(App);

export default PreviewScheduleCalendar;
