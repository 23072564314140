import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as CALENDAR_API from '../api/calendarApi';
import * as ACTION from '../constants/calendarActionTypes'
import Utils from "../helpers/Utils";
import {FETCH_ACTIVITY_CALENDAR, FETCH_TASK_COUNT, TASK_COUNT} from "../constants/calendarActionTypes";
import {fetchActivityList, fetchTaskCount, getContacts, saveActivityFollowup} from "../api/calendarApi";

function* calendarWatcher() {
    yield takeEvery(ACTION.FETCH_ACTIVITY_CALENDAR, fetchCalendarMiddleware);
    yield takeEvery(ACTION.FETCH_ACTIVITY_LIST, fetchActivityListMiddleware);
    yield takeEvery(ACTION.FETCH_TASK_COUNT, fetchTaskCountMiddleware);
    yield takeEvery(ACTION.SAVE_ACTIVITY_FOLLOWUP, saveActivityFollowUpFlow);
}

function* fetchCalendarMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchActivityCalendar, action.payload);
        let responseData = response.data.data;
        let responseFullData = response.data.full_data.last_page ?? ""
        yield put({type: ACTION.ACTIVITY_CALENDAR, payload: responseData});
        yield put({type: ACTION.UPDATE_CALENDAR_TOTAL_PAGE, payload: responseFullData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchActivityListMiddleware(action) {
    try {
        
        const response = yield call(CALENDAR_API.fetchActivityList, action.payload);
        let responseData = response.data.data;
        
        yield put({type: ACTION.ACTIVITY_LIST, payload: responseData});
        
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchTaskCountMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchTaskCount, action.payload);
        let responseData = response.data.data;
        yield put({type: ACTION.TASK_COUNT, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* saveActivityFollowUpFlow(action) {
    const { payload } = action;
    try {
        const response = yield call(CALENDAR_API.saveActivityFollowup, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': false, 'message' : 'Something went wrong! Try again.'});
        Utils.handleException(error);
    }
}

export default function* calendarMiddleware() {
    yield all([
        calendarWatcher(),
    ])
}
