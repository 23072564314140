import moment from "moment";

export const getUserTimezone = (abbr) => {
    return timezoneListObj[abbr];
}

export const timezoneListObj = {
    EST : 'Eastern Standard',
    EDT : 'Eastern Daylight',
    CST : 'Central Standard',
    CDT : 'Central Daylight',
    MST : 'Mountain Standard',
    MDT : 'Mountain Daylight',
    PST : 'Pacific Standard',
    PDT : 'Pacific Daylight',
    AKDT : 'Alaska Daylight',
    AKST : 'Alaska Standard',
    HST : 'Hawaii Standard',
    AST : 'Atlantic Standard',
    UTC : 'UTC',
    CET : 'Central European',
    EET : 'Eastern European',
    'America/Chicago' : 'Central Standard',
    'America/Denver' : 'Mountain Standard',
    'America/Los_Angeles' : 'Pacific Daylight',
    'America/New_York' : 'Eastern',
    'America/Anchorage' : 'Alaska Daylight',
    'America/Honolulu' : 'Hawaii Standard',
    'America/Phoenix' : 'Arizona Standard',
    'Asia/Dhaka' : 'Asia/Dhaka',
    'Pacific/Honolulu' : 'Hawaii',
    'Canada/Newfoundland' : 'Newfoundland',
    'America/Adak' : 'America/Adak',
    'America/Argentina/Buenos_Aires' : 'Buenos Aires',
    'America/Asuncion' : 'Asuncion',
    'America/Bogota' : 'Bogota, Jamaica, Lima',
    'America/Campo_Grande' : 'America/Campo Grande',
    'America/Caracas' : 'Caracas',
    'America/Godthab' : 'America/Godthab',
    'America/Halifax' : 'Atlantic',
    'America/Guatemala' : 'Saskatchewan, Guatemala, Costa Rica',
    'America/Havana' : 'America/Havana',
    'America/Mazatlan' : 'America/Mazatlan',
    'America/Mexico_City' : 'Mexico City',
    'America/Montevideo' : 'Montevideo City',
    'America/Miquelon' : 'America/Miquelon',
    'America/Noronha' : 'America/Noronha',
    'America/Santiago' : 'Santiago',
    'America/Santa_Isabel' : 'America/Santa Isabel',
    'Atlantic/Stanley' : 'Atlantic standard',
    'America/Fortaleza' : 'Brasilia',
    'Africa/Cairo' : 'Africa/Cairo',
    'Africa/Blantyre' : 'Central Africa',
    'Africa/Bangui' : 'West Africa',
    'Africa/Windhoek' : 'Africa/Windhoek',
    'Asia/Amman' : 'Jordan',
    'Asia/Baghdad' : 'Baghdad, East Africa',
    'Asia/Baku' : 'Asia/Baku',
    'Asia/Beirut' : 'Lebanon',
    'Asia/Damascus' : 'Syria',
    'Asia/Dubai' : 'Dubai',
    'Asia/Gaza' : 'Asia/Gaza',
    'Asia/Irkutsk' : 'Asia/Irkutsk',
    'Asia/Bangkok' : 'Indochina',
    'Asia/Jerusalem' : 'Israel',
    'Asia/Kabul' : 'Kabul',
    'Pacific/Majuro' : 'Pacific/Majuro',
    'Asia/Karachi' : 'Pakistan',
    'Indian/Maldives' : 'Maldives',
    'Asia/Kathmandu' : 'Asia/Kathmandu',
    'Asia/Calcutta' : 'India',
    'Asia/Colombo' : 'Sri Lanka',
    'Asia/Krasnoyarsk' : 'Krasnoyarsk',
    'Asia/Omsk' : 'Asia/Omsk',
    'Asia/Yangon' : 'Asia/Yangon',
    'Asia/Shanghai' : 'China',
    'Asia/Singapore' : 'Singapore',
    'Australia/Perth' : 'Perth',
    'Asia/Tehran' : 'Tehran',
    'Asia/Tokyo' : 'Japan',
    'Asia/Pyongyang' : 'Korea',
    'Asia/Vladivostok' : 'Asia/Vladivostok',
    'Asia/Yakutsk' : 'Asia/Yakutsk',
    'Asia/Yekaterinburg' : 'Asia/Yekaterinburg',
    'Asia/Yerevan' : 'Asia/Yerevan',
    'Atlantic/Azores' : 'Azores',
    'Atlantic/Cape_Verde' : 'Cape Verde',
    'Australia/Adelaide' : 'Adelaide',
    'Australia/Brisbane' : 'Brisbane',
    'Australia/Darwin' : 'Darwin',
    'Australia/Eucla' : 'Eucla',
    'Australia/Lord_Howe' : 'Lord Howe',
    'Australia/Sydney' : 'Sydney',
    'Australia/Melbourne' : 'Melbourne',
    'Europe/Kyiv' : 'Ukraine',
    'Europe/Dublin' : 'Dublin',
    'Europe/Lisbon' : 'Lisbon',
    'Europe/Minsk' : 'Minsk',
    'Europe/Moscow' : 'Moscow',
    'Europe/Istanbul' : 'Istanbul',
    'Pacific/Apia' : 'Pacific/Apia',
    'Pacific/Auckland' : 'Pacific/Auckland',
    'Pacific/Chatham' : 'Pacific/Chatham',
    'Pacific/Easter' : 'Pacific/Easter',
    'Pacific/Fiji' : 'Pacific/Fiji',
    'Pacific/Gambier' : 'Pacific/Gambier',
    'Pacific/Kiritimati' : 'Pacific/Kiritimati',
    'Pacific/Marquesas' : 'Pacific/Marquesas',
    'Pacific/Norfolk' : 'Pacific/Norfolk',
    'Pacific/Noumea' : 'Pacific/Noumea',
    'Pacific/Pago_Pago' : 'Pacific/Pago Pago',
    'Pacific/Pitcairn' : 'Pacific/Pitcairn',
    'Pacific/Tarawa' : 'Pacific/Tarawa',
    'Pacific/Tongatapu' : 'Pacific/Tongatapu',
};

const usCanadaOptions = [
    { value: 'America/Los_Angeles', label: getUserTimezone(moment.tz('America/Los_Angeles').format('z')) +' Time - '+moment.tz('America/Los_Angeles').format('hh:mm A')},
    { value: 'America/Denver', label: getUserTimezone(moment.tz('America/Denver').format('z')) +' Time - '+moment.tz('America/Denver').format('hh:mm A')},
    { value: 'America/Chicago', label: getUserTimezone(moment.tz('America/Chicago').format('z')) +' Time - '+moment.tz('America/Chicago').format('hh:mm A')},
    { value: 'America/New_York', label: getUserTimezone(moment.tz('America/New_York').format('z')) +' Time - '+moment.tz('America/New_York').format('hh:mm A')},
    { value: 'America/Anchorage', label: getUserTimezone(moment.tz('America/Anchorage').format('z')) +' Time - '+moment.tz('America/Anchorage').format('hh:mm A')},
    { value: 'America/Phoenix', label: getUserTimezone('America/Phoenix') +' Time - '+moment.tz('America/Phoenix').format('hh:mm A')},
    { value: 'Canada/Newfoundland', label: getUserTimezone('Canada/Newfoundland') +' Time - '+moment.tz('Canada/Newfoundland').format('hh:mm A')},
    { value: 'Pacific/Honolulu', label: getUserTimezone('Pacific/Honolulu') +' Time - '+moment.tz('Pacific/Honolulu').format('hh:mm A')},

];

const americaOptions = [
    { value: 'America/Adak', label: getUserTimezone('America/Adak') +' Time - '+moment.tz('America/Adak').format('hh:mm A')},
    { value: 'America/Argentina/Buenos_Aires', label: getUserTimezone('America/Argentina/Buenos_Aires') +' Time - '+moment.tz('America/Argentina/Buenos_Aires').format('hh:mm A')},
    { value: 'America/Asuncion', label: getUserTimezone('America/Asuncion') +' Time - '+moment.tz('America/Asuncion').format('hh:mm A')},
    { value: 'America/Bogota', label: getUserTimezone('America/Bogota') +' Time - '+moment.tz('America/Bogota').format('hh:mm A')},
    { value: 'America/Campo_Grande', label: getUserTimezone('America/Campo_Grande') +' Time - '+moment.tz('America/Campo_Grande').format('hh:mm A')},
    { value: 'America/Caracas', label: getUserTimezone('America/Caracas') +' Time - '+moment.tz('America/Caracas').format('hh:mm A')},
    { value: 'America/Godthab', label: getUserTimezone('America/Godthab') +' Time - '+moment.tz('America/Godthab').format('hh:mm A')},
    { value: 'America/Halifax', label: getUserTimezone('America/Halifax') +' Time - '+moment.tz('America/Halifax').format('hh:mm A')},
    { value: 'America/Guatemala', label: getUserTimezone('America/Guatemala') +' Time - '+moment.tz('America/Guatemala').format('hh:mm A')},
    { value: 'America/Havana', label: getUserTimezone('America/Havana') +' Time - '+moment.tz('America/Havana').format('hh:mm A')},
    { value: 'America/Mazatlan', label: getUserTimezone('America/Mazatlan') +' Time - '+moment.tz('America/Mazatlan').format('hh:mm A')},
    { value: 'America/Mexico_City', label: getUserTimezone('America/Mexico_City') +' Time - '+moment.tz('America/Mexico_City').format('hh:mm A')},
    { value: 'America/Montevideo', label: getUserTimezone('America/Montevideo') +' Time - '+moment.tz('America/Montevideo').format('hh:mm A')},
    { value: 'America/Miquelon', label: getUserTimezone('America/Miquelon') +' Time - '+moment.tz('America/Miquelon').format('hh:mm A')},
    { value: 'America/Noronha', label: getUserTimezone('America/Noronha') +' Time - '+moment.tz('America/Noronha').format('hh:mm A')},
    { value: 'America/Santiago', label: getUserTimezone('America/Santiago') +' Time - '+moment.tz('America/Santiago').format('hh:mm A')},
    { value: 'America/Santa_Isabel', label: getUserTimezone('America/Santa_Isabel') +' Time - '+moment.tz('America/Santa_Isabel').format('hh:mm A')},
    { value: 'Atlantic/Stanley', label: getUserTimezone('Atlantic/Stanley') +' Time - '+moment.tz('Atlantic/Stanley').format('hh:mm A')},
    { value: 'America/Fortaleza', label: getUserTimezone('America/Fortaleza') +' Time - '+moment.tz('America/Fortaleza').format('hh:mm A')},
];

const africaOptions = [
    { value: 'Africa/Cairo', label: getUserTimezone('Africa/Cairo') +' Time - '+moment.tz('Africa/Cairo').format('hh:mm A')},
    { value: 'Africa/Blantyre', label: getUserTimezone('Africa/Blantyre') +' Time - '+moment.tz('Africa/Blantyre').format('hh:mm A')},
    { value: 'Africa/Bangui', label: getUserTimezone('Africa/Bangui') +' Time - '+moment.tz('Africa/Bangui').format('hh:mm A')},
    { value: 'Africa/Windhoek', label: getUserTimezone('Africa/Windhoek') +' Time - '+moment.tz('Africa/Windhoek').format('hh:mm A')},
];

const asiaOptions = [
    { value: 'Asia/Amman', label: getUserTimezone('Asia/Amman') +' Time - '+moment.tz('Asia/Amman').format('hh:mm A')},
    { value: 'Asia/Baghdad', label: getUserTimezone('Asia/Baghdad') +' Time - '+moment.tz('Asia/Baghdad').format('hh:mm A')},
    { value: 'Asia/Baku', label: getUserTimezone('Asia/Baku') +' Time - '+moment.tz('Asia/Baku').format('hh:mm A')},
    { value: 'Asia/Beirut', label: getUserTimezone('Asia/Beirut') +' Time - '+moment.tz('Asia/Beirut').format('hh:mm A')},
    { value: 'Asia/Damascus', label: getUserTimezone('Asia/Damascus') +' Time - '+moment.tz('Asia/Damascus').format('hh:mm A')},
    { value: 'Asia/Dhaka', label: getUserTimezone('Asia/Dhaka') +' Time - '+moment.tz('Asia/Dhaka').format('hh:mm A')},
    { value: 'Asia/Dubai', label: getUserTimezone('Asia/Dubai') +' Time - '+moment.tz('Asia/Dubai').format('hh:mm A')},
    { value: 'Asia/Gaza', label: getUserTimezone('Asia/Gaza') +' Time - '+moment.tz('Asia/Gaza').format('hh:mm A')},
    { value: 'Asia/Irkutsk', label: getUserTimezone('Asia/Irkutsk') +' Time - '+moment.tz('Asia/Irkutsk').format('hh:mm A')},
    { value: 'Asia/Bangkok', label: getUserTimezone('Asia/Bangkok') +' Time - '+moment.tz('Asia/Bangkok').format('hh:mm A')},
    { value: 'Asia/Jerusalem', label: getUserTimezone('Asia/Jerusalem') +' Time - '+moment.tz('Asia/Jerusalem').format('hh:mm A')},
    { value: 'Asia/Kabul', label: getUserTimezone('Asia/Kabul') +' Time - '+moment.tz('Asia/Kabul').format('hh:mm A')},
    { value: 'Pacific/Majuro', label: getUserTimezone('Pacific/Majuro') +' Time - '+moment.tz('Pacific/Majuro').format('hh:mm A')},
    { value: 'Asia/Karachi', label: getUserTimezone('Asia/Karachi') +' Time - '+moment.tz('Asia/Karachi').format('hh:mm A')},
    { value: 'Indian/Maldives', label: getUserTimezone('Indian/Maldives') +' Time - '+moment.tz('Indian/Maldives').format('hh:mm A')},
    { value: 'Asia/Kathmandu', label: getUserTimezone('Asia/Kathmandu') +' Time - '+moment.tz('Asia/Kathmandu').format('hh:mm A')},
    { value: 'Asia/Calcutta', label: getUserTimezone('Asia/Calcutta') +' Time - '+moment.tz('Asia/Calcutta').format('hh:mm A')},
    { value: 'Asia/Colombo', label: getUserTimezone('Asia/Colombo') +' Time - '+moment.tz('Asia/Colombo').format('hh:mm A')},
    { value: 'Asia/Krasnoyarsk', label: getUserTimezone('Asia/Krasnoyarsk') +' Time - '+moment.tz('Asia/Krasnoyarsk').format('hh:mm A')},
    { value: 'Asia/Omsk', label: getUserTimezone('Asia/Omsk') +' Time - '+moment.tz('Asia/Omsk').format('hh:mm A')},
    { value: 'Asia/Yangon', label: getUserTimezone('Asia/Yangon') +' Time - '+moment.tz('Asia/Yangon').format('hh:mm A')},
    { value: 'Asia/Shanghai', label: getUserTimezone('Asia/Shanghai') +' Time - '+moment.tz('Asia/Shanghai').format('hh:mm A')},
    { value: 'Asia/Singapore', label: getUserTimezone('Asia/Singapore') +' Time - '+moment.tz('Asia/Singapore').format('hh:mm A')},
    { value: 'Australia/Perth', label: getUserTimezone('Australia/Perth') +' Time - '+moment.tz('Australia/Perth').format('hh:mm A')},
    { value: 'Asia/Tehran', label: getUserTimezone('Asia/Tehran') +' Time - '+moment.tz('Asia/Tehran').format('hh:mm A')},
    { value: 'Asia/Tokyo', label: getUserTimezone('Asia/Tokyo') +' Time - '+moment.tz('Asia/Tokyo').format('hh:mm A')},
    { value: 'Asia/Pyongyang', label: getUserTimezone('Asia/Pyongyang') +' Time - '+moment.tz('Asia/Pyongyang').format('hh:mm A')},
    { value: 'Asia/Vladivostok', label: getUserTimezone('Asia/Vladivostok') +' Time - '+moment.tz('Asia/Vladivostok').format('hh:mm A')},
    { value: 'Asia/Yakutsk', label: getUserTimezone('Asia/Yakutsk') +' Time - '+moment.tz('Asia/Yakutsk').format('hh:mm A')},
    { value: 'Asia/Yekaterinburg', label: getUserTimezone('Asia/Yekaterinburg') +' Time - '+moment.tz('Asia/Yekaterinburg').format('hh:mm A')},
    { value: 'Asia/Yerevan', label: getUserTimezone('Asia/Yerevan') +' Time - '+moment.tz('Asia/Yerevan').format('hh:mm A')},
];

const atlanticOptions = [
    { value: 'Atlantic/Azores', label: getUserTimezone('Atlantic/Azores') +' Time - '+moment.tz('Atlantic/Azores').format('hh:mm A')},
    { value: 'Atlantic/Cape_Verde', label: getUserTimezone('Atlantic/Cape_Verde') +' Time - '+moment.tz('Atlantic/Cape_Verde').format('hh:mm A')},
    ];

const australiaOptions = [
    { value: 'Australia/Adelaide', label: getUserTimezone('Australia/Adelaide') +' Time - '+moment.tz('Australia/Adelaide').format('hh:mm A')},
    { value: 'Australia/Brisbane', label: getUserTimezone('Australia/Brisbane') +' Time - '+moment.tz('Australia/Brisbane').format('hh:mm A')},
    { value: 'Australia/Darwin', label: getUserTimezone('Australia/Darwin') +' Time - '+moment.tz('Australia/Darwin').format('hh:mm A')},
    { value: 'Australia/Eucla', label: getUserTimezone('Australia/Eucla') +' Time - '+moment.tz('Australia/Eucla').format('hh:mm A')},
    { value: 'Australia/Lord_Howe', label: getUserTimezone('Australia/Lord_Howe') +' Time - '+moment.tz('Australia/Lord_Howe').format('hh:mm A')},
    { value: 'Australia/Perth', label: getUserTimezone('Australia/Perth') +' Time - '+moment.tz('Australia/Perth').format('hh:mm A')},
    { value: 'Australia/Sydney', label: getUserTimezone('Australia/Sydney') +' Time - '+moment.tz('Australia/Sydney').format('hh:mm A')},
    { value: 'Australia/Melbourne', label: getUserTimezone('Australia/Melbourne') +' Time - '+moment.tz('Australia/Melbourne').format('hh:mm A')},
];

const utcOptions = [
    { value: 'UTC', label: getUserTimezone('UTC') +' Time - '+moment.tz('UTC').format('hh:mm A')},
];

const europeOptions = [
    { value: 'CET', label: getUserTimezone('CET') +' Time - '+moment.tz('CET').format('hh:mm A')},
    { value: 'EET', label: getUserTimezone('EET') +' Time - '+moment.tz('EET').format('hh:mm A')},
    { value: 'Europe/Kyiv', label: getUserTimezone('Europe/Kyiv') +' Time - '+moment.tz('Europe/Kyiv').format('hh:mm A')},
    { value: 'Europe/Dublin', label: getUserTimezone('Europe/Dublin') +' Time - '+moment.tz('Europe/Dublin').format('hh:mm A')},
    { value: 'Europe/Lisbon', label: getUserTimezone('Europe/Lisbon') +' Time - '+moment.tz('Europe/Lisbon').format('hh:mm A')},
    { value: 'Europe/Minsk', label: getUserTimezone('Europe/Minsk') +' Time - '+moment.tz('Europe/Minsk').format('hh:mm A')},
    { value: 'Europe/Moscow', label: getUserTimezone('Europe/Moscow') +' Time - '+moment.tz('Europe/Moscow').format('hh:mm A')},
    { value: 'Europe/Istanbul', label: getUserTimezone('Europe/Istanbul') +' Time - '+moment.tz('Europe/Istanbul').format('hh:mm A')},
];

const pacificOptions = [
    { value: 'Pacific/Apia', label: getUserTimezone('Pacific/Apia') +' Time - '+moment.tz('Pacific/Apia').format('hh:mm A')},
    { value: 'Pacific/Auckland', label: getUserTimezone('Pacific/Auckland') +' Time - '+moment.tz('Pacific/Auckland').format('hh:mm A')},
    { value: 'Pacific/Chatham', label: getUserTimezone('Pacific/Chatham') +' Time - '+moment.tz('Pacific/Chatham').format('hh:mm A')},
    { value: 'Pacific/Easter', label: getUserTimezone('Pacific/Easter') +' Time - '+moment.tz('Pacific/Easter').format('hh:mm A')},
    { value: 'Pacific/Fiji', label: getUserTimezone('Pacific/Fiji') +' Time - '+moment.tz('Pacific/Fiji').format('hh:mm A')},
    { value: 'Pacific/Gambier', label: getUserTimezone('Pacific/Gambier') +' Time - '+moment.tz('Pacific/Gambier').format('hh:mm A')},
    { value: 'Pacific/Kiritimati', label: getUserTimezone('Pacific/Kiritimati') +' Time - '+moment.tz('Pacific/Kiritimati').format('hh:mm A')},
    { value: 'Pacific/Majuro', label: getUserTimezone('Pacific/Majuro') +' Time - '+moment.tz('Pacific/Majuro').format('hh:mm A')},
    { value: 'Pacific/Marquesas', label: getUserTimezone('Pacific/Marquesas') +' Time - '+moment.tz('Pacific/Marquesas').format('hh:mm A')},
    { value: 'Pacific/Norfolk', label: getUserTimezone('Pacific/Norfolk') +' Time - '+moment.tz('Pacific/Norfolk').format('hh:mm A')},
    { value: 'Pacific/Noumea', label: getUserTimezone('Pacific/Noumea') +' Time - '+moment.tz('Pacific/Noumea').format('hh:mm A')},
    { value: 'Pacific/Pago_Pago', label: getUserTimezone('Pacific/Pago_Pago') +' Time - '+moment.tz('Pacific/Pago_Pago').format('hh:mm A')},
    { value: 'Pacific/Pitcairn', label: getUserTimezone('Pacific/Pitcairn') +' Time - '+moment.tz('Pacific/Pitcairn').format('hh:mm A')},
    { value: 'Pacific/Tarawa', label: getUserTimezone('Pacific/Tarawa') +' Time - '+moment.tz('Pacific/Tarawa').format('hh:mm A')},
    { value: 'Pacific/Tongatapu', label: getUserTimezone('Pacific/Tongatapu') +' Time - '+moment.tz('Pacific/Tongatapu').format('hh:mm A')},
];

const groupedOptions = [
    {
        label: "US/CANADA",
        options: usCanadaOptions
    },
    {
        label: "AMERICA",
        options: americaOptions
    },
    {
        label: "AFRICA",
        options: africaOptions
    },
    {
        label: "ASIA",
        options: asiaOptions
    },
    {
        label: "ATLANTIC",
        options: atlanticOptions
    },
    {
        label: "AUSTRALIA",
        options: australiaOptions
    },
    {
        label: "UTC",
        options: utcOptions
    },
    {
        label: "EUROPE",
        options: europeOptions
    },
    {
        label: "PACIFIC",
        options: pacificOptions
    }
];

export const TimeZoneOptions = groupedOptions;