import React from "react";
import * as ACTION from "../constants/calendarActionTypes";

export const fetchCalendarWidgetList = (payload, callback) => ({
    type: ACTION.FETCH_CALENDAR_WIDGET,
    payload: {payload, callback}
});

export const turnOnCalendarWidget = (payload, callback) => ({
    type: ACTION.FETCH_CALENDAR_WIDGET,
    payload: {payload, callback}
});

export const fetchCalendarWidgetDetails = (payload, callback) => ({
    type: ACTION.FETCH_CALENDAR_WIDGET_DETAILS,
    payload: {payload, callback}
});

export const fetchCampaignList = (payload, callback) => ({
    type: ACTION.FETCH_CAMPAIGN_LIST,
    payload: {payload, callback}
});

export const fetchLeadFlowList = (payload, callback) => ({
    type: ACTION.FETCH_LEAD_FLOW_LIST,
    payload: {payload, callback}
});

export const updateCalendarWidgetEvents = payload => ({
    type:ACTION.UPDATE_CALENDAR_EVENTS_SLOT,
    payload: payload
});

export const regenerateCalendarEvents = (payload, callback) => ({
    type: ACTION.REGENERATE_CALENDAR_EVENTS,
    payload: {payload, callback}
});