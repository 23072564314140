import moment from 'moment';
import { useEffect, useState } from 'react';
import CustomPopupMenu from './CustomPopupMenu';
import { calendarWidgetDefault, DefaultFieldGroup } from './Default';
import './style.css';

const GlobalCustomFieldGrouped = ({
    className,
    handleSelect,
    labelText = 'Personalized Tags',
    forCalendarWidget = false,
    omitGroup=[] //["pype_calendar_widget", "pype_default_contact", "pype_default_user", "pype_date", "pype_agency", "user_cutomize_personalized_fields"]
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        let local_data = localStorage.getItem("global__custom__field__data");
        if(local_data){
            try{
                let parse_data = JSON.parse(local_data)
                if(parse_data.data !== undefined){
                    var now = moment(new Date()); //todays date
                    var end = moment(parse_data.time); // another date
                    var duration = moment.duration(now.diff(end));
                    var hours = duration.asHours();
                    if(hours < 23){
                        if(forCalendarWidget){
                            if(parse_data.data[0].group_value !== 'pype_calendar_widget'){
                                parse_data.data = calendarWidgetDefault.concat(parse_data.data)
                            }
                        }
                        else{
                            if(parse_data[0].group_value === 'pype_calendar_widget'){
                                parse_data.data.splice(0, 1)
                            }
                        }

                        if (omitGroup?.length > 0){
                            parse_data.data = parse_data.data.filter((group)=>(!omitGroup.includes(group.group_value)));
                        }

                        setList(parse_data.data)
                    }
                    else{
                        getData()
                    }
                }
                else{
                    localStorage.removeItem("global__custom__field__data")
                    getData()
                }
            }
            catch(error){
                getData()
            }
        }
        else{
            getData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const hanldeMenu = (value) => {
        setShowMenu(value)
    }

    const getData = () => {
        if(isLoading){
            return
        }
        setIsLoading(true)
        window.globalCustomFieldWithPersonalized({
            get_custom_data: true,
            get_personalized_data: true
        }).then(res => {
            let data = [];
            /* custom field data */
            let customFieldData = [],
                customFieldGroup = {};
            res.data.custom_field.forEach((row)=>{
                if(customFieldGroup[row.group_name] !== undefined){
                    let total_data = customFieldGroup[row.group_name].total + 1
                    customFieldGroup[row.group_name]['total'] = total_data;
                    customFieldGroup[row.group_name]['list'].push({
                        field_label: row.title,
                        value: row.personalize_tag,
                        order: row.order
                    });
                }
                else{
                    customFieldGroup[row.group_name] = {
                        group_name: row.group_name,
                        group_value: row.group_name,
                        list: [{
                            field_label: row.title,
                            value: row.personalize_tag,
                            order: row.order
                        }],
                        total: 1
                    }
                }
            })
            for (const group in customFieldGroup) {
                customFieldData.push(customFieldGroup[group])
                }

            if(forCalendarWidget){
                data = calendarWidgetDefault.concat(DefaultFieldGroup)
            }
            else{
                data  = DefaultFieldGroup.concat(customFieldData);
            }
            /* personalized data */
            let personalizedData = [
                {
                    group_name: 'User Personalized Fields',
                    list: [],
                    total: 0,
                    group_value: 'user_cutomize_personalized_fields'
                }
            ];
            let personalize_field_order = 0;
            res.data.personalized_field.forEach((row) => {
                personalizedData[0].list.push({
                    field_label: row.title,
                    value: row.personalize_tag,
                    order: personalize_field_order,
                });
                personalize_field_order++;
            });
            personalizedData[0].total = personalize_field_order
            data  = data.concat(personalizedData);

            let omitData = data;

            if (omitGroup?.length > 0){
                omitData = omitData.filter((group)=>(!omitGroup.includes(group.group_value)));
            }

            setList(omitData);
            setIsLoading(false)
            localStorage.setItem("global__custom__field__data", JSON.stringify({
                data: data,
                time: new Date()
            }));
        }).catch(error => {
            setIsLoading(false)
        })
    }

    const handleItemChange = (value, group_value = '') => {
        if(group_value === 'pype_calendar_widget'){
            value = value.replace(/\[/g, '{').replace(/\]/g, '}')
        }
        handleSelect(value, group_value)
    }

    return(
        <div className={`pypepro_global__custom__field__grouped__wraper ${className}`}>
            <CustomPopupMenu 
                hanldeMenu={hanldeMenu}
                showMenu={showMenu}
                list={list}
                handleClick={(value, group_value) => handleItemChange(value, group_value)}
                labelText={labelText}
                isLoading={isLoading}
                forCalendarWidget={forCalendarWidget}
            />
        </div>
    );
}
export default GlobalCustomFieldGrouped;