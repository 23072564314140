import React from 'react';
import If from "if-else-react";

class EachSchedule extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <If	condition={this.props.isSelected === this.props.time.time}>
                    <div  className="timeslot-item-list">
                        <div className="timeslot-list is-selected">
                            <span className="timeslot-time">{this.props.time.time}</span>
                            <span onClick={()=>{this.props.handlerConfirm(this.props.time.date, this.props.time.time)}} className="timeslot-confirm">Confirm</span>
                        </div>
                    </div>
                </If>

                <If	condition={this.props.isSelected !== this.props.time.time}>
                    <div  className="timeslot-list myclass">
                        <span onClick={()=>this.props.handler(this.props.time.date, this.props.time.time)} >{this.props.time.time}</span>
                    </div>
                </If>
            </React.Fragment>
        );
    }
}
export default EachSchedule;