import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchActivityCalendar = async (payload) => {
    return sendRequest("POST", Api.getActivityCalendar, payload);
}

export const fetchActivityList = async (payload) => {
    return sendRequest("POST", Api.getActivityList, payload);
}

export const fetchTaskCount = async (payload) => {
    return sendRequest("POST", Api.getTaskCount, payload);
}

export const getUserGroup = async (payload) => {
    return sendRequest("post", Api.getTeamUsers, payload);
}

export const getContacts = async (payload) => {
    return sendRequest("post", Api.getContacts, payload.form_data);
}

export const saveActivityFollowup = async (payload) => {
    return sendRequest("post", Api.saveActivityFollowup, payload);
}

export const markAsDone = async (payload) => {
    return sendRequest("post", Api.markAsDone, payload);
}

export const fetchCalendarWidgets = async (payload) => {
    return sendRequest("post", Api.getCalendarWidgetList, payload);
}

export const turnOnCalendarWidget = async (payload) => {
    return sendRequest("post", Api.turnOnCalendarWidget, payload);
}

export const setDefaultCalendar = async (payload) => {
    return sendRequest("post", Api.setDefaultCalendar, payload);
}

export const createNewCalendarWidgets = async (payload) => {
    return sendRequest("post", Api.createNewCalendarWidget, payload);
}

export const deleteCalendarWidget = async (payload) => {
    return sendRequest("post", Api.deleteCalendarWidget, payload);
}

export const fetchCalendarWidgetDetails = async (payload) => {
    return sendRequest("post", Api.fetchCalendarWidgetDetails, payload);
}

export const fetchCampaignList = async (payload) => {
    return sendRequest("post", Api.fetchCampaignList, payload);
}

export const fetchLeadFlowList = async (payload) => {
    return sendRequest("post", Api.fetchLeadFlowList, payload);
}

export const fetchCalendarWidgetScheduledDetails = async (payload) => {
    return sendRequest("post", Api.fetchCalendarWidgetScheduledDetails, payload);
}

export const updatePolicySection = async (payload) => {
    return sendRequest("post", Api.updatePolicySection, payload);
}

export const updateBasicSection= async (payload) => {
    return sendRequest("post", Api.updateBasicSection, payload);
}

export const updateCalendarSection = async (payload) => {
    return sendRequest("post", Api.updateCalendarSection, payload);
}

export const updateConfirmationSection = async (payload) => {
    return sendRequest("post", Api.updateConfirmationSection, payload);
}

export const regenerateCalendarEvents = async (payload) => {
    return sendRequest("post", Api.regenerateCalendarEvents, payload);
}

export const updateCalendarWidgetStatus = async (payload) => {
    return sendRequest("post", Api.updateCalendarWidgetStatus, payload);
}

export const emailUploadFileApi = async (payload) => {
    return sendRequest("post", Api.emailUploadFileApi, payload);
}

export const deleteActivityForCalendar = async (payload) => {
    return sendRequest("post", Api.deleteActivityForCalendar, payload);
}

export const deleteBulkActivityForCalendar = async (payload) => {
    return sendRequest("post", Api.deleteBulkActivityForCalendar, payload);
}
export const userConnectedCalendarList = async (payload) => {
    return sendRequest("post", Api.userConnectedCalendarList, payload);
}

