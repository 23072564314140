import React from "react";
import * as ACTION from "../constants/calendarActionTypes";

export const fetchActivityCalendar = (payload, callback) => ({
    type: ACTION.FETCH_ACTIVITY_CALENDAR,
    payload: {payload, callback}
});

export const fetchActivityListData = (payload, callback) => ({
    type: ACTION.FETCH_ACTIVITY_LIST,
    payload: {payload, callback}
});

export const fetchTaskCount = (payload, callback) => ({
    type: ACTION.FETCH_TASK_COUNT,
    payload: {payload, callback}
});

export const updateActivityActiveTab = payload => ({
    type:ACTION.UPDATE_VIEW_ACTIVE_TAB,
    payload: payload
});

export const updateFilterActiveTab = payload => ({
    type:ACTION.UPDATE_VIEW_ACTIVE_TAB,
    payload: payload
});

export const updateActiveTaskId = payload => ({
    type:ACTION.UPDATE_TASK_ACTIVE_TAB,
    payload: payload
});

export const followupActivitySave = (payload, callback) => ({
    type: ACTION.SAVE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
});

export const fetchContactsList = (payload, callback) => ({
    type: ACTION.FETCH_CONTACT_LIST,
    payload: {payload, callback}
});

export const storeUpdateTask = (payload)=>({
    type: ACTION.UPDATE_TASK_UPDATE_FLAG,
    payload: payload
});

export const storeCalendarListSelectedPageSize = (payload)=>({
    type: ACTION.UPDATE_CALENDAR_PAGE_SIZE,
    payload: payload
});

export const storeCalendarListSelectedPage = (payload)=>({
    type: ACTION.UPDATE_CALENDAR_PAGE,
    payload: payload
});

export const storeCalendarUpdateActivityList = (payload)=>({
    type: ACTION.UPDATE_CALENDAR_ACTIVITY_LIST,
    payload: payload
});

export const storeCalendarListSelectedFilter = (payload)=>({
    type: ACTION.UPDATE_CALENDAR_ACTIVITY_FILTER,
    payload: payload
});

export const storeCalendarListSelectedTask = (payload)=>({
    type: ACTION.UPDATE_CALENDAR_ACTIVITY_TASK,
    payload: payload
});

export const storeCalendarActivitySelectedTask = (payload)=>({
   type: ACTION.UPDATE_CALENDAR_ACTIVITY_SELECTED_TASK,
   payload: payload
});

export const resetCalendarActivitySelectedTask = ()=>({
    type: ACTION.RESET_CALENDAR_ACTIVITY_SELECTED_TASK
});






