import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import './assets/css/calendar-widget.css';
import {createNewCalendarWidgets} from "../../api/calendarApi";
import {fetchCalendarWidgetList} from "../../actions/calendarWidgetAction";
import CalendarWidgetBlock from "./CalendarWidgetBlock";
import EmbedCode from "./EmbedCode";
import Utils from "../../helpers/Utils";
import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {useHistory} from "react-router-dom";
import CustomConfirmAlertMod from "../Common/confirm-alert-modified/CustomConfirmAlertMod";
import { CalendarIcon } from "../Common/confirm-alert-modified/IconList";
import { CustomButton } from "../Common/CustomButton";
import AddIcon from "@material-ui/icons/Add";

const App = (props) => {

    const [calendarWidgetList, setCalendarWidgetList] = useState(props.calendarWidgetList);
    const [loading, setLoading] = useState(true);
    const [openEmbedCodeModal, setOpenEmbedCodeModal] = useState(false);
    const [embedApiKey, setEmbedApiKey] = useState(null);
    const [embedTitle, setEmbedTitle] = useState(null);
    const history = useHistory();

    const closeEmbedCodeModal = () => {
        setOpenEmbedCodeModal(false);
    }

    useEffect(() => {
        document.title = `Calendar | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('calendar');
    },[]);

    useEffect(()=>{
        props.fetchCalendarWidgetList();
    }, [loading])

    useEffect(()=>{
        setCalendarWidgetList(props.calendarWidgetList);
    }, [props.calendarWidgetList])

    const fetchList = () => {
        props.fetchCalendarWidgetList();
    }

    const openEmbedWidget = (data) => {
        setEmbedApiKey(data.api_key);
        setEmbedTitle(data.permalink);
        setOpenEmbedCodeModal(true);
    }

    let calendarWidgetListItems = calendarWidgetList.map(function(data,index){
        return(
            < CalendarWidgetBlock key={`${index}-${data.id}-${data.is_default}`} data={data} fetchList={()=>fetchList(true)} openEmbedWidget={(data)=>openEmbedWidget(data)}/>
        )
    });

    const createNewCalendar = () => {

        createNewCalendarWidgets({
            calendar_from : moment().format('Y-MM-DD'),
            calendar_to : moment().add(30, 'days').format('Y-MM-DD')
        }).then(res => {
            if(res.data.status == true) {
                history.push(`/calendar-widget/${res.data.data.id}`);
                window.location.reload();
            }else{
                showAlert('Something wrong!', 'Please try again later');
            }
        });

    }

    const showAlert = (title, message) => {
        let alertTitle = title ? title : 'Cannot perform action';
        let alertMessage = message ? message : 'This calendar is not configured correctly yet!';
        CustomConfirmAlertMod({
            onSubmit : () => {
                return;
            },
            title: alertTitle,
            description: alertMessage,
            submitText: 'Ok, I understand'
        });
    };



    return (
        <React.Fragment>


                <div class="calendar_section">
                    <div class="page_heading">
                        <div style={{display:'flex',alignItems:'center'}}>
                        <CalendarIcon/>
                        <div>
                        <h4>Online Appointment Scheduling</h4>
                        <p style={{ margin: '0px 0px 0px 25px'}}>This tool makes it easy to share your availability with clients and prospects so they can self-book an appointment with you online</p>
                        </div>
                        </div>
                        <CustomButton
                            variant="contained"
                            onClick={ (event) => createNewCalendar(event) }
                            startIcon={<AddIcon />}
                            disableElevation
                            size="small"
                            >
                            New Event
                        </CustomButton>
                    </div>

                    <div className="page_content calendar_card_new_v2h_top_scroll awesome__scroll_bar">
                       

                            {
                                !calendarWidgetList.length ?
                                <div className="row">
                                    <div className="col s12">
                                            <h5>You don't have any calendar events yet.</h5>
                                            <p>You need to add an event type to allow people to schedule with you.</p>
                                    </div> </div>:
                                    <div className="calendar_card_new_v2h_wrapper">
                                       { calendarWidgetListItems}
                                    </div>

                            }

                        
                    </div>
                </div>

            < EmbedCode
                api_key = {embedApiKey}
                openEmbedCodeModal={openEmbedCodeModal}
                closeEmbedCodeModal={() => closeEmbedCodeModal()}
                calendarTitle = {embedTitle}
            />


        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        calendarWidgetList : state.calendarWidgetReducer.calendarWidgetList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCalendarWidgetList : (params) => dispatch(fetchCalendarWidgetList(params)),
    };
}

const CalendarWidget = connect(mapStateToProps, mapDispatchToProps)(App);

export default CalendarWidget;
