import { all } from "redux-saga/effects";
import calendarMiddleware from "./calendarMiddleware";
import calendarWidgetMiddleware from "./calendarWidgetMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            calendarMiddleware(),
            calendarWidgetMiddleware(),
        ]
    )
}
