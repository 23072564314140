import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {updatePolicySection} from "../../../api/calendarApi";
import {} from "../../../actions/calendarWidgetAction";
import {confirmAlert} from "react-confirm-alert";

const App = (props) => {

    const DISPLAY_THANK_YOU_PAGE = 0;
    const REDIRECT_TO_URL = 1;

    const [loading, setLoading] = useState(true);
    const [calendarDetails, setCalendarDetails] = useState(props.calendarDetails);
    const [onConfirm, setOnConfirm] = useState(DISPLAY_THANK_YOU_PAGE);
    const [buttonText, setButtonText] = useState(0);
    const [showButtonAfterConfirm, setShowButtonAfterConfirm] = useState(0);
    const [confirmationLink, setConfirmationLink] = useState(0);
    const [submitBtnText, setSubmitBtnText] = useState('Save & Close');
    const [urlValidationError, setUrlValidationError] = useState(false);
    const [btnTextValidationError, setBtnTextValidationError] = useState(false);

    const handleSubmit = () => {

        setUrlValidationError(false);
        setBtnTextValidationError(false);

        if(onConfirm == REDIRECT_TO_URL && confirmationLink == ''){
            setUrlValidationError(true);
            return false
        }

        if(onConfirm == DISPLAY_THANK_YOU_PAGE && showButtonAfterConfirm && buttonText == ''){
            setBtnTextValidationError(true);
            return false
        }

        if(onConfirm == REDIRECT_TO_URL && confirmationLink != ''){
            if(!validURL(confirmationLink)){
                setUrlValidationError(true);
                return false
            }

        }



        updatePolicySection({
            calendarId : props.calendarDetails.id,
            confirmation_link : onConfirm == DISPLAY_THANK_YOU_PAGE ? '' : confirmationLink,
            show_button_after_confirm : showButtonAfterConfirm,
            button_text : buttonText

        }).then(res => {
            if(res.status == 200){
                setSubmitBtnText('Saved Successful');
                props.turnOnEvent();
            }else{
                setSubmitBtnText('Save Then Turn event type "ON" at top right');
            }
        });
    }

    const validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }


    useEffect(()=>{
        setUrlValidationError(false);
        setBtnTextValidationError(false);
        setCalendarDetails(props.calendarDetails);
        if(props.calendarDetails.id != undefined){
            setOnConfirm(props.calendarDetails.confirmation_link != '' ? 1 : 0);
            setButtonText(props.calendarDetails.button_text);
            setShowButtonAfterConfirm(props.calendarDetails.show_button_after_confirm);
            setConfirmationLink(props.calendarDetails.confirmation_link);
        }
    }, [props.calendarDetails])

    useEffect(()=>{
        setUrlValidationError(false);
        setBtnTextValidationError(false);
    },[onConfirm])

    useEffect(()=>{
        setUrlValidationError(false);
    },[confirmationLink])


    return (
        <>
            <div className="row calender_confirmation_widget">
                <div className="col s12">
                    <div className="white_body white_body_update_white_policy_v2">
                        <div className="row">
                            <div className="col s6 white_body_update_white_policy_v2_col">
                                <div className="input_group">
                                    <label>On Confirmation</label>
                                    <div className="option_select">
                                        <select className="input-field" value={onConfirm} onChange={(event) => setOnConfirm(event.target.value)}>
                                            <option value={DISPLAY_THANK_YOU_PAGE} >Display thank you page</option>
                                            <option value={REDIRECT_TO_URL} >Redirect to URL</option>
                                        </select>
                                        <svg width="14" height="7" viewBox="0 0 14 7" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 6L13 1" stroke="#133159"
                                                  stroke-opacity="0.5" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            {
                                onConfirm == DISPLAY_THANK_YOU_PAGE ?
                                    <div className="col s6 white_body_update_white_policy_v2_col">
                                        <div className="input_group">
                                            <div className="condition_label">
                                                <label>Display button to schedule another event?</label>
                                                <label className="switch">
                                                    <small className="on">
                                                        <svg width="50" height="25" viewBox="0 0 52 27"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.5" y="0.5" width="51" height="26"
                                                                  rx="13" fill="white"/>
                                                            <path
                                                                d="M38 3.50004C43.5228 3.50004 48 7.9772 48 13.5C48 19.0228 43.5228 23.5 38 23.5C32.4772 23.5 28 19.0228 28 13.5C27.9843 7.99286 32.436 3.51576 37.9431 3.50004C37.9621 3.49999 37.981 3.49999 38 3.50004Z"
                                                                fill="#3C7EF3"/>
                                                            <path
                                                                d="M43.5405 10.7432L36.4382 17.8455L32.4609 13.8967L34.0803 12.3058L36.4382 14.6353L41.9212 9.15234L43.5405 10.7432Z"
                                                                fill="white"/>
                                                            <rect x="0.5" y="0.5" width="51" height="26"
                                                                  rx="13" stroke="#F2F2F2"/>
                                                        </svg>
                                                    </small>
                                                    <input value={1} onChange={(event)=>setShowButtonAfterConfirm(!showButtonAfterConfirm)} type="checkbox" checked={showButtonAfterConfirm ? 'checked' : ''} />
                                                    <span className="slider round"></span>
                                                    <small className="off">
                                                        <svg width="50" height="25" viewBox="0 0 62 32"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.5" y="0.5" width="61" height="31"
                                                                  rx="15.5" fill="white"/>
                                                            <path
                                                                d="M16 4.00005C22.6274 4.00005 28 9.37264 28 16C28 22.6274 22.6274 28 16 28C9.37264 28 4.00005 22.6274 4.00005 16C3.98119 9.39143 9.32318 4.01891 15.9317 4.00005C15.9545 3.99998 15.9773 3.99998 16 4.00005Z"
                                                                fill="#FF264A"/>
                                                            <path
                                                                d="M19.6058 19.6066C19.28 19.9325 18.9883 20.1676 18.6624 19.8417L16.0703 17.2496L13.4782 19.8417C13.1529 20.167 12.8606 19.9325 12.5348 19.6066C12.2089 19.2807 11.9744 18.9885 12.2997 18.6632L14.8918 16.0711L12.2997 13.4789C11.9744 13.1537 12.2089 12.8614 12.5348 12.5355C12.8606 12.2097 13.1529 11.9752 13.4782 12.3004L16.0703 14.8926L18.6624 12.3004C18.9883 11.9746 19.28 12.2097 19.6058 12.5355C19.9317 12.8614 20.1668 13.1531 19.841 13.4789L17.2488 16.0711L19.841 18.6632C20.1668 18.9891 19.9317 19.2807 19.6058 19.6066Z"
                                                                fill="white"/>
                                                            <rect x="0.5" y="0.5" width="61" height="31"
                                                                  rx="15.5" stroke="#E0E0E0"/>
                                                        </svg>
                                                    </small>
                                                </label>
                                            </div>
                                            {
                                                showButtonAfterConfirm ?
                                                <div className="option_select">
                                                    <input type="text" value={buttonText} onChange={(event)=>setButtonText(event.target.value)}/>
                                                    {btnTextValidationError && <p className={"text-danger"}> Button text is required</p>}
                                                </div>
                                                    :
                                                    ''
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div className="col s6">
                                        <div className="input_group">
                                            <div className="condition_label">
                                                <label>Redirect URL</label>
                                            </div>
                                            <div className="option_select">
                                                <input type="text" value={confirmationLink} onChange={(event)=>setConfirmationLink(event.target.value)}/>
                                                {urlValidationError && <p className={"text-danger"}> Valid URL is required</p>}
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="collapsible_footer">
                    <button type="button" className="btn btn_danger d-inline-flex align-items-center btnSetHeight">
                        <span className="mr-2">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                            </span>Cancel</button>
                    <button onClick={()=>handleSubmit()} type="button" className="btn btn_info d-inline-flex align-items-center btnSetHeight accent--bg--color "><span className="mr-2"><svg className="MuiSvgIcon-root" focusable="false"
                                                                                                                            viewBox="0 0 24 24" aria-hidden="true"><path
                        d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg></span>{submitBtnText}
                    </button>
                </div>
            </div>
        </>
    );
}


const mapStateToProps = state => {
    return {
        calendarDetails : state.calendarWidgetReducer.calendarWidgetDetails
    };
};

const CalendarWidgetPolicySection = connect(mapStateToProps, null)(App);

export default CalendarWidgetPolicySection;
