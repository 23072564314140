import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import Utils from "../helpers/Utils";
import * as ACTION from "../constants/calendarActionTypes";
import * as CALENDAR_API from "../api/calendarApi";
import {fetchCalendarWidgets, fetchCalendarWidgetScheduledDetails} from "../api/calendarApi";

function* calendarWidgetWatcher() {
    yield takeEvery(ACTION.FETCH_CALENDAR_WIDGET, fetchCalendarWidgetMiddleware);
    yield takeEvery(ACTION.FETCH_CALENDAR_WIDGET_DETAILS, fetchCalendarWidgetDetailsMiddleware);
    yield takeEvery(ACTION.FETCH_CALENDAR_WIDGET_DETAILS, fetchCalendarWidgetScheduledDetailsMiddleware);
    yield takeEvery(ACTION.REGENERATE_CALENDAR_EVENTS, regenerateCalendarEventsMiddleware);
}

function* fetchCalendarWidgetMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchCalendarWidgets, action.payload);
        let responseData = response.data.data[0];
        yield put({type: ACTION.CALENDAR_WIDGET_LIST, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchCalendarWidgetDetailsMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchCalendarWidgetDetails, action.payload);
        let responseData = response.data.data[0];
        yield put({type: ACTION.CALENDAR_WIDGET_DETAILS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchCalendarWidgetScheduledDetailsMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.fetchCalendarWidgetScheduledDetails, action.payload);

        let responseData = response.data.data[0];
        yield put({type: ACTION.CALENDAR_WIDGET_SCHEDULE_DETAILS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}


function* regenerateCalendarEventsMiddleware(action) {
    try {
        const response = yield call(CALENDAR_API.regenerateCalendarEvents, action.payload);

        let responseData = response.data.data;
        yield put({type: ACTION.CALENDAR_WIDGET_SCHEDULE_DETAILS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* calendarWidgetMiddleware() {
    yield all([
        calendarWidgetWatcher(),
    ])
}
