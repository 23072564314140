import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import If from "if-else-react";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import {
  fetchLeadFlowList,
  updateBasicSection,
  userConnectedCalendarList,
} from "../../../api/calendarApi";
import BootstrapTooltip from "../../Common/BootstrapTooltip";
import GlobalCustomFieldGrouped from "../../Common/GlobalCustomFieldGrouped/Index";
import { HelpIcon } from "../../Common/Icons";
import CustomSwitch from "../../Common/Switch";
import BasicSelect from "../../Common/custom/BasicSelect";

const App = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const [calendarError, setCalendarError] = useState(false);

  const givenEventTitleRef = useRef();
  const givenEventDescriptionRef = useRef();
  const [loading, setLoading] = useState(true);
  const [lengthError, setLengthError] = useState(false);
  const [calendarDetails, setCalendarDetails] = useState(props.calendarDetails);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [leadFlow, setLeadFlow] = useState(null);
  const [description, setDescription] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [displayLocation, setDisplayLocation] = React.useState(1);
  const [eventLink, setEventLink] = useState("");
  const [eventLinkError, setEventLinkError] = useState(false);
  const [color, setColor] = useState("");
  const [status, setStatus] = useState(0);
  const [saveChanges, setSaveChanges] = useState(0);
  const [leadFlows, setLeadFlows] = useState([]);
  const [btnTitle, setBtnTitle] = useState(
    "Save & Set booking time in next step"
  );
  const [copyToClipBoard, setCopyToClipBoard] = useState("");
  const [incomingEventSync, setIncomingEventSync] = useState(0);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState("");

  let current_url = window.location.href;
  let domain = new URL(current_url);
  // let root_url = getAccountData('baseUrl');
  let root_url = "https://" + window.location.hostname;
  const handleCheckboxClick = () => {
    console.log("Checkbox was clicked");
    // Perform your desired action here
  };
  useEffect(() => {
    fetchLeadFlowList({}).then((res) => {
      if (res.status == 200) {
        setLeadFlows(res.data.data[0]);
      }
    });
    try {
      userConnectedCalendarList()
        .then((res) => {
          if (res.data.status === true && res.data.data) {
            setCalendarData(res.data.data);
            console.log("======data=====", res.data);
          }
        })
        .catch((err) => {
          console.log("error");
        });
    } catch (er) {
      setCalendarData([]);
    }
  }, []);
  const updateSelectedCalendar = (value) => {
    setSelectedCalendar(value);
    console.log("Selected calendar: ", value);
  };

  const updateTitle = (event) => {
    event.preventDefault();
    setTitle(event.target.value);
    if (event.target.value.length > 60) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
  };

  const handleSubmit = () => {
    if (lengthError) {
      return false;
    }

    if (title == "") {
      setTitleError(true);
      return false;
    }

    if (eventLink == "") {
      setEventLinkError(true);
      return false;
    }

    setBtnTitle("Processing . . .");
    updateBasicSection({
      calendarId: props.calendarDetails.id,
      title: title,
      leadFlowId: leadFlow,
      description: description,
      eventLink: eventLink,
      eventLocation: eventLocation,
      displayLocation: displayLocation,
      color: color,
      incomingEventSync: incomingEventSync,
      userNylasCalendarId: selectedCalendar,
    }).then((res) => {
      if (res.status == 200) {
        setBtnTitle("Saved successfully");
        setSaveChanges(0);
        props.openNextSection(1, true);
      } else {
        setBtnTitle("Save & Set booking time in next step");
      }
    });
  };

  const generatePermalink = (event) => {
    if (lengthError) return false;

    var permalink = event.target.value.trim();

    permalink = permalink.replaceAll(" ", "-");

    setEventLink(permalink.toLowerCase());

    setTitleError(0);
    setEventLinkError(0);
    setSaveChanges(1);
  };

  const copyCalendarLink = (event) => {
    event.preventDefault();

    setCopyToClipBoard("Copied");

    setTimeout(function () {
      setCopyToClipBoard("");
    }, 2000);
  };

  const handleChangePersonalizedForGivenEventTitle = (value) => {
    if (givenEventTitleRef) {
      let start = givenEventTitleRef.current.selectionStart;
      let end = givenEventTitleRef.current.selectionEnd;
      let text = givenEventTitleRef.current.value;
      let before = text.substring(0, start);
      let after = text.substring(end, text.length);
      let newText = before + value + after;
      setTitle(newText);
      setTimeout(() => {
        givenEventTitleRef.current.selectionStart =
          givenEventTitleRef.current.selectionEnd = start + value.length;
        givenEventTitleRef.current.focus();
      }, 0);
    }
  };

  const handleChangePersonalizedForGivenEventDescription = (value) => {
    if (givenEventDescriptionRef) {
      let start = givenEventDescriptionRef.current.selectionStart;
      let end = givenEventDescriptionRef.current.selectionEnd;
      let text = givenEventDescriptionRef.current.value;
      let before = text.substring(0, start);
      let after = text.substring(end, text.length);
      givenEventDescriptionRef.current.value = before + value + after;
      setDescription(before + value + after);
      setTimeout(() => {
        givenEventDescriptionRef.current.selectionStart =
          givenEventDescriptionRef.current.selectionEnd = start + value.length;
        givenEventDescriptionRef.current.focus();
      }, 0);
    }
  };

  useEffect(() => {
    setCalendarDetails(props.calendarDetails);
    if (props.calendarDetails.id != undefined) {
      setTitle(props.calendarDetails.title);
      setDescription(props.calendarDetails.description);
      setEventLocation(props.calendarDetails.location);
      setEventLink(props.calendarDetails.permalink);
      setDisplayLocation(props.calendarDetails.location_setting);
      setLeadFlow(props.calendarDetails.leadflow_id);
      setColor(props.calendarDetails.color);
      setIncomingEventSync(props.calendarDetails.incoming_event_sync);
      setSelectedCalendar(props.calendarDetails.user_nylas_calendar_id);
    }
  }, [props.calendarDetails]);

  let leadflows = [];

  leadflows = leadFlows.map(function (data, index) {
    return (
      <option key={index} value={data.id}>
        {data.title}
      </option>
    );
  });

  let calendarUrl = "";

  if (props.calendarDetails.api_key != undefined) {
    calendarUrl = root_url + "/calendar/" + props.calendarDetails.api_key + "/";
  }

  return (
    <>
      <div className="row">
        <div className="col xl6 s6 basic_top_row_update_v2">
          {/* <div className="input_group">
                        <label>Do you like to use lead Flow</label>
                        <div className="option_select">
                            <select value={leadFlow} onChange={(event) => setLeadFlow(event.target.value)} className="input-field">
                                <option value="0" selected>Select Lead Flow</option>
                                {leadflows}
                            </select>
                            <svg width="14" height="7" viewBox="0 0 14 7" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 6L13 1" stroke="#133159" stroke-opacity="0.5" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div> */}
          <div className="input_group">
            <div className="input_group_calender_widget_wr">
              <label className="input_group_calender_widget_wr_title">
                Event Name
              </label>
              <div className="input_group_calender_widget_container">
                <GlobalCustomFieldGrouped
                  className={"send_module__quick__reply__global_custom__field"}
                  labelText={"Personalized"}
                  handleSelect={handleChangePersonalizedForGivenEventTitle}
                  omitGroup={["pype_default_contact"]}
                />
              </div>
            </div>
            <input
              ref={givenEventTitleRef}
              onBlur={(event) => generatePermalink(event)}
              placeholder="Name your event"
              type="text"
              className="validate"
              value={title}
              onChange={(event) => updateTitle(event)}
            />
            <If condition={titleError}>
              <p className={"text-red"}>Title is required</p>
            </If>
            <If condition={lengthError}>
              <p className={"text-red"}>Length must be less than 60</p>
            </If>
          </div>
          <div className="input_group">
            <label>
                Event Location 
                <span style={{fontSize:"14px",color:"#3c7ef3",fontWeight:600}}>(Zoom, Google Meet, Microsoft Teams, Webex, GoTo Meeting)</span>
            </label>
            <input
              placeholder="Event Location"
              type="text"
              className="validate"
              value={eventLocation}
              onChange={(event) => setEventLocation(event.target.value)}
            />
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="display location"
              name="displayLocation"
              value={displayLocation}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Display location while booking"
                onClick={() => setDisplayLocation(1)}
              />
              <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label="Display location only after confirmation"
                onClick={() => setDisplayLocation(2)}
              />
            </RadioGroup>
          </FormControl>
          <div className="input_group">
            <label>Event link</label>
            <div className="link_copy">
              <a href="javascript:void(0)">{calendarUrl}</a>
              <input
                onBlur={(event) => generatePermalink(event)}
                value={eventLink}
                onChange={(event) => setEventLink(event.target.value)}
                type="text"
                className="validate"
              />
              <CopyToClipboard text={calendarUrl + eventLink}>
                <span
                  title="Copy to clipboard"
                  onClick={(event) => copyCalendarLink(event)}
                  className="material-icons pl-2 text-gray custom-cursor-pointer"
                >
                  content_copy
                </span>
              </CopyToClipboard>{" "}
              {eventLink != "" && <p>{copyToClipBoard}</p>}
            </div>
            <If condition={eventLinkError}>
              <p className={"text-red"}>Event link is required</p>
            </If>
          </div>
          <div className="input_group">
            <label>Event Color</label>
            <div className="color_radio">
              <label className="six">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#673ab7" ? "checked" : ""}
                  onChange={() => setColor("#673ab7")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="one">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#3C7EF3" ? "checked" : ""}
                  onChange={() => setColor("#3C7EF3")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="two">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#133159" ? "checked" : ""}
                  onChange={() => setColor("#133159")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="three">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#FFFFFF" ? "checked" : ""}
                  onChange={() => setColor("#FFFFFF")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="four">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#696DFD" ? "checked" : ""}
                  onChange={() => setColor("#696DFD")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="five">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#7DEBCA" ? "checked" : ""}
                  onChange={() => setColor("#7DEBCA")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="seven">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#EF994A" ? "checked" : ""}
                  onChange={() => setColor("#EF994A")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="eight">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#F2C94C" ? "checked" : ""}
                  onChange={() => setColor("#F2C94C")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="nine">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#539755" ? "checked" : ""}
                  onChange={() => setColor("#539755")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="ten">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#61AF62" ? "checked" : ""}
                  onChange={() => setColor("#61AF62")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="eleven">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#70CF97" ? "checked" : ""}
                  onChange={() => setColor("#70CF97")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="twelve">
                <input
                  className="with-gap"
                  name="group1"
                  type="radio"
                  checked={color == "#9E65E0" ? "checked" : ""}
                  onChange={() => setColor("#9E65E0")}
                />
                <span></span>
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5444 2.24325L4.44212 9.3455L0.464844 5.39666L2.08418 3.80576L4.44212 6.13532L9.92508 0.652344L11.5444 2.24325Z"
                    fill="white"
                  />
                </svg>
              </label>
            </div>
          </div>
        </div>
        <div className="col xl6 s6 basic_top_row_update_v2">
          <div className="input_group">
            <div className="input_group_calender_widget_wr">
              <label className="input_group_calender_widget_wr_title">
                Description/Instructions
              </label>
              <div className="input_group_calender_widget_container">
                <GlobalCustomFieldGrouped
                  className={"send_module__quick__reply__global_custom__field"}
                  labelText={"Personalized"}
                  handleSelect={
                    handleChangePersonalizedForGivenEventDescription
                  }
                  omitGroup={["pype_default_contact"]}
                />
              </div>
            </div>
            <TextareaAutosize
              ref={givenEventDescriptionRef}
              className={"p-2"}
              maxRows={15}
              minRows={10}
              placeholder="Write a summary and provide details your invitee should know about the event."
              defaultValue={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          {props.calendarDetails.is_default ? (
            <div className="d-flex align-items-center">
              <p className="sync-email-action">
                Do you want to sync this widget with your upcoming events?
              </p>
              <CustomSwitch
                status={incomingEventSync}
                brandColor={"#f94f72"}
                onChange={() => {
                  setIncomingEventSync(incomingEventSync ? 0 : 1);
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {calendarData.length !== 0 && (
          <div
            className="col xl6 s6 basic_top_row_update_v2"
            style={{ minWidth: "200px" }}
          >
            <div className="input_group" style={{ marginTop: "10px" }}>
              <label style={{ display:"flex"}}>
                Booking Calendar
                <span >
                  <BootstrapTooltip title={"Select the calendar you would like your booked appointments added to."} arrow placement = "top"> 
                    <div style={{ cursor: "pointer", marginTop: "2px" , marginLeft:"3px" }}>
                      <HelpIcon />
                    </div>
                  </BootstrapTooltip>
                </span>
              </label>
              <BasicSelect
                fullWidth
                defaultText="Select Calender"
                value={selectedCalendar || ""}
                options={calendarData}
                onChange={(e) => {
                  setSelectedCalendar(e.target.value);
                }}
                mapping={{
                  label: "name",
                  value: "id",
                }}
              />
              {calendarError && (
                <small style={{ color: "red" }}>
                  Calendar field is required
                </small>
              )}
            </div>
          </div>
        )}

        <div className="col s12">
          <div class="collapsible_footer">
            <div className="row">
              <div className="col s12">
                <If condition={titleError}>
                  <p className={"text-red ml-3"}>Title is required</p>
                </If>
                <If condition={saveChanges}>
                  <p className={"text-red ml-3"}>
                    You have some unsaved changes, please save ***
                  </p>
                </If>
                <div className="collapsible_footer_extra_btn">
                  <button
                    type="button"
                    className="btn btn_danger d-inline-flex align-items-center btnSetHeight"
                  >
                    <span className="mr-2">
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="13" cy="13" r="13" fill="white"></circle>
                        <path
                          d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z"
                          fill="#FF264A"
                        ></path>
                      </svg>
                    </span>
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    type="button"
                    className="btn btn_info d-inline-flex align-items-center btnSetHeight accent--bg--color "
                  >
                    <span className="mr-2">
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                      </svg>
                    </span>
                    {btnTitle}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    calendarDetails: state.calendarWidgetReducer.calendarWidgetDetails,
  };
};

const CalendarWidgetBasicSection = connect(mapStateToProps, null)(App);

export default CalendarWidgetBasicSection;
