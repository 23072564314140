import React, {useEffect, useState} from 'react';
import './../assets/css/calendar-custom.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import {connect} from "react-redux";
import SlotModalComponent from "./SlotModalComponent";
import {updateCalendarWidgetEvents} from "../../../actions/calendarWidgetAction";
import {confirmAlert} from "react-confirm-alert";
import CustomConfirmAlertMod from "../../Common/confirm-alert-modified/CustomConfirmAlertMod";
import Utils from '../../../helpers/Utils';


let userTimeZone = Utils.getAccountData('userTimezoneRaw');
moment.tz.setDefault(userTimeZone);
const localizer = momentLocalizer(moment);

let formats = {
    dayFormat: "ddd MM/DD",
    dayHeaderFormat: "ddd YYYY/MM/DD",
};

const calendarOptions = {
    formats
};

const App = (props) => {

    const [schedules, setSchedules] = useState([]);
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [startDate, setStartDate]= useState(null);
    const [endDate, setEndDate]= useState(null);
    const [currentSlot, setCurrentSlot]= useState([]);
    const [currentSlotDate, setCurrentSlotDate]= useState(null);


const DateCell = ({
                      range,
                      value,
                      children
                  }) => {

    const now = new Date();
    now.setHours(0,0,0,0);

    return (
        <div className={ value < now ? "date-in-past" : "date-in-future" }>
            { children }
        </div>
    )

};

    useEffect(()=>{
        if(props.calendarWidgetScheduleDetails.id != undefined){

            let timeIntervalSlots = props.calendarWidgetScheduleDetails.schedules;

            if(timeIntervalSlots != null){
                for (let i = 0; i < timeIntervalSlots.length; i++) {
                    if(timeIntervalSlots[i] !== null && timeIntervalSlots[i].start !== null) {
                        let startDateSlot = moment(timeIntervalSlots[i].start, "YYYY-MM-DDTHH:mm:ssZ").toDate();

                        let endDateSlot = moment(timeIntervalSlots[i].end, "YYYY-MM-DDTHH:mm:ssZ").toDate();

                        let title = timeIntervalSlots[i].title
                        let newTitle = title.replace("0:00pm", "12:00pm");

                        timeIntervalSlots[i]['title'] = newTitle;
                        timeIntervalSlots[i]['start'] = startDateSlot;
                        timeIntervalSlots[i]['end'] = endDateSlot;
                        timeIntervalSlots[i]['id'] = i;
                    }
                }

                setSchedules(timeIntervalSlots);

            }



            setStartDate(props.calendarWidgetScheduleDetails.start_date);
            setEndDate(props.calendarWidgetScheduleDetails.end_date);
            props.updateCalendarWidgetEvents(props.calendarWidgetScheduleDetails.schedules);
        }
    }, [props.calendarWidgetScheduleDetails]);



    const modalToggle = () => {
        setShowModal(false);
    };

    const showAlert = (title, message) => {
        let alertTitle = title ? title : 'Cannot perform action';
        let alertMessage = message ? message : 'This calendar is not configured correctly yet!';
        CustomConfirmAlertMod({
            onSubmit : () => {
                return;
            },
            title: alertTitle,
            description: alertMessage,
            submitText: 'Ok, I understand'
        });
    };

    const onSlotChange = (slotinfo) => {
        if(moment().diff(moment(slotinfo.start),'days') > 0){
            showAlert('Sorry!', 'You will not able to change previous date');
            return false;
        }

        if(moment(slotinfo.start) > moment(endDate)){
            showAlert('Sorry!', 'You will not able to change out of calendar range date');
            return false;
        }

        if(moment() > moment(endDate)) {
            showAlert('Sorry!', 'You will not able to change previous date');
            return false;
        }else{
            var startDate = moment(slotinfo.start).format("YYYY-MM-DD");
            var currentSlot = [];

            schedules.filter(function(item){

                if(item != null && item.start != null){
                    var itemStart = moment(item.start.toLocaleString()).format("YYYY-MM-DD");

                    if(startDate.indexOf(itemStart) > -1 && startDate.length == itemStart.length){
                        currentSlot.push(item);
                    }
                }
            });

            if(moment(startDate).diff(moment(startDate),'days') < 0 ){
                showAlert('Sorry!', 'You will not able to change previous date');
                return false;
            }
            else{
                setShowModal(true);
                setCurrentSlot(currentSlot);
                setCurrentSlotDate(startDate);

            }
        }

    }

    const onEventClick = (event) => {
        if(moment().diff(moment(event.start),'days') > 0){
            showAlert('Sorry!', 'You will not able to change previous date');
            return false;
        }

        if(moment(event.start) > moment(endDate)) {
            showAlert('Sorry!', 'You will not able to change previous date');
            return false;
        }else{
            var eventStartDate = moment(event.start).format("YYYY-MM-DD");
            if(moment(eventStartDate).diff(moment(startDate),'days') < 0 ){
                showAlert('Sorry!', 'You will not able to change previous date');
                return false;
            }
            else{

                var eventCurrentSlot = [];

                schedules.filter(function(item){

                    if(item != null && item.start != null){
                        var itemStart = moment(item.start.toLocaleString()).format("YYYY-MM-DD");
                        if(eventStartDate.indexOf(itemStart) > -1 && eventStartDate.length == itemStart.length){
                            eventCurrentSlot.push(item);
                        }
                    }
                });

                setShowModal(true);
                setCurrentSlot(eventCurrentSlot);
                setCurrentSlotDate(eventStartDate);
            }
        }


    };

    const onSlotSubmit = (preInterval, slotNewInterval, unAvailable) => {

        var currentSlot = schedules;


        for(var i = 0; i < currentSlot.length; i++) {

            var slotStart = currentSlot[i].start;
            slotStart = moment(slotStart).format('Y-MM-DD');

            slotStart = slotStart.split(" ");
            slotStart = slotStart[0];
            if(slotStart.length < 10){
                var slotStartArr = slotStart.split("-");
                var slotStartMonth = (slotStartArr[1].length < 2) ? '0'+slotStartArr[1] : slotStartArr[1];
                var slotStartDay = (slotStartArr[2].length < 2) ? '0'+slotStartArr[2] : slotStartArr[2];
                slotStart = slotStartArr[0]+'-'+slotStartMonth+'-'+slotStartDay;
            }
            if(slotStart.length >= 10){
                slotStart = slotStart.substring(0,10);
            }
            if(slotStart == currentSlotDate) {
                currentSlot.splice(i, 1);
                i--;
            }
        }

        if(!unAvailable){

            for(var j = 0; j < preInterval.length; j++){
                currentSlot.push(preInterval[j]);
            }

            for(var k = 0; k < slotNewInterval.length; k++){

                currentSlot.push(slotNewInterval[k]);
            }
        }
        console.log(currentSlot);
        setSchedules(currentSlot);

        modalToggle();

    };

    const onMultipleDaySubmit = (preInterval, slotNewInterval, allDates, unAvailable) => {


        var currentSlot = schedules;

        var schedulesLength = currentSlot.length;

        for(var i = 0; i < currentSlot.length; i++) {

            var slotDateMD = moment(currentSlot[i].start).format("YYYY-M-D");
            var slotDateMMD = moment(currentSlot[i].start).format("YYYY-MM-D");
            var slotDateMDD = moment(currentSlot[i].start).format("YYYY-M-DD");
            var slotDateMMDD = moment(currentSlot[i].start).format("YYYY-MM-DD");

            if(allDates.includes(slotDateMD) || allDates.includes(slotDateMMD) || allDates.includes(slotDateMDD) || allDates.includes(slotDateMMDD)) {

                currentSlot.splice(i, 1);
                i--;
            }

        }

        if(!unAvailable) {

            for (var j = 0; j < preInterval.length; j++) {
                for(var l=0;l<preInterval[j].length;l++){
                    preInterval[j][l]['id'] = ++schedulesLength;
                    currentSlot.push(preInterval[j][l]);
                }
            }


            for (var k = 0; k < slotNewInterval.length; k++) {
                slotNewInterval[k]['id'] = ++schedulesLength;
                currentSlot.push(slotNewInterval[k]);
            }
        }
        setSchedules(()=>currentSlot);

        modalToggle();

    };



    return (
        <>

                <Calendar
                    {...calendarOptions}
                    popup
                    defaultDate={new Date()}
                    selectable={true}
                    localizer={localizer}
                    events={schedules}
                    startAccessor="start"
                    endAccessor="end"
                    view='month'
                    views={['month']}
                    showMultiDayTimes={true}
                    onSelectSlot={(slotinfo) => onSlotChange(slotinfo)}
                    onSelectEvent={event => onEventClick(event)}
                    components={{
                        month: {
                            dateHeader: ({ date, label }) => {
                                return (
                                    <p >{label}</p>
                                );
                            }
                        }
                    }}

                />


            <SlotModalComponent
                isOpen={showModal}
                hideModal={() => setShowModal(false)}
                currentSlot={currentSlot}
                currentSlotDate={currentSlotDate}
                startDate = {startDate}
                endDate = {endDate}
                onSlotSubmit={ (preInterval, slotNewInterval, unAvailable) => onSlotSubmit(preInterval, slotNewInterval, unAvailable) }
                onMultipleDaySubmit={ (preInterval, slotNewInterval, allDates, unAvailable) => onMultipleDaySubmit(preInterval, slotNewInterval, allDates, unAvailable) }
            />

        </>
    );

}

    const mapStateToProps = state => {
        return {
            calendarWidgetScheduleDetails : state.calendarWidgetReducer.calendarWidgetScheduleDetails
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            updateCalendarWidgetEvents: (params) => dispatch(updateCalendarWidgetEvents(params)),
        };
    }

    const ScheduleCalendar = connect(mapStateToProps, mapDispatchToProps)(App);

    export default ScheduleCalendar;
