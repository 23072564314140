import React, {useEffect, useState} from 'react';
import ActivityListItem from './ActivityListItem';
import {connect} from "react-redux";
import {
    fetchActivityCalendar,
    fetchTaskCount,
    storeCalendarUpdateActivityList,
    storeCalendarListSelectedPage,
    storeCalendarActivitySelectedTask, resetCalendarActivitySelectedTask
} from "../../../actions/calendarAction";
import Checkbox from "@material-ui/core/Checkbox";
import {confirmAlert} from "react-confirm-alert";
import {deleteBulkActivityForCalendar} from "../../../api/calendarApi";


const App = (props) => {

    const {itemPerSize,currentPage} = props
    const [activitiesList, setActivitiesList] = useState(props.activityCalendar);
    const [activeTaskId, setActiveTaskId] = useState(props.activeTaskId);
    const [activeTab, setActiveTab] = useState(props.activeTabActivityCalendar);

    const [openActivityModal, setOpenActivityModal] = React.useState(false);
    const [slotInfo, setSlotInfo] = React.useState([]);
    const [filter, setFilter] = useState(props.activeFilterId);
    const [isBulkSelected, setIsBulkSelected] = useState(false);

    useEffect(() => {
        setActivitiesList(props.activityCalendar);
    }, [props.activityCalendar]);

    const reloadActivityList = (itemIndex) => {
        if(props.calendarTotalPage === currentPage && props.activityCalendar.length>1){
           let activities = [...activitiesList];
           props.updateCalendarActivityList([ ...activities.slice(0, itemIndex), ...activities.slice(itemIndex+1) ]);
        }
        else if(props.calendarTotalPage === currentPage && props.activityCalendar.length===1){
            if(props.calendarTotalPage !==1){
                fetchPageData(currentPage-1);
                props.storeCalendarListSelectedPage(currentPage-1);
            }else{
                fetchPageData();
            }
        }
        else{
            fetchPageData();
        }
    }

    const fetchPageData = (pageData=currentPage) => {
        props.fetchActivityCalendar({
            'task_id' : activeTaskId,
            'activeTab' : props.activeTabActivityCalendar,
            'filter' : props.activeFilterId,
            'pageSize' : itemPerSize,
            'currentPage' : pageData,
        });

        props.fetchTaskCount({
            'task_id' : activeTaskId,
            'activeTab' : activeTab
        });
    }

    const fetchTask = () => {
        closeActivityModel();
        fetchPageData();
    }

    

    const onSlotChange = (slotinfo) => {
        // setSlotInfo(slotinfo);
        // setOpenActivityModal(true);
        window.openGlobalActivityModal({
            callBack:fetchTask,editData:slotinfo,from:'calendar'
        })
    }

    const closeActivityModel = () => {
        setOpenActivityModal(false);
    }

    const handleBulkSelect = () => {
        setIsBulkSelected(!isBulkSelected);
        let listData = [];
        activitiesList && activitiesList.map((item) => {
            if (item) {
                listData.push(item.id);
            }
        });

        props.storeCalendarActivitySelectedTask(listData);

        if (isBulkSelected && props.calendarActivitySelectedTask.length === activitiesList.length && activitiesList.length !== 0) {
            props.resetCalendarActivitySelectedTask();
        }
    };

    const handleDeleteBulkActivity = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to want to delete selected items?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteBulkActivityForCalendar({ activity_ids: props.calendarActivitySelectedTask })
                            .then(res => {
                                if(res.data.status) {
                                    let filteredData = activitiesList.filter((e) => !props.calendarActivitySelectedTask.includes(e.id));
                                    setActivitiesList(filteredData);
                                    props.resetCalendarActivitySelectedTask();
                                    setIsBulkSelected(false);
                                    // Resetting the current-page in api call and reducer
                                    fetchPageData(1);
                                    props.storeCalendarListSelectedPage(1);
                                    if(window.showNotification !== undefined){
                                        window.showNotification("SUCCESS", "Activity deleted successfully")
                                    }
                                }else{
                                    if(window.showNotification !== undefined){
                                        window.showNotification("ERROR", "Unable to delete")
                                    }
                                }
                            }).catch((err)=>{
                            window.showNotification("ERROR", "Unable to delete")
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    };


    const renderListData = () => {

        if(activitiesList.length === 0){
            return(
                <tr className="px-1">
                    <td colSpan={6}>
                        <p className="text-red" style={{textAlign: 'center'}}>No activity found!</p>
                    </td>
                </tr>
            )
        }

        let listData = [];
        activitiesList && activitiesList.map((item, index) => {
            if(item != undefined ){
                listData.push(
                    <ActivityListItem
                        item={item}
                        index={index}
                        key={`${item.id}${index}`}
                        reloadActivityList={ (itemIndex) => reloadActivityList(itemIndex)}
                        onSlotChange={onSlotChange}
                    />
                )
            }
        })
        return listData;
    }


    return (
        <div className="activity_list_table_container">
            {
                Array.isArray(props.calendarActivitySelectedTask) && props.calendarActivitySelectedTask.length > 0 && (
                    <div className="bulk__action__wrapper">
                        <span>{ props.calendarActivitySelectedTask.length } item selected!</span>
                        <button onClick={handleDeleteBulkActivity} className="delete_activity_btn d-inline-flex justify-content-center align-items-center waves-effect white-text py-2 px-2 radius-10">Delete Selected</button>
                    </div>
                )
            }
            {/* {openActivityModal &&
                <ActivityModal
                    openActivityModal={true}
                    setOpenActivityModal={() => setOpenActivityModal(false)}
                    showAll={true}
                    slotInfo={slotInfo}
                    fetchTask={() => fetchTask()}
                />
            } */}
           {
               props.isLoading &&  <span className='activity__loader'><p>Please Wait ...</p></span>
           }
            {
                !props.isLoading &&
                <table className="alt activities_items_table responsive-table">
                    <thead className="white py-1">
                        <tr className="px-1">
                            <td>
                                <label>
                                    {
                                        activitiesList.length !== 0 && (
                                            <Checkbox
                                                checked={props.calendarActivitySelectedTask.length === activitiesList.length && activitiesList.length !== 0}
                                                onChange={handleBulkSelect}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                indeterminate={props.calendarActivitySelectedTask.length < activitiesList.length && props.calendarActivitySelectedTask.length !== 0}
                                            />
                                        )
                                    }
                                    {/*<input value={id} type="checkbox" onChange={() => setChecked(!checked)} className="filled-in" />*/}
                                    <span
                                        className="d-flex justify-content-start align-items-center">
                                    </span>
                                </label>
                            </td>
                            <td>
                                <label>
                                    <span className="text-dark-blue">Title</span>
                                </label>
                            </td>
                            <td>
                                <h5 className="m-0 text-dark-blue">Due Date</h5>
                            </td>
                            <td>
                                <h5 className="m-0 text-dark-blue">Duration</h5>
                            </td>
                            <td>
                                <h5 className="m-0 text-dark-blue">Type</h5>
                            </td>
                            <td>
                                <h5 className="m-0 text-dark-blue">Contact Name</h5>
                            </td>
                            <td>
                                <h5 className="m-0 text-dark-blue">Action</h5>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderListData()}
                    </tbody>
                </table>
            }
        </div>
    );
};



const mapStateToProps = state => {
    return {
        activityCalendar : state.calendarReducer.activityCalendar,
        calendarTotalPage : state.calendarReducer.calendarTotalPage,
        activeTaskId : state.calendarReducer.activeTaskId,
        activeFilterId : state.calendarReducer.activeFilterId,
        activeTabActivityCalendar : state.calendarReducer.activeTabActivityCalendar,
        isLoading : state.calendarReducer.isLoading,
        calendarActivitySelectedTask: state.calendarReducer.calendarActivitySelectedTask
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchActivityCalendar : (params) => dispatch(fetchActivityCalendar(params)),
        fetchTaskCount : (params) => dispatch(fetchTaskCount(params)),
        updateCalendarActivityList : (params) => dispatch(storeCalendarUpdateActivityList(params)),
        storeCalendarListSelectedPage: (params) => dispatch(storeCalendarListSelectedPage(params)),
        storeCalendarActivitySelectedTask: (params) => dispatch(storeCalendarActivitySelectedTask(params)),
        resetCalendarActivitySelectedTask: () => dispatch(resetCalendarActivitySelectedTask())
    };
}

const ActivityList = connect(mapStateToProps, mapDispatchToProps)(App);

export default ActivityList;