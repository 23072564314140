import React, {useEffect, useState} from 'react';
import moment from 'moment';
import TimePicker from 'react-times';
import If from "if-else-react";
import SimpleReactValidator from 'simple-react-validator';
import NewInterval from "./NewInterval";
import PreviousInterval from "./PreviousInterval";
// import DayPicker, {DateUtils} from 'react-day-picker';
import { Calendar } from "react-multi-date-picker"
import 'react-day-picker/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {confirmAlert} from "react-confirm-alert";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const App = (props) => {
    const classes = useStyles();
    const [title, setSchedules] = useState('');
    const [currentSlot, setCurrentSlot] = useState(props.currentSlot);
    const [currentSlotDate, setCurrentSlotDate] = useState(props.currentSlotDate);
    const [later_hour, setLater_hour] = useState(12);
    const [later_min, setLater_min] = useState(0);
    const [time, setTime] = useState('12:00');
    const [clicked, setClicked] = useState(0);
    const [arrSlotTimeHour1, setArrSlotTimeHour1] = useState([]);
    const [arrSlotTimeHour2, setArrSlotTimeHour2] = useState([]);
    const [arrNewIntervalSlotTimeHour1, setArrNewIntervalSlotTimeHour1] = useState([]);
    const [arrSlotTimeMin1, setArrSlotTimeMin1] = useState([]);
    const [arrSlotTimeMin2, setArrSlotTimeMin2] = useState([]);
    const [arrNewIntervalSlotTimeMin1, setArrNewIntervalSlotTimeMin1] = useState([]);
    const [previous_number_length, setPrevious_number_length] = useState(0);
    const [newRow, setNewRow] = useState([]);
    const [slotNewInterval, setSlotNewInterval] = useState([]);
    const [showFirstForm, setShowFirstForm] = useState(true);
    const [multipleSetting, setMultipleSetting] = useState(2);
    const [currentDayName, setCurrentDayName] = useState(moment(props.currentSlotDate).format('dddd'));
    const [arrDayNames, setArrDayNames] = useState([moment(props.currentSlotDate).format('dddd')]);
    const [newTestState, setNewTestState] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    const [unavailable, setUnavailable] = useState(false);
    const [arrSlotsElement, setArrSlotsElement] = useState([]);
    const [generatedNewRow, setGeneratedNewRow] = useState([]);



   const validator = new SimpleReactValidator({
            validators: {
                selectContact: {
                    message: 'Select a contact'
                }
            }
        });

    const onTimeChange=(index, options)=> {

        var currentArrSlotTimeHour1 = arrSlotTimeHour1;

        currentArrSlotTimeHour1[index] = (options.meridiem == 'AM') ? options.hour : parseInt(options.hour) + 12;

        var currentArrSlotTimeMin1 = arrSlotTimeMin1;
        currentArrSlotTimeMin1[index] = options.minute;

        setArrSlotTimeHour1(()=>currentArrSlotTimeHour1);
        setArrSlotTimeMin1(()=>currentArrSlotTimeMin1);
    }

    useEffect(()=>{

    }, [arrSlotTimeHour1])

    const onTimeChange2=(index, options)=> {

        var slotTimeHour2 = arrSlotTimeHour2;
        slotTimeHour2[index] = (options.meridiem == 'AM') ? options.hour : parseInt(options.hour) + 12;

        var slotTimeMin2 = arrSlotTimeMin2;
        slotTimeMin2[index] = options.minute;

        setArrSlotTimeHour2(slotTimeHour2);
        setArrSlotTimeMin2(slotTimeMin2);


    }

    const onNewIntervalTimeChange=(index, options)=> {

        var arrslotTimeHour1 = [];
        var arrSlotTimeMin1 = [];

        if (clicked == 0) {
            arrslotTimeHour1 = arrNewIntervalSlotTimeHour1;
            arrslotTimeHour1[index] = options.hour;

                setArrNewIntervalSlotTimeHour1(arrslotTimeHour1);
        } else {
            arrSlotTimeMin1 = arrNewIntervalSlotTimeMin1;
            arrSlotTimeMin1[index] = options.minute;

            setArrNewIntervalSlotTimeMin1(arrSlotTimeMin1);
        }

        setClicked(!clicked);

    };

    const modalToggle = (event) => {
        event.preventDefault();
        props.hideModal();
        setPrevious_number_length(0);
        setNewRow([]);
    };

    const toggle = (event) => {
        event.preventDefault();
        props.hideModal();
        setPrevious_number_length(0);
        setNewRow([]);
    };

    const slotModalToggle = () => {
        props.hideModal();
        setPrevious_number_length(0);
        setNewRow([]);
    };

    const removeParent = (index) => {

        var newArr = [...currentSlot];
        var index = newArr.indexOf(index);
        if (index !== -1) {
            newArr.splice(index, 1);
            setCurrentSlot(newArr);
        }

    };

    useEffect(()=>{
        setCurrentSlotDate(props.currentSlotDate);
    }, [props.currentSlotDate])

    useEffect( ()=>{

        if(props.currentSlot[0] != undefined){
            if (props.isOpen) {
                setUnavailable(false);
            }

            setShowFirstForm(true);
            setMultipleSetting(2);
            setCurrentSlot(props.currentSlot);
            setCurrentSlotDate(props.currentSlot[0].start);
            setSelectedDays([props.currentSlot[0].start]);
            setCurrentDayName(moment(props.currentSlot[0].start).format('dddd'));
            setArrDayNames([moment(props.currentSlot[0].start).format('dddd')]);
            setStartDate(props.startDate);
            setEndDate(props.endDate);

            //generateArrState();
        }



    }, [props.currentSlot]);

    useEffect(()=>{

        if (props.currentSlot !== undefined && props.currentSlot.length !== 0) {
            var slotTime = [];
            var arrSlotsElement = [];
            var firstSlot = '';
            var scndSlot = '';
            var arrSlotTimeHour1 = [];
            var arrSlotTimeHour2 = [];
            var arrSlotTimeMin1 = [];
            var arrSlotTimeMin2 = [];
            var getMoon = '';

            props.currentSlot.map((row, index) => {

                slotTime = row.title.split('-');
                if(slotTime[0] == '12:00pm'){
                    slotTime[0] = '0:00pm';
                }
                firstSlot = slotTime[0].split(':');
                getMoon = firstSlot[1].slice(-2);

                if (getMoon === 'pm') {
                    arrSlotTimeHour1.push(parseInt(firstSlot[0]) + 12);
                } else {
                    arrSlotTimeHour1.push(firstSlot[0]);
                }

                arrSlotTimeMin1.push(firstSlot[1].slice(0, -2));

                scndSlot = slotTime[1].split(':');
                getMoon = scndSlot[1].slice(-2);
                if (getMoon === 'pm') {
                    arrSlotTimeHour2.push(parseInt(scndSlot[0]) + 12);
                } else {
                    arrSlotTimeHour2.push(scndSlot[0]);
                }
                arrSlotTimeMin2.push(scndSlot[1].slice(0, -2));

            });

            setArrSlotTimeHour1(arrSlotTimeHour1);
            setArrSlotTimeMin1(arrSlotTimeMin1);
            setArrSlotTimeHour2(arrSlotTimeHour2);
            setArrSlotTimeMin2(arrSlotTimeMin2);
        }


    }, [props.currentSlot]);

    const doUnavailable = (event) => {
        event.preventDefault();
        setPrevious_number_length(0);
        setCurrentSlot([]);
        setNewRow([]);
        setUnavailable(true);
    };

    const addNewIntervalStartTime = (slotStartHour, slotStartMin, slotEndHour, slotEndMin, rowId) => {

        let newSlot = [];

        let timeHour1 = parseInt(slotStartHour);
        let firstMoon = 'am';

        let timeHour2 = parseInt(slotEndHour);
        let lastMoon = 'am';

        if (parseInt(slotStartHour) >= 12) {
            timeHour1 = parseInt(slotStartHour) - 12;
            firstMoon = 'pm';
        }

        if (parseInt(slotEndHour) >= 12) {
            timeHour2 = parseInt(slotEndHour) - 12;
            lastMoon = 'pm';
        }

        let title = timeHour1 + ':' + slotStartMin + firstMoon + '-' + timeHour2 + ':' + slotEndMin + lastMoon;
        let newTitle = title.replace("0:00pm", "12:00pm");

        var obj = {
            title: newTitle,
            start: moment(currentSlotDate,'YYYY-MM-DD HH:mm:ss'),
            end: moment(currentSlotDate,'YYYY-MM-DD HH:mm:ss'),
        };

        newSlot = slotNewInterval;
        newSlot[rowId] = obj;

        setSlotNewInterval(newSlot);

    }

    const addPreviousIntervalStartTime = (slotStartHour, slotStartMin, slotEndHour, slotEndMin, index) => {

        var currentArrSlotTimeHour1 = arrSlotTimeHour1;

        currentArrSlotTimeHour1[index] = slotStartHour;

        var currentArrSlotTimeMin1 = arrSlotTimeMin1;
        currentArrSlotTimeMin1[index] = slotStartMin;

        setArrSlotTimeHour1(()=>currentArrSlotTimeHour1);
        setArrSlotTimeMin1(()=>currentArrSlotTimeMin1);

        var slotTimeHour2 = arrSlotTimeHour2;
        slotTimeHour2[index] = slotEndHour;

        var slotTimeMin2 = arrSlotTimeMin2;
        slotTimeMin2[index] = slotEndMin;

        setArrSlotTimeHour2(slotTimeHour2);
        setArrSlotTimeMin2(slotTimeMin2);

    }

    const previousLengthUpdate = () => {
        setPrevious_number_length(previous_number_length + 1);
    }

    const removePreInterval = (index) => {

        var newArr = currentSlot;
        var newStartHourArr = arrSlotTimeHour1;
        var newEndHourArr = arrSlotTimeHour2;
        var newStartMinArr = arrSlotTimeMin1;
        var newEndMinArr = arrSlotTimeMin2;
        if (index !== -1) {

            newArr.splice(index, 1);
            newStartHourArr.splice(index, 1);
            newEndHourArr.splice(index, 1);
            newStartMinArr.splice(index, 1);
            newEndMinArr.splice(index, 1);

            // setCurrentSlot(newArr);
            // setArrSlotTimeHour1(newArr);
            // setArrSlotTimeHour2(newArr);
            // setArrSlotTimeMin1(newArr);
            // setArrSlotTimeMin2(newArr);
        }


    }

    const removeNewInterval = (index) => {

        console.log('AM I IN LINE 325');

        var newArr = slotNewInterval;

        if (index !== -1) {
            newArr.splice(index, 1);
            setSlotNewInterval(newArr);
        }

    }

    const createNewInterval = (event) => {

        event.preventDefault();

        setUnavailable(false);

        let row = previous_number_length;

        var currentRow = [];

        currentRow = newRow;

        currentRow.push(
            <
                NewInterval
                addNewIntervalStartTime={(slotStartHour, slotStartMin, slotEndHour, slotEndMin, rowId) => addNewIntervalStartTime(slotStartHour, slotStartMin, slotEndHour, slotEndMin, rowId)}
                removeNewInterval={(index) => removeNewInterval(index)} newRow={row}
            />
        )
        setNewRow(currentRow);

        setPrevious_number_length(previous_number_length + 1);
    }

    const slotReady = () => {
        var arrSlotsElement = [];
        var timeHour1;
        var firstMoon;
        var timeHour2;
        var lastMoon;
        arrSlotTimeHour1.forEach(function (element, index) {

            timeHour1 = parseInt(arrSlotTimeHour1[index]);
            firstMoon = 'am';

            timeHour2 = parseInt(arrSlotTimeHour2[index]);
            lastMoon = 'am';

            if (parseInt(arrSlotTimeHour1[index]) >= 12) {
                timeHour1 = parseInt(arrSlotTimeHour1[index]) - 12;
                firstMoon = 'pm';
            }

            if (parseInt(arrSlotTimeHour2[index]) >= 12) {
                timeHour2 = parseInt(arrSlotTimeHour2[index]) - 12;
                lastMoon = 'pm';
            }

            let title = timeHour1 + ':' + arrSlotTimeMin1[index] + firstMoon + '-' + timeHour2 + ':' + arrSlotTimeMin2[index] + lastMoon;
            let newTitle = title.replace("0:00pm", "12:00pm");

            var obj = {
                title: newTitle,
                start: moment(currentSlotDate,'YYYY-MM-DD HH:mm:ss'),
                end: moment(currentSlotDate,'YYYY-MM-DD HH:mm:ss'),
            };

            arrSlotsElement[index] = obj;

        }.bind(this));


        var currentSlotNewInterval = slotNewInterval;

        setArrSlotsElement(arrSlotsElement);
        setPrevious_number_length(0)
        setNewRow([])
        setSlotNewInterval([]);

        props.onSlotSubmit(arrSlotsElement, currentSlotNewInterval, unavailable);

        slotModalToggle();
    }

    const handleSlotCurrentDaySubmit = (event) => {

        event.preventDefault();

        generateSlotForCurrentDay(currentSlotDate);

        slotModalToggle();

    };

    const generateSlotForCurrentDay = (currentSlotDate) => {

        var allDates = [];
        var arrAllSlotsElement = [];

        //For new interval
        var currentNewSlotInterval = slotNewInterval;
        var CurrentNewSlotIntervalForSelectedDay = [];
        var newArrSlotIntervalForSelectedDay = [];

        var currentStartDate = startDate;
        var currentEndDate = endDate;

        while (moment(currentStartDate, 'YYYY-M-D').format('dddd') != moment(currentSlotDate).format('dddd')) {

            currentStartDate = moment(currentStartDate, 'YYYY-M-D').add(1, 'days').format('YYYY-M-D');
        }

        while (moment(currentStartDate, 'YYYY-M-D').isSameOrBefore(moment(currentEndDate, 'YYYY-M-D'))) {
            var arrSlotsElement = [];
            var timeHour1;
            var firstMoon;
            var timeHour2;
            var lastMoon;

            arrSlotTimeHour1.forEach(function (element, index) {

                timeHour1 = parseInt(arrSlotTimeHour1[index]);
                firstMoon = 'am';

                timeHour2 = parseInt(arrSlotTimeHour2[index]);
                lastMoon = 'am';

                if (parseInt(arrSlotTimeHour1[index]) >= 12) {
                    timeHour1 = parseInt(arrSlotTimeHour1[index]) - 12;
                    firstMoon = 'pm';
                }

                if (parseInt(arrSlotTimeHour2[index]) >= 12) {
                    timeHour2 = parseInt(arrSlotTimeHour2[index]) - 12;
                    lastMoon = 'pm';
                }

                let formattedCurrentStartDate = moment(currentStartDate, 'YYYY-MM-DD HH:mm:ss');

                let title = timeHour1 + ':' + arrSlotTimeMin1[index] + firstMoon + '-' + timeHour2 + ':' + arrSlotTimeMin2[index] + lastMoon;
                let newTitle = title.replace("0:00pm", "12:00pm");

                var obj = {
                    title: newTitle,
                    start: moment(formattedCurrentStartDate),
                    end: moment(formattedCurrentStartDate),
                    id:index
                };

                arrSlotsElement[index] = obj;
            }.bind(this));

            allDates.push(currentStartDate);
            arrAllSlotsElement.push(arrSlotsElement);

            currentStartDate = moment(currentStartDate, 'YYYY-M-D').add(7, 'days').format('YYYY-M-D');

        }

        //FOR NEW INTERVAL

        currentNewSlotInterval.map((element, index) => {

            allDates.map((elementNew, indexNew) => {

                let formattedElementNew = moment(elementNew, 'YYYY-MM-DD HH:mm:ss');
                let title = element.title;
                let newTitle = title.replace("0:00pm", "12:00pm");

                var newObj = {
                    title: newTitle,
                    start: moment(formattedElementNew),
                    end: moment(formattedElementNew),
                    id: indexNew
                };
                newArrSlotIntervalForSelectedDay[indexNew] = newObj;
            })

        });

        setArrSlotsElement([]);
        setSlotNewInterval([]);
        setPrevious_number_length(0);
        setNewRow([]);

       props.onMultipleDaySubmit(arrAllSlotsElement, newArrSlotIntervalForSelectedDay, allDates, unavailable);
    }

    const applyToMultiple = (event) => {
        event.preventDefault();
        setShowFirstForm(false);
    }

    const applyToSingle = () => {
        setShowFirstForm(true);
    }

    const handleSlotSubmit = (event) => {
        event.preventDefault();
        slotReady();
    }

    const onDateChanged = () => {
        setMultipleSetting(1);
    }

    const onDayChanged = () => {
        setMultipleSetting(2);
    }

    const applyRepeatingDays = (event) => {

        event.preventDefault();
        var allDates = [];
        var arrAllSlotsElement = [];

        //For new interval
        var newSlotInterval = slotNewInterval;
        var newSlotIntervalForSelectedDay = [];
        var newArrSlotIntervalForSelectedDay = [];

        var startDate = props.startDate;
        startDate = moment(startDate, 'YYYY-M-D').subtract(1, 'days').format('YYYY-M-D');

        var endDate = props.endDate;

        arrDayNames.map(function (each) {

            while (moment(startDate, 'YYYY-M-D').format('dddd') != each) {

                startDate = moment(startDate, 'YYYY-M-D').add(1, 'days').format('YYYY-M-D');


            }
            while (moment(startDate, 'YYYY-M-D').isSameOrBefore(moment(endDate, 'YYYY-M-D'))) {

                startDate = moment(startDate, 'YYYY-M-D');

                var arrSlotsElement = [];
                var timeHour1;
                var firstMoon;
                var timeHour2;
                var lastMoon;

                arrSlotTimeHour1.forEach(function (element, index) {

                    timeHour1 = parseInt(arrSlotTimeHour1[index]);
                    firstMoon = 'am';

                    timeHour2 = parseInt(arrSlotTimeHour2[index]);
                    lastMoon = 'am';

                    if (parseInt(arrSlotTimeHour1[index]) >= 12) {
                        timeHour1 = parseInt(arrSlotTimeHour1[index]) - 12;
                        firstMoon = 'pm';
                    }

                    if (parseInt(arrSlotTimeHour2[index]) >= 12) {
                        timeHour2 = parseInt(arrSlotTimeHour2[index]) - 12;
                        lastMoon = 'pm';
                    }

                    let title = timeHour1 + ':' + arrSlotTimeMin1[index] + firstMoon + '-' + timeHour2 + ':' + arrSlotTimeMin2[index] + lastMoon;
                    let newTitle = title.replace("0:00pm", "12:00pm");

                    var obj = {
                        title: newTitle,
                        start: moment(startDate, 'YYYY-MM-DD HH:mm:ss'),
                        end: moment(startDate, 'YYYY-MM-DD HH:mm:ss'),
                        id:index
                    };


                    arrSlotsElement[index] = obj

                }.bind(this), arrAllSlotsElement.push(arrSlotsElement));

                allDates.push(startDate.format('YYYY-M-D'));

                startDate = moment(startDate, 'YYYY-M-D').add(7, 'days').format('YYYY-M-D');

            }

            newSlotInterval.map((element, index) => {

                let title = element.title;
                let newTitle = title.replace("0:00pm", "12:00pm");

                allDates.map((elementNew, indexNew) => {
                    var newObj = {
                        title: newTitle,
                        start: moment(elementNew, 'YYYY-MM-DD HH:mm:ss'),
                        end: moment(elementNew, 'YYYY-MM-DD HH:mm:ss'),
                        id: indexNew
                    };
                    newSlotIntervalForSelectedDay[indexNew] = newObj;
                })

            });


            setArrSlotsElement(arrAllSlotsElement);
            setSlotNewInterval([]);
            setPrevious_number_length(0);
            setNewRow([]);


            props.onMultipleDaySubmit(arrAllSlotsElement, newSlotIntervalForSelectedDay, allDates, unavailable);

            startDate = props.startDate;

        }.bind(this));

        slotModalToggle();

    }

    const applySelectedDates = (event) => {

        event.preventDefault();

        var allDates = [];
        var arrAllSlotsElement = [];
        //For new interval
        var newSlotInterval = slotNewInterval;
        var newSlotIntervalForSelectedDay = [];
        var newArrSlotIntervalForSelectedDay = [];

        selectedDays.map(function (each) {


           var eachDay = each.year+'-'+each.month.number+'-'+each.day;

            var arrSlotsElement = [];
            var timeHour1;
            var firstMoon;
            var timeHour2;
            var lastMoon;

            arrSlotTimeHour1.forEach(function (element, index) {

                timeHour1 = parseInt(arrSlotTimeHour1[index]);
                firstMoon = 'am';

                timeHour2 = parseInt(arrSlotTimeHour2[index]);
               lastMoon = 'am';

                if (parseInt(arrSlotTimeHour1[index]) >= 12) {
                    timeHour1 = parseInt(arrSlotTimeHour1[index]) - 12;
                    firstMoon = 'pm';
                }

                if (parseInt(arrSlotTimeHour2[index]) >= 12) {
                    timeHour2 = parseInt(arrSlotTimeHour2[index]) - 12;
                    lastMoon = 'pm';
                }

                let title = timeHour1 + ':' + arrSlotTimeMin1[index] + firstMoon + '-' + timeHour2 + ':' + arrSlotTimeMin2[index] + lastMoon;
                let newTitle = title.replace("0:00pm", "12:00pm");

                var obj = {
                    title: newTitle,
                    start: moment(eachDay, 'YYYY-MM-DD HH:mm:ss'),
                    end: moment(eachDay, 'YYYY-MM-DD HH:mm:ss')
                };
                arrSlotsElement[index] = obj;
            }.bind(this), arrAllSlotsElement.push(arrSlotsElement));

            allDates.push(eachDay);

            newSlotInterval.map((element, index) => {

                allDates.map((elementNew, indexNew) => {
                    let title = element.title;
                    let newTitle = title.replace("0.00pm", "12:00pm");
                    var newObj = {
                        title: newTitle,
                        start: moment(elementNew, 'YYYY-MM-DD HH:mm:ss'),
                        end: moment(elementNew, 'YYYY-MM-DD HH:mm:ss'),
                    };
                    newSlotIntervalForSelectedDay[indexNew] = newObj;
                });
            })

            setArrSlotsElement(arrAllSlotsElement);
            setSlotNewInterval(newSlotIntervalForSelectedDay);
            setPrevious_number_length(0);
            setNewRow([]);

            props.onMultipleDaySubmit(arrAllSlotsElement, newSlotIntervalForSelectedDay, allDates, unavailable);

        })

        slotModalToggle();
    }

    const handleDayCheck = (e, dayName) => {
        let isChecked = e.target.checked;

        var newArr = arrDayNames;

        if (isChecked) {
            newArr.push(dayName);


        } else {
            var index = newArr.indexOf(dayName);

            if (index != -1) {

                newArr.splice(index, 1);
            }
        }

        setArrDayNames(newArr);
    }



        let newRowList = [];

        if (currentSlot != []) {

            currentSlot.map((row, index) => {

                newRowList.push(
                    <PreviousInterval
                        hour1={arrSlotTimeHour1[index]}
                        min1={arrSlotTimeMin1[index]}
                        hour2={arrSlotTimeHour2[index]}
                        min2={arrSlotTimeMin2[index]}
                        rowId={index}

                        addPreviousIntervalStartTime={(slotStartHour, slotStartMin, slotEndHour, slotEndMin) => addPreviousIntervalStartTime(slotStartHour, slotStartMin, slotEndHour, slotEndMin, index)}

                        removePreInterval={(index) => removePreInterval(index)} newRow={row}

                    />
                )
            });
        }

        var generatedRows=[];
        generatedRows = newRow.map(function (row, index) {
            return <div key={index}>
                {row}
            </div>
        });

        validator.purgeFields();


        return (
            <React.Fragment>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={props.isOpen}
                    onClose={props.hideModal}
                    closeAfterTranstion
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={props.isOpen}>
                        <form className="edit-availability-tab-modal">
                            <If condition={showFirstForm}>

                                <div id="create_calendar" className="calendar_widget_time_interval_modal modal d-block  awesome__scroll_bar">
                                    <div className="modal-content">
                                        <div className="header">
                                            <h5>Edit Availability</h5>
                                        </div>
                                        <div className="modal_article border-0">
                                            <If condition={!unavailable}>
                                                <div className="single-slot">
                                                    <div className="row slot-header-border pb-2">
                                                        <div className="col s4">
                                                            <div className="form-group m-form__group">
                                                                <label>
                                                                    From
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col s1 text-center ">
                                                            <div className="form-group m-form__group">
                                                                <label>
                                                                    -
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col s4  ">
                                                            <div className="form-group m-form__group">
                                                                <label>
                                                                    To
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col s3 text-center">
                                                            <div className="form-group m-form__group">
                                                                <label>
                                                                    Action
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {newRowList}
                                                    {generatedRows}
                                                </div>
                                            </If>
                                            <If condition={unavailable}>
                                                <div className="row py-2">
                                                    <div className="col-6 text-center mx-auto">
                                                        <div className="m-portlet">
                                                            <div className="m-portlet__body py-2">
                                                                <h5 className={"text-muted py-3"}>Unavailable</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </If>
                                            <div className="row text-left py-2 tab-modal-input">
                                                <div className="col s6">
                                                    <div className="form-group m-form__group mb-3">
                                                        <a onClick={(event)=>createNewInterval(event)}
                                                           className="btn btn-outline-success m-btn m-btn--icon m-btn--outline-2x d-block">
                                                            <span>
                                                                <i className="fa fa-edit"></i>
                                                                <span>
                                                                    New interval
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                   
                                                </div>
                                                <div className="col s6">
                                                      <div className="form-group m-form__group">
                                                            <a onClick={(event) => doUnavailable(event)}
                                                            className="btn btn-outline-success m-btn m-btn--icon m-btn--outline-2x d-block">
                                                                <span>
                                                                    <span>
                                                                        I am unavailable
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                </div>
 
                                            </div>
                                            <div className="row mt-4 d-flex tab-modal-input align-items-center justify-content-center">
                                                   <div className="col s6 m-0">
                                                        <button onClick={(event) => handleSlotSubmit(event)} type="submit" value="Submit"
                                                                color="primary" className="btn btn-primary mb-3 btn-block w-100">Apply
                                                            to {moment(currentSlotDate).format('MMMM Do')} only</button>
                                                            <button onClick={(event)=>handleSlotCurrentDaySubmit(event)} type="submit" value="Submit"
                                                                color="primary" className="btn btn-primary mr-2 btn-block w-100">Apply to
                                                            all {moment(currentSlotDate).format('dddd')}</button>
                                                    </div>
                                            </div>
                        
                                            <div className="massage_send_close_button massage_send_close_button_daterange d-flex justify-content-center align-items-center mt-5">
                                                <a onClick={(event)=>applyToMultiple(event)} href="javascript:void(0)" className="d-flex align-items-center mr-3 accent--bg--color">
                                                    <span className="mr-2">
                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.5257 19.9998C15.6541 19.9998 14.4297 19.6846 12.5963 18.6605C10.3668 17.4105 8.64237 16.2565 6.42495 14.0453C4.28701 11.9091 3.24662 10.5261 1.79052 7.87691C0.145544 4.88581 0.425958 3.31793 0.739415 2.64783C1.11271 1.84693 1.66371 1.36791 2.37591 0.892454C2.78043 0.627466 3.20852 0.400313 3.65474 0.213874C3.6994 0.194677 3.74092 0.176374 3.77798 0.159856C3.99901 0.0603008 4.3339 -0.0901474 4.75809 0.0705688C5.04119 0.17682 5.29392 0.394233 5.68953 0.784863C6.50086 1.58487 7.60957 3.36659 8.01858 4.2416C8.29319 4.83134 8.47493 5.22063 8.47537 5.65724C8.47537 6.16841 8.21818 6.56261 7.90606 6.98806C7.84756 7.06797 7.78952 7.14431 7.73325 7.21842C7.39345 7.66486 7.31888 7.79388 7.368 8.02424C7.46758 8.48719 8.21014 9.86533 9.43048 11.0828C10.6508 12.3002 11.9895 12.9957 12.4543 13.0948C12.6945 13.1462 12.8263 13.0685 13.2871 12.7167C13.3532 12.6663 13.421 12.614 13.492 12.5618C13.968 12.2078 14.344 11.9573 14.8432 11.9573H14.8459C15.2803 11.9573 15.6523 12.1457 16.2685 12.4564C17.0722 12.8618 18.9079 13.956 19.7129 14.7681C20.1045 15.1627 20.3229 15.4145 20.4296 15.6971C20.5903 16.1225 20.439 16.456 20.3403 16.6793C20.3238 16.7163 20.3055 16.7569 20.2863 16.802C20.0983 17.2474 19.8698 17.6745 19.6035 18.0779C19.1289 18.7878 18.648 19.3373 17.8451 19.711C17.4329 19.9059 16.9817 20.0047 16.5257 19.9998Z" fill="white"/>
                                                        </svg>
                                                    </span>Or Apply to multiple
                                                </a>

                                                <a onClick={(event)=>modalToggle(event)} href="javascript:void(0)" className="close_btn alt">
                                                    <span>
                                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                                                    </span> Close
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <If condition={!showFirstForm}>
                                <div id="create_calendar" className="create_calendar_widget modal d-block tab-calendar-modal">
                                    <div className="modal-content">
                                        <div className="header">
                                            <h5>Edit Availability</h5>
                                        </div>
                                        <div className="modal_article">
                                            <div className="title pb-2">
                                            <button onClick={()=>setShowFirstForm(true)} className="modal-back custom-pointer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.875 9C0.875 13.4871 4.51289 17.125 9 17.125C13.4871 17.125 17.125 13.4871 17.125 9C17.125 4.51289 13.4871 0.875 9 0.875C4.51289 0.875 0.875 4.51289 0.875 9ZM9.625 5.87148L7.14141 8.375H12.9844V9.625H7.14141L9.625 12.1285L8.73828 13.009L4.76016 9L8.73828 4.99102L9.625 5.87148Z"
                                                        fill="#3C7EF3"/>
                                                </svg>
                                                <span>Back</span>
                                            </button>
                                            </div>
                                            <div className="m-portlet">
                                                <div className="m-portlet__body py-3">
                                                    <div className="row  d-flex mb-0">
                                                        <div className="col  text-left">
                                                            <label className="m-radio m-radio--state-brand d-flex align-items-center">
                                                                <input type="radio" name="example_2" value={1}
                                                                       checked={multipleSetting === 1}
                                                                       onChange={onDateChanged}/>
                                                                Specific Dates
                                                                <span className="ml-2"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row d-flex mb-0">
                                                        <div className="col  text-left">
                                                            <label className="m-radio m-radio--state-brand d-flex align-items-center">
                                                                <input type="radio" name="example_2"
                                                                       value={2}
                                                                       checked={multipleSetting === 2}
                                                                       onChange={onDayChanged}/>
                                                                Repeating days of the week
                                                                <span></span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <If condition={multipleSetting === 2}>
                                                <div className="m-checkbox-list">
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                    
                                                        <If condition={currentDayName == 'Sunday'}>
                                                            <input type="checkbox" value={'Sunday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Sunday'}>
                                                            <input type="checkbox" value={'Sunday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Sunday')}/>
                                                        </If>
                                                       
                                                        <span></span>
                                                        Sundays
                                                    </label>
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                        <If condition={currentDayName == 'Monday'}>
                                                            <input type="checkbox" value={'Monday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Monday'}>
                                                            <input type="checkbox" value={'Monday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Monday')}/>
                                                        </If>
                                                       
                                                        <span></span>
                                                        Mondays
                                                    </label>
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                        <If condition={currentDayName == 'Tuesday'}>
                                                            <input type="checkbox" value={'Tuesday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Tuesday'}>
                                                            <input type="checkbox" value={'Tuesday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Tuesday')}/>
                                                        </If>
                                                        
                                                        <span></span>
                                                        Tuesdays
                                                    </label>
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                        <If condition={currentDayName == 'Wednesday'}>
                                                            <input type="checkbox" value={'Wednesday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Wednesday'}>
                                                            <input type="checkbox" value={'Wednesday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Wednesday')}/>
                                                        </If>
                                                        
                                                        <span></span>
                                                        Wednesdays
                                                    </label>
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                        <If condition={currentDayName == 'Thursday'}>
                                                            <input type="checkbox" value={'Thursday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Thursday'}>
                                                            <input type="checkbox" value={'Thursday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Thursday')}/>
                                                        </If>
                                                        
                                                        <span></span>
                                                        Thursdays
                                                    </label>
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                        <If condition={currentDayName == 'Friday'}>
                                                            <input type="checkbox" value={'Friday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Friday'}>
                                                            <input type="checkbox" value={'Friday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Friday')}/>
                                                        </If>
                                                        
                                                        <span></span>
                                                        Fridays
                                                    </label>
                                                    <label className="m-checkbox m-checkbox--state-brand">
                                                        <If condition={currentDayName == 'Saturday'}>
                                                            <input type="checkbox" value={'Saturday'} disabled checked/>
                                                        </If>
                                                        <If condition={currentDayName != 'Saturday'}>
                                                            <input type="checkbox" value={'Saturday'}
                                                                   onChange={(e) => handleDayCheck(e, 'Saturday')}/>
                                                        </If>
                                                        
                                                        <span></span>
                                                        Saturdays
                                                    </label>
                                                </div>
                                                <div className="massage_send_close_button massage_send_close_button_daterange d-flex justify-content-center align-items-center">
                                                    <a onClick={(event)=>applyRepeatingDays(event)} href="javascript:void(0)" className="send_massage">
                                                        <span>
                                                             <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16.5257 19.9998C15.6541 19.9998 14.4297 19.6846 12.5963 18.6605C10.3668 17.4105 8.64237 16.2565 6.42495 14.0453C4.28701 11.9091 3.24662 10.5261 1.79052 7.87691C0.145544 4.88581 0.425958 3.31793 0.739415 2.64783C1.11271 1.84693 1.66371 1.36791 2.37591 0.892454C2.78043 0.627466 3.20852 0.400313 3.65474 0.213874C3.6994 0.194677 3.74092 0.176374 3.77798 0.159856C3.99901 0.0603008 4.3339 -0.0901474 4.75809 0.0705688C5.04119 0.17682 5.29392 0.394233 5.68953 0.784863C6.50086 1.58487 7.60957 3.36659 8.01858 4.2416C8.29319 4.83134 8.47493 5.22063 8.47537 5.65724C8.47537 6.16841 8.21818 6.56261 7.90606 6.98806C7.84756 7.06797 7.78952 7.14431 7.73325 7.21842C7.39345 7.66486 7.31888 7.79388 7.368 8.02424C7.46758 8.48719 8.21014 9.86533 9.43048 11.0828C10.6508 12.3002 11.9895 12.9957 12.4543 13.0948C12.6945 13.1462 12.8263 13.0685 13.2871 12.7167C13.3532 12.6663 13.421 12.614 13.492 12.5618C13.968 12.2078 14.344 11.9573 14.8432 11.9573H14.8459C15.2803 11.9573 15.6523 12.1457 16.2685 12.4564C17.0722 12.8618 18.9079 13.956 19.7129 14.7681C20.1045 15.1627 20.3229 15.4145 20.4296 15.6971C20.5903 16.1225 20.439 16.456 20.3403 16.6793C20.3238 16.7163 20.3055 16.7569 20.2863 16.802C20.0983 17.2474 19.8698 17.6745 19.6035 18.0779C19.1289 18.7878 18.648 19.3373 17.8451 19.711C17.4329 19.9059 16.9817 20.0047 16.5257 19.9998Z" fill="white"/>
                                                            </svg>
                                                        </span>Apply To Repeating Days
                                                    </a>
                                                    <a onClick={(event)=>toggle(event)} href="javascript:void(0)" className="close_btn alt">
                                                        <span>
                                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                                                        </span> Close
                                                    </a>
                                                </div>
                                            </If>
                                            <If condition={multipleSetting === 1}>
                                        <div className="row">
                                            <div className="col-6 mx-auto">
                                                <Calendar
                                                    value={selectedDays}
                                                    onChange={setSelectedDays}
                                                />
                                            </div>
                                        </div>
                                        <div className="massage_send_close_button massage_send_close_button_daterange d-flex justify-content-center align-items-center">
                                            <a onClick={(event)=>applySelectedDates(event)} href="javascript:void(0)" className="send_massage">
                                            <span>
                                                 <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.5257 19.9998C15.6541 19.9998 14.4297 19.6846 12.5963 18.6605C10.3668 17.4105 8.64237 16.2565 6.42495 14.0453C4.28701 11.9091 3.24662 10.5261 1.79052 7.87691C0.145544 4.88581 0.425958 3.31793 0.739415 2.64783C1.11271 1.84693 1.66371 1.36791 2.37591 0.892454C2.78043 0.627466 3.20852 0.400313 3.65474 0.213874C3.6994 0.194677 3.74092 0.176374 3.77798 0.159856C3.99901 0.0603008 4.3339 -0.0901474 4.75809 0.0705688C5.04119 0.17682 5.29392 0.394233 5.68953 0.784863C6.50086 1.58487 7.60957 3.36659 8.01858 4.2416C8.29319 4.83134 8.47493 5.22063 8.47537 5.65724C8.47537 6.16841 8.21818 6.56261 7.90606 6.98806C7.84756 7.06797 7.78952 7.14431 7.73325 7.21842C7.39345 7.66486 7.31888 7.79388 7.368 8.02424C7.46758 8.48719 8.21014 9.86533 9.43048 11.0828C10.6508 12.3002 11.9895 12.9957 12.4543 13.0948C12.6945 13.1462 12.8263 13.0685 13.2871 12.7167C13.3532 12.6663 13.421 12.614 13.492 12.5618C13.968 12.2078 14.344 11.9573 14.8432 11.9573H14.8459C15.2803 11.9573 15.6523 12.1457 16.2685 12.4564C17.0722 12.8618 18.9079 13.956 19.7129 14.7681C20.1045 15.1627 20.3229 15.4145 20.4296 15.6971C20.5903 16.1225 20.439 16.456 20.3403 16.6793C20.3238 16.7163 20.3055 16.7569 20.2863 16.802C20.0983 17.2474 19.8698 17.6745 19.6035 18.0779C19.1289 18.7878 18.648 19.3373 17.8451 19.711C17.4329 19.9059 16.9817 20.0047 16.5257 19.9998Z" fill="#3C7EF3"/>
                                                </svg>
                                            </span>Apply to Selected Dates
                                                            </a>
                                            <a onClick={(event)=>toggle(event)} href="javascript:void(0)" className="close_btn alt">
                                            <span>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white"></circle><path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"></path></svg>
                                            </span> Close
                                            </a>
                                        </div>
                                    </If>
                                        </div>
                                    </div>
                                </div>
                            </If>
                        </form>
                    </Fade>
                </Modal>
            </React.Fragment>
        );

}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
}

const SlotModalComponent2 = connect(mapStateToProps, mapDispatchToProps)(App);

export default SlotModalComponent2;
