import React, {useEffect,useRef} from 'react';
import { useState } from 'react';
import './ActivityList.css';
import moment from 'moment';
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {deleteActivityForCalendar, getUserGroup, markAsDone} from "../../../api/calendarApi";
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import { BottomActionIcon } from './BottomActionIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Utils from '../../../helpers/Utils';
import {useDispatch, useSelector} from "react-redux";
import {storeCalendarActivitySelectedTask} from "../../../actions/calendarAction";

const TASK_TO_ICON = {
    call : 'phone',
    meeting : 'groups',
    task : 'task',
    deadline : 'schedule',
    email : 'mail',
    followup : 'group_add'
}

const direct_mail_options = {
    POSTCARD: 6,
    GREETING_CARD: 7,
    GIFT: 8,
    LETTER: 9,
}

const ActivityListItem = ({ item, reloadActivityList, onSlotChange ,index}) => {
    const {calendarActivitySelectedTask} = useSelector((state)=>state.calendarReducer);
    const dispatch = useDispatch();
    const { id, title, taskTitle, start, taskDuration, name,  taskDate, taskTime, task_status, contactId, email, contactNumber, taskRawTime} = item;
    const [checked, setChecked] = useState(task_status === 2);
    const [showEdit, setShowEdit] = useState(false)
    const ref = useRef()

    const [mailOptionVisible, setMailOptionVisible] = useState(false);

    const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true :false
    const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true :false

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if(event.target.checked){
            dispatch(storeCalendarActivitySelectedTask([ ...calendarActivitySelectedTask, id ]));
        }else{
            let selectedItem = calendarActivitySelectedTask.filter((item)=>item !== id);
            dispatch(storeCalendarActivitySelectedTask(selectedItem));
        }
    };

    const doneActivity = () => {
        setChecked(false)
        markAsDone({
            id : id
        }).then(res => {
            reloadActivityList(index);
        });

    }

    const onHoverActivity = () => {
        setShowEdit(true)
    }

    const onBlurActivity = () => {
        setShowEdit(false)
    }

    const handleMarkAsDoneActivity = () => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Do you want to mark this as done?',
            closeOnEscape: true,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        markAsDone({id: id })
                            .then(res => {
                                if(res.data.status) {
                                    reloadActivityList(index);
                                    if(window.showNotification !== undefined){
                                        window.showNotification("SUCCESS", "Activity mark as done.")
                                    }
                                }else{
                                    if(window.showNotification !== undefined){
                                        window.showNotification("ERROR", "Unable to take action")
                                    }
                                }
                            }).catch((err)=>{
                            window.showNotification("ERROR", "Unable to take action")
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ],

        });
    };

    const handleDeleteActivity = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to want to delete selected item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteActivityForCalendar({ activity_id: id })
                            .then(res => {
                                if(res.data.status) {
                                    reloadActivityList(index);
                                    let selectedItem = calendarActivitySelectedTask.filter((item)=>item !== id);
                                    dispatch(storeCalendarActivitySelectedTask(selectedItem));
                                    if(window.showNotification !== undefined){
                                        window.showNotification("SUCCESS", "Activity deleted successfully")
                                    }
                                }else{
                                    if(window.showNotification !== undefined){
                                        window.showNotification("ERROR", "Unable to delete")
                                    }
                                }
                            }).catch((err)=>{
                            window.showNotification("ERROR", "Unable to delete")
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }


    const renderAction = () => {

        if(contactId !== undefined && contactId != null && contactId !==''){
            return (
                <div className="option___container">
                    <div className="action___option___each">
                        <span className="icon___inbox_action" title="Details" onClick={() => handleActionOptions('info')}>{BottomActionIcon['contactDetailsIcon']}</span>
                    </div>
                    {contactNumber !== "" &&
                    <div className="action___option___each">
                        <span className="icon___inbox_action" title="Text" onClick={() => handleActionOptions('sms')}>{BottomActionIcon['messageIcon']}</span>
                    </div>
                    }
                    {email !== "" &&
                    <div className="action___option___each">
                        <span className="icon___inbox_action" title="Email" onClick={() => handleActionOptions('email')}>{BottomActionIcon['emailIconInListForSingle']}</span>
                    </div>
                    }
                    {email !== "" &&
                    <div className="action___option___each">
                        <span className="icon___inbox_action" title="Video Email" onClick={() => handleActionOptions('video')}>{BottomActionIcon['videoIcon']}</span>
                    </div>
                    }
                    {contactNumber !== "" &&
                    <div className="action___option___each">
                        <span className="icon___inbox_action" title="Voice" onClick={() => handleActionOptions('voice')}>{BottomActionIcon['speakerIcon']}</span>
                    </div>
                    }
                    {contactNumber !== "" &&
                    <div className="action___option___each">
                        <span className="icon___inbox_action" title="Call" onClick={() => handleActionOptions('call')}>{BottomActionIcon['callIconInList']}</span>
                    </div>
                    }
                    {/* {(Utils.getAccountData('agencyId') === 1) && */}
                    {(thanksIoActive || zenDirectActive) &&
                    <div className={`action___option___each`} onClick={(e) => setMailOptionVisible(e.currentTarget)}>
                        <span className="icon___inbox_action" title="Direct Mail">{BottomActionIcon['directMailiconInContactDetails']}</span>
                    </div>
                    }
                </div>
            )
        }
        else
            return null;
        
    }

    const handleActionOptions = (tab) =>{
        if(tab === 'email'){
            if(window.handleGlobalEmailSendModal !== undefined){
                window.handleGlobalEmailSendModal({
                    open: true,
                    onClose:() => {},
                    id:contactId,
                    from:"calendar-activity",
                    primaryEmail: email,
                    fetchContactDetails:false
                });
            }
        }
        else if(tab === 'sms'){
            if(window.handleGlobalSmsSendModal !== undefined){
                window.handleGlobalSmsSendModal({
                    open: true,
                    onClose:() => {},
                    id:contactId,
                    from:"calendar-activity",
                    fetchContactDetails:false,
                    // contactInfo: props.selectedContact
                })
            }
        }
        else if(tab === 'voice'){
            if(window.handleGlobalVoiceSendModal !== undefined){
                window.handleGlobalVoiceSendModal({
                    open: true,
                    onClose:() => {},
                    id:contactId,
                    from:"calendar-activity",
                    fetchContactDetails:false
                })
            }
        }
        else if(tab === 'video'){
            if(window.openVideoEmailSend !== undefined){
                window.openVideoEmailSend({
                    open: true,
                    contactId: contactId,
                    contactDetails: {
                        first_name: name,
                        last_name: '',
                        email: email,
                        number: contactNumber
                    },
                    from: 'calendar-activity'
                });
            }
        }
        else if(tab === 'call'){
            if(window.initiateGlobalDialer !== undefined){
                window.initiateGlobalDialer({
                    id: contactId,
                    number: contactNumber
                });
            }
        }
        else if(tab === 'info'){
            window.handleGlobalContactDetailsModal({
                open: true,
                from: 'calendar-activity',
                title: () => {
                    let title = "Contact details";
                    if(name !== ""){
                        title = name
                    }
                    else if(email !== ""){
                        title = email
                    }
                    else if(contactNumber !== ""){
                        title = contactNumber
                    }
                    return title;
                },
                id: contactId,
                closeContactDetails: () => {reloadActivityList()}
            })
        }
    }

    const selectActiveMailType = (type) => {
        setMailOptionVisible(false)
        window.openGlobalDirectMailModal({ 
            messageType: type,
            contactId: contactId, 
            from: 'inbox'
        })
    }

    const handleNewPostcard = (type = 'postcard') => {
        setMailOptionVisible(false)
        window.sendGlobalDirectMailV2({
            contactId: contactId,
            from: '/activity-calendar',
            card_type: type,
            for: 'calendar-activity' //direct-mail-send, campaign-setting, calendar-activity
        })
      }

    // useEffect(() => {
    //     const checkIfClickedOutside = e => {
    //       // If the menu is open and the clicked target is not within the menu,
    //       // then close the menu
    //       if (checked && ref.current && !ref.current.contains(e.target)) {
    //         setChecked(false)
    //       }
    //     }
    
    //     document.addEventListener("click", checkIfClickedOutside)
    
    //     return () => {
    //       // Cleanup the event listener
    //       document.removeEventListener("click", checkIfClickedOutside)
    //     }
    //   }, [checked])

    let taskFormattedDate = window.globalTimezoneConversionToDifferentTimezone(taskDate+' '+taskRawTime,'UTC','')

   
    return (
        <>
        <tr style={{ background: checked ? 'rgba(60, 127, 243, 0.1)' : '#ffffff' }} onMouseOver={onHoverActivity} onMouseLeave={onBlurActivity}>
            <td>
                <label>
                    <Checkbox
                        checked={calendarActivitySelectedTask.includes(id)}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    {/*<input value={id} type="checkbox" onChange={() => setChecked(!checked)} className="filled-in" />*/}
                    <span
                        className="d-flex justify-content-start align-items-center">
                    </span>
                </label>
            </td>
            <td> 
                <div className="d-flex align-items-center d-flex">
                    <p className="m-0 text-dark-blue-65 truncate" style={{width: '200px'}}>{title}</p>
                    {
                        task_status !== 2 &&
                        <div className="ml-2 cursor-pointer edit_icon_wrapper" onClick={() => onSlotChange(item)}>
                            <EditIcon  />
                        </div>
                    }
                </div>
            </td>
            <td>
                <p className={`m-0 ${start ? 'text-red' : 'text-dark-blue-65'}`}>
                    {
                            taskFormattedDate !== undefined && taskDate != null && 

                            moment(taskFormattedDate).format('MM/DD/YYYY')+' at ' + moment(taskTime, 'hh:mm a').format('hh:mm a')
                    }
                </p>
            </td>
            <td>
                <p className="m-0 text-dark-blue-65">{taskDuration}</p>
            </td>
            <td>
                <label>
                    <span className="d-flex justify-content-start align-items-center alt activity_table_title"><i
                            className="material-icons mr-2">
                            {TASK_TO_ICON[taskTitle]}
                        </i>
                        {taskTitle}
                    </span>
                </label>
            </td>
            <td>
                <div className="d-flex">
                    <p className="m-0 text-dark-blue-65 truncate" style={{width: '200px'}}>{name} </p>
                </div>
            </td>
            <td>
                <div className="d-inline-flex">
                    {
                        task_status !== 2 && (
                            <div style={{ cursor: "pointer", marginLeft: "2px", marginRight: "2px" }} className="action___option___each">
                                <span className="icon___inbox_action" title="Mark as done" onClick={() => handleMarkAsDoneActivity()}>{BottomActionIcon['markAsDoneIcon']}</span>
                            </div>
                        )
                    }
                    {renderAction()}
                    <div style={{ cursor: "pointer", marginLeft: "2px" }} className="action___option___each">
                        <span className="icon___inbox_action" title="Delete" onClick={() => handleDeleteActivity()}>{BottomActionIcon['deleteIcon']}</span>
                    </div>
                </div>
            </td>
        </tr>
        <Menu
            id="more-menu"
            anchorEl={mailOptionVisible}
            keepMounted
            open={Boolean(mailOptionVisible)}
            onClose={() => setMailOptionVisible(false)}
        >
            {/* <MenuItem onClick={() => selectActiveMailType(direct_mail_options.POSTCARD)} className="dropdownhelper-menuitem-class">
                <span>
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.7334 0.275391L7.25879 4.06504V11.2876L16.7334 15.0772V0.275391Z" fill="#3C7EF3" />
                        <path d="M6.36297 4.20508H5.59961V11.1387H6.36297V4.20508Z" fill="#3C7EF3" />
                        <path d="M4.70876 10.9756V4.04199H1.62995C1.19766 4.04199 0.783079 4.21372 0.477402 4.51939C0.171726 4.82507 0 5.23966 0 5.67195V9.35293C0 9.78522 0.171726 10.1998 0.477402 10.5055C0.783079 10.8112 1.19766 10.9829 1.62995 10.9829L4.70876 10.9756Z" fill="#3C7EF3" />
                        <path d="M20.0005 7.54223C19.9998 6.93244 19.7573 6.34784 19.3261 5.91665C18.8949 5.48546 18.3103 5.24291 17.7005 5.24219H17.6299V9.84229H17.7005C18.3103 9.84157 18.8949 9.59901 19.3261 9.16783C19.7573 8.73664 19.9998 8.15202 20.0005 7.54223Z" fill="#3C7EF3" />
                        <path d="M4.30887 17.7239C4.55132 17.7239 4.79051 17.6679 5.0078 17.5603C5.2251 17.4528 5.41466 17.2966 5.56176 17.1038C5.70885 16.9111 5.8095 16.687 5.8559 16.4491C5.90229 16.2111 5.89318 15.9656 5.82926 15.7318L4.81145 12.0381H1.62669H1.53613L2.78577 16.5721C2.87936 16.9039 3.07883 17.196 3.35379 17.4039C3.62875 17.6119 3.96413 17.7242 4.30887 17.7239Z" fill="#3C7EF3" />
                    </svg>
                </span>
                &nbsp;Post Card
            </MenuItem>
            <MenuItem onClick={() => selectActiveMailType(direct_mail_options.GREETING_CARD)} className="dropdownhelper-menuitem-class">
                <span>
                    <svg width="20" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.55469 9.19531V11.664L5.69266 10.4299L3.55469 9.19531Z" fill="#3C7EF3" />
                        <path d="M10.2073 9.19531L8.06934 10.4299L10.2073 11.664V9.19531Z" fill="#3C7EF3" />
                        <path d="M13.5629 3.16602H0.198033C0.0886657 3.16602 0 3.25468 0 3.36405V9.87219C0.140303 9.81707 0.0200766 9.83219 2.36608 9.83219V8.17449C2.36608 8.01201 2.42674 7.85299 2.54303 7.73948C2.7393 7.54786 3.03076 7.51747 3.25723 7.64828L6.88046 9.74028L10.5036 7.64828C10.7301 7.51747 11.0216 7.54786 11.2178 7.73945C11.3341 7.85295 11.3948 8.01193 11.3948 8.17445V9.83219H13.547C13.6223 9.83219 13.6944 9.84606 13.7609 9.87219V3.36405C13.7609 3.25464 13.6722 3.16602 13.5629 3.16602Z" fill="#3C7EF3" />
                        <path d="M11.3947 12.6925C11.3947 12.9048 11.2814 13.1012 11.0977 13.2073C11.0058 13.26 10.9032 13.2866 10.8006 13.2866C10.698 13.2866 10.5955 13.26 10.5036 13.2073L9.12565 12.4117C9.5134 13.2137 9.78393 14.1773 9.70233 15.2463C9.67858 15.558 9.41836 15.7953 9.11061 15.7953C9.09557 15.7953 9.08011 15.7945 9.06468 15.7933C8.73755 15.7683 8.49277 15.4827 8.51772 15.1556C8.65357 13.382 7.50338 11.9119 6.88159 11.2552C6.26015 11.9146 5.108 13.3908 5.24307 15.1556C5.26803 15.4828 5.02324 15.7683 4.69612 15.7933C4.68069 15.7944 4.66522 15.7953 4.65018 15.7953C4.34243 15.7953 4.08221 15.558 4.05847 15.2463C3.97687 14.1773 4.2474 13.2137 4.63515 12.4117L3.25723 13.2073C3.16533 13.26 3.06275 13.2866 2.96018 13.2866C2.85761 13.2866 2.75504 13.26 2.66313 13.2073C2.47936 13.1012 2.36608 12.9048 2.36608 12.6925V11.0234C1.66227 11.0234 0.589724 11.025 0 11.0254V19.8018C0 19.9112 0.0886657 19.9999 0.198033 19.9999H13.5628C13.6722 19.9999 13.7609 19.9112 13.7609 19.8018V11.0235H11.3948V12.6925H11.3947Z" fill="#3C7EF3" />
                        <path d="M10.9626 0.198206C10.9626 0.0669653 10.8374 -0.0280281 10.7111 0.00751629C9.22591 0.425768 1.69839 2.54535 1.59766 2.57374H10.9626V0.198206Z" fill="#3C7EF3" />
                    </svg>
                </span>
                &nbsp;Greeting Card
            </MenuItem>
            <MenuItem onClick={() => selectActiveMailType(direct_mail_options.LETTER)} className="dropdownhelper-menuitem-class">
                <span>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83586 0.0126953H11.6366C12.2191 0.0126953 12.6912 0.484873 12.6912 1.06737V10.8232L8.99988 13.5916L5.30852 10.8233V0.540032C5.30852 0.248801 5.54463 0.0126953 5.83586 0.0126953ZM13.7459 10.0322V4.495L17.4724 7.26376L13.7459 10.0322ZM8.99988 14.7783C9.11111 14.7783 9.22234 14.7433 9.31607 14.6727L17.9998 8.18635V17.4853C17.9998 17.7768 17.7639 18.0126 17.4724 18.0126H0.527337C0.23586 18.0126 0 17.7768 0 17.4853V8.18653L8.68369 14.6727C8.77741 14.7433 8.88865 14.7783 8.99988 14.7783ZM4.25399 10.0322V4.495L0.527337 7.26383L4.25399 10.0322Z" fill="#3C7EF3" />
                    </svg>
                </span>
                &nbsp;Letter
            </MenuItem>
            <MenuItem onClick={() => selectActiveMailType(direct_mail_options.GIFT)} className="dropdownhelper-menuitem-class">
                <span>
                    <svg width="20" height="20" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4404 6.82812C10.1768 7.6091 9.47672 8.08355 8.70668 8.08355C8.58336 8.08355 8.46242 8.05457 8.34125 8.02977L9.2766 9.43281C9.45629 9.7023 9.38359 10.0657 9.1141 10.2454C8.84031 10.4268 8.47832 10.3484 8.30156 10.0829L7.03125 8.17738V15.0312H18.2422C19.2115 15.0312 20 14.2427 20 13.2734V6.82812H10.4404ZM17.0703 12.6875H12.3047C11.9808 12.6875 11.7188 12.4254 11.7188 12.1016C11.7188 11.7777 11.9808 11.5156 12.3047 11.5156H17.0703C17.3942 11.5156 17.6562 11.7777 17.6562 12.1016C17.6562 12.4254 17.3942 12.6875 17.0703 12.6875ZM17.0703 10.3438H12.3047C11.9808 10.3438 11.7188 10.0817 11.7188 9.75781C11.7188 9.43395 11.9808 9.17188 12.3047 9.17188H17.0703C17.3942 9.17188 17.6562 9.43395 17.6562 9.75781C17.6562 10.0817 17.3942 10.3438 17.0703 10.3438Z" fill="#3C7EF3" />
                        <path d="M8.3368 5.68446L7.50195 6.24122L8.3368 6.79798C8.78125 7.09423 9.37535 6.77594 9.37535 6.2418C9.37535 5.70528 8.77953 5.3893 8.3368 5.68446Z" fill="#3C7EF3" />
                        <path d="M1.75781 0.967773C0.788516 0.967773 0 1.75629 0 2.72559V5.65527H2.45023C2.84656 4.48133 4.20984 4.04715 5.20418 4.70941L5.85938 5.14629V0.967773H1.75781Z" fill="#3C7EF3" />
                        <path d="M18.2422 0.967773H7.03125V5.14629L7.68641 4.70941C8.67023 4.05215 10.0416 4.47266 10.4406 5.65527H20V2.72559C20 1.75629 19.2115 0.967773 18.2422 0.967773Z" fill="#3C7EF3" />
                        <path d="M4.55418 5.68476C4.10906 5.388 3.51562 5.70824 3.51562 6.24093C3.51562 6.77523 4.10758 7.0955 4.55418 6.79828L5.38902 6.24152L4.55418 5.68476Z" fill="#3C7EF3" />
                        <path d="M4.58906 10.0829C4.40938 10.3512 4.04605 10.4227 3.77652 10.2454C3.50703 10.0657 3.43434 9.70234 3.61402 9.43281L4.54938 8.02977C4.4282 8.05457 4.30727 8.08355 4.18395 8.08355C3.41203 8.08355 2.71281 7.60719 2.44996 6.82812H0V13.2735C0 14.2428 0.788516 15.0313 1.75781 15.0313H5.85938V8.17742L4.58906 10.0829Z" fill="#3C7EF3" />
                    </svg>
                </span>
                &nbsp; Gift
            </MenuItem> */}

            {/* {Utils.getAccountData('userId') == 1 && */}
            <>
                {thanksIoActive &&
                <>
                <MenuItem onClick={() => handleNewPostcard('postcard')}
                    style={{
                        paddingTop: 6,
                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                    }}
                >
                    Postcard
                </MenuItem>
                <MenuItem onClick={() => handleNewPostcard('letter')}
                    style={{
                        paddingTop: 6,
                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                    }}
                >
                    Letter
                </MenuItem>
                <MenuItem onClick={() => handleNewPostcard('notecard')}
                    style={{
                        paddingTop: 6,
                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                    }}
                >
                    Notecard
                </MenuItem>
                </>
                }
                {zenDirectActive &&
                <MenuItem onClick={() => handleNewPostcard('zendirect_gift')}
                    style={{
                        paddingTop: 6,
                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                    }}
                >
                    Gift
                </MenuItem>
                }
            </>
            {/* } */}
        </Menu>
        </>
    );
};

export default ActivityListItem;